import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { motion } from "framer-motion";

export const animationProps = {
  initial: { "--x": "100%", scale: 0.8 },
  animate: { "--x": "-100%", scale: 1 },
  whileTap: { scale: 0.95 },
  transition: {
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: 1,
    type: "spring",
    stiffness: 20,
    damping: 15,
    mass: 2,
    scale: {
      type: "spring",
      stiffness: 200,
      damping: 5,
      mass: 0.5,
    },
  },
} as const;

export const CustomPill = styled(motion(Flex))`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /* Pill shape + background */
  border-radius: 60px;
  background: linear-gradient(#72439a, #f95370);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #f7fcfd;

  @media (max-width: 425px) {
    font-size: 12px;
  }

  /* 
    This 'span' block is key: it uses a mask-image that depends on --x 
    to animate the text shimmer.
  */
  & > span {
    position: relative;
    z-index: 1;
    mask-image: linear-gradient(
      -75deg,
      #ffffff calc(var(--x) + 20%),
      transparent calc(var(--x) + 30%),
      #ffffff calc(var(--x) + 100%)
    );
    mask-size: 200%;
    mask-repeat: no-repeat;
  }

  /*
    The shiny overlay effect for the pill background itself. 
    We use ::after with a gradient that moves via --x.
  */
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 2;
    pointer-events: none;

    background: linear-gradient(
      -75deg,
      rgba(255, 255, 255, 0.1) calc(var(--x) + 20%),
      rgba(255, 255, 255, 0.5) calc(var(--x) + 25%),
      rgba(255, 255, 255, 0.1) calc(var(--x) + 100%)
    );

    mask:
      linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)) content-box,
      linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0));
    mask-composite: exclude;
  }
`;
