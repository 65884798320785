import { useRecoilValue } from "recoil";
import { Box, Flex, Text } from "rebass";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import { useUser } from "../../Hooks/useUser";
import { SidebarExpandedState } from "../../State/sidebar";
import { SidebarCollapsed } from "./SidebarCollapsed";
import { SidebarLoginWidget } from "./SidebarLoginWidget";
import { NAV_LINKS, isActive } from "./utils";
import {
  navLinkActive,
  navLinkActiveFill,
  sidebarContainerStyle,
  NavItem,
  NavLinkText,
  StyledBiCompass,
  StyledBiPalette,
  StyledBiUser,
  StyledBiVideo,
  StyledDivider,
  StyledHistoryLogo,
  StyledBiTrophy,
} from "./styledComponents";
import { usePortal } from "../../Hooks/usePortal";
import { PartnerTypeModal } from "../Modals/PartnerType";
import { CompetitionViewModel } from "../../Views/Competitions/ViewModel";

const Sidebar = () => {
  const expanded = useRecoilValue(SidebarExpandedState);
  const { allstarUser } = useUser();
  const { portalOpen, setPortalOpen, Portal } = usePortal(<PartnerTypeModal />);
  const { t } = useTranslation("translation", {
    keyPrefix: "nav",
  });
  const { data: competitionData } = CompetitionViewModel({
    activeOnly: true,
  });
  const competitionLink = competitionData?.competitions[0]?.slug;

  if (!expanded) return <SidebarCollapsed competitionLink={competitionLink} />;

  const username = allstarUser?.user?.profile?.username;
  const profileLink = username
    ? `${NAV_LINKS.profile}/${username}`
    : NAV_LINKS.login;

  return (
    <SidebarContainer>
      {portalOpen && Portal}
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ gap: 8 }}
      >
        <NavItem>
          <NavLink to={NAV_LINKS.root} $isActive={isActive([NAV_LINKS.root])}>
            <StyledBiCompass />
            <NavLinkText>{t("main.explore", "Explore")}</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={profileLink}
            $isActive={isActive(["/u", NAV_LINKS.profile])}
          >
            <StyledBiUser />
            <NavLinkText>{t("main.myProfile", "My Profile")}</NavLinkText>
          </NavLink>
        </NavItem>
        <SidebarLoginWidget />
        <StyledDivider />
        <NavSubheader>Create</NavSubheader>
        <NavItem>
          <NavLink
            to={NAV_LINKS.matchHistory}
            $isActive={isActive([NAV_LINKS.matchHistory])}
            $fill={true}
          >
            <StyledHistoryLogo />
            <NavLinkText>Match History</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={NAV_LINKS.studio} $isActive={false}>
            <StyledBiPalette />
            <NavLinkText>{t("main.studio", "Studio")}</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={NAV_LINKS.montages} $isActive={false}>
            <StyledBiVideo />
            <NavLinkText>{t("main.montages", "Montages")}</NavLinkText>
          </NavLink>
        </NavItem>
        <StyledDivider />
        {competitionLink && (
          <>
            <NavSubheader data-testid="earn">Earn</NavSubheader>
            <NavItem>
              <NavLink
                to={`/competitions/${competitionLink}`}
                $isActive={false}
                data-testid="competitionLink"
              >
                <StyledBiTrophy />
                <NavLinkText>
                  {t("main.competitions", "Competitions")}
                </NavLinkText>
              </NavLink>
            </NavItem>
            <StyledDivider />
          </>
        )}
        <NavItem>
          <NavLink to={NAV_LINKS.upgrade} $isActive={false}>
            <NavLinkText>{t("main.upgrade", "Upgrade")}</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="#" onClick={() => setPortalOpen(true)} $isActive={false}>
            <NavLinkText>For Partners</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            onClick={() => window.location.replace(NAV_LINKS.support)}
            $isActive={false}
          >
            <NavLinkText>{t("main.support", "Support")}</NavLinkText>
          </NavLink>
        </NavItem>
      </Flex>
      <Box style={{ paddingBottom: "100px" }} />
    </SidebarContainer>
  );
};

const SidebarContainer = styled(Box)`
  ${sidebarContainerStyle}
  width: 240px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 75vw;
    z-index: 3;
  }
`;

export const navLinkStyle = css`
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  text-decoration: none;
  width: 100%;
`;

export const NavLink = styled(Link)<{ $isActive: boolean; $fill?: boolean }>`
  ${(props) =>
    props.$isActive ? (props.$fill ? navLinkActiveFill : navLinkActive) : ""}

  ${navLinkStyle}

  :hover {
    ${(props) => (props.$fill ? navLinkActiveFill : navLinkActive)}
  }
`;

const NavSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 700;
  padding: 8px 16px;
`;

export { Sidebar };
