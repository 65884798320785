import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Text } from "rebass/styled-components";
import { useRecoilState } from "recoil";

import { Avatar } from "MVVM/Components/Avatar";
import {
  AvatarWrapper,
  getAvatarBorderLevel,
  getAvatarImage,
} from "MVVM/Utilities/User";
import { dateFormatWithDistanceSuffix } from "MVVM/Utilities/Clip";
import { FollowButton } from "MVVM/Components/Button";
import { useUser } from "MVVM/Hooks/useUser";
import { AllstarModalState } from "MVVM/State/modals";
import { IClip, ModalType } from "MVVM/@types";
import { useMutateFollowers } from "MVVM/Hooks/useMutateFollowers";
import { formatNumber } from "MVVM/Views/Wrapped/util";
import EditableText from "MVVM/Components/Text/Editable";
import { Clip } from "MVVM/Model/Clip";
import { getQueryParam } from "MVVM/Utilities/QueryParam";

interface InfoSectionProps {
  clip: IClip;
}

const InfoSection = ({ clip }: InfoSectionProps) => {
  const { allstarUser } = useUser();
  const { mutateFollowers } = useMutateFollowers();
  const editMode = getQueryParam("edit");
  const username = clip?.user?.username || "";
  const avatarImage = getAvatarImage(clip.user);
  const avatarBorderLevel = getAvatarBorderLevel(clip.user);
  const history = useHistory();
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const handleClick = () => {
    username && history.push(`/u/${username}`);
  };
  const isUsersClip = clip.user?._id === allstarUser.user?._id;
  const clipFunctions = Clip.getInstance();
  const editTitle = clipFunctions.Title;
  const containerRef = useRef<HTMLDivElement>(null);

  const setFollowModal = ({
    displayName,
    avatar,
  }: {
    displayName: string;
    avatar: string;
  }) => {
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.Signup,
      data: {
        target: displayName,
        avatar: avatar,
        action: "follow",
      },
    });
  };

  useEffect(() => {
    if (!editMode || !isUsersClip) return;
    if (containerRef.current) {
      const editableElement = containerRef?.current?.querySelector(
        "#react-edit-text-button",
      );
      if (editableElement) (editableElement as HTMLElement).click();
    }
  }, [editMode, isUsersClip]);

  return (
    <Flex style={{ gap: 18 }} p={6} pb={0}>
      <Box
        onClick={() => handleClick()}
        style={{ cursor: "pointer", transform: "scale(1.1)" }}
        pt={2}
        flexShrink={0}
      >
        <AvatarWrapper size="nav" borderLevel={avatarBorderLevel}>
          <Avatar
            image={avatarImage}
            onClick={() => history.push(`/u/${username}`)}
            size="nav"
          />
        </AvatarWrapper>
      </Box>
      <Flex flexGrow={1}>
        <Flex flexWrap="wrap" style={{ gap: 14 }} flexGrow={1}>
          <Flex
            flexDirection="column"
            style={{ gap: 12 }}
            flexGrow={1}
            flexWrap="wrap"
          >
            <Flex
              justifyContent="space-between"
              style={{ gap: 2, maxWidth: "100%" }}
              ref={containerRef}
            >
              {isUsersClip && clip.type !== "CAREER_MONTAGE" ? (
                <EditableText
                  key="editing"
                  variant="title3"
                  defaultValue={clip.title || ""}
                  onSave={(value: string) =>
                    editTitle(clip.internalId, value, false)
                  }
                />
              ) : (
                <Text variant="text.title3">{clip.title}</Text>
              )}
              {!isUsersClip && (
                <FollowButton
                  alignSelf="flex-start"
                  allstarUser={allstarUser}
                  avatar={clip.user.avatar}
                  displayName={username}
                  flex={true}
                  followUnfollow={mutateFollowers}
                  followerData={undefined}
                  isModal={false}
                  ml={1}
                  py={5}
                  openModal={true}
                  setFollowModal={setFollowModal}
                  size="tiny"
                  userId={clip.user._id}
                />
              )}
            </Flex>
            <Flex style={{ gap: 6 }} color="chalk" flexWrap="wrap">
              <Text onClick={() => handleClick()} style={{ cursor: "pointer" }}>
                {username || "Allstar User"}
              </Text>
              <Text>&bull;</Text>
              {!!clip.views && clip.views >= 5 && (
                <>
                  <Text>{formatNumber(clip.views)} views</Text>{" "}
                  <Text>&bull;</Text>
                </>
              )}
              <Text>{dateFormatWithDistanceSuffix(clip.createdAt)}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { InfoSection };
