import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import { Body, Title3 } from "../../../../Components/Text";
import { NotNowWrapper, StyledLinkButton, ModalPosition } from "MVVM/Views/Iframe/Components/AttemptedAction";

export const ThirdPartySettingsModal = ({
  type,
  overlayConfig,
  translateHook,
  setAttemptedAction,
}: any) => {
  return (
    <ModalPosition
      display={!!type}
      onClick={(e: any) => {
        setAttemptedAction("");
      }}
    >
      <ModalContainer
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Flex
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <StyledTitle>{translateHook("partnerOverlay.thirdPartySettings.title", "Customize Your Clips")}</StyledTitle>
          <StyledDescription>{translateHook("partnerOverlay.thirdPartySettings.description", "Take full control of your highlights!")}</StyledDescription>
        </Flex>
        <Row>
          <StyledColumn>
            <StyledImage src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/static/web/3rdparty/clipResolution.svg`} />
            <Text>{translateHook("partnerOverlay.thirdPartySettings.clipResolution", "Adjust your clip resolution & format")}</Text>
          </StyledColumn>
          <StyledColumn>
            <StyledImage src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/static/web/3rdparty/changeUsername.svg`} />
            <Text>{translateHook("partnerOverlay.thirdPartySettings.changeUsername", "Change your displayed username in clips")}</Text>
          </StyledColumn>
          <StyledColumn>
            <StyledImage src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/static/web/3rdparty/clipPreferences.svg`} />
            <Text>{translateHook("partnerOverlay.thirdPartySettings.clipPreferences", "Set up advanced clipping preferences")}</Text>
          </StyledColumn>
        </Row>
        <StyledFlex>
          <NotNowWrapper>
            <StyledBody
              onClick={() => {
                setAttemptedAction("");
              }}
            >
              {translateHook("partnerOverlay.thirdPartySettings.notNowButtonText", "Close")}
            </StyledBody>
          </NotNowWrapper>
          <StyledLinkButton
            classification="roundedFilled"
            size="tiny"
            flex={true}
            onClick={() => {
              try {
                window.rudderanalytics.track(
                  "Overlay - Third Party Settings Button Click",
                  {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: overlayConfig?.partner?.useCase,
                    type: type,
                  },
                );
              } catch (e) {}

              window.open("/third-party-settings", "_blank");
            }}
          >
            {translateHook("partnerOverlay.thirdPartySettings.buttonText", "3rd Party Settings")}
          </StyledLinkButton>
        </StyledFlex>
      </ModalContainer>
    </ModalPosition>
  );
};

const Row = styled(Flex)`
  text-align: center;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const StyledColumn = styled(Flex)`
  border-radius: 12px;
  flex-direction: column;
  border: ${(props) => `1px solid ${props.theme.colors.chalkAlpha20}`};
  align-items: center;
  padding: 12px 6px;
  width: 155px;

  @media (max-width: 800px) {
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 650px) {
    width: 80%;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

const StyledImage = styled(Image)`
  height: 32px;
  width: 32px;
  margin-bottom: 1rem;

  @media (max-width: 800px) {
    margin-bottom: 0;
    margin-right: 1rem;
  }
`;

const StyledBody = styled(Body)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.envy};
`;

const StyledTitle = styled(Title3)`
  font-weight: 700;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 650px) {
    font-size: 1.1em;
  }
`;

const StyledDescription = styled(Body)`
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.ice};
  font-weight: 500;
  margin-bottom: 10px;
  @media (max-width: 650px) {
    padding-top: 10px;
    font-size: 0.9em;
  }
`;

const Text = styled.p`
  all: unset;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.chalk};
`;

const ModalContainer = styled(Flex)`
  flex-direction: column;
  width: 600px;
  height: 300px;
  background-color: #000000;
  border-radius: 10px;
  box-sizing: border-box;
  color: #ffffff;
  position: absolute;
  pointer-events: auto;
  margin: auto;
  padding: 16px;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 550px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 800px) {
    justify-content: space-evenly;
  }
`;
