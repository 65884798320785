import React, { useState, useEffect, useReducer } from "react";
import styled from "styled-components";

import { clipBucketURL } from "./utils";
import { UpNextPreview } from "../../organisms/clip/upNextPreview";
import VideoPlayer from "./videojs";
import ReactPlaceholder from "react-placeholder";
import theme from "../../../theme/themeThree";
import Axios from "axios";
import { PinClipButton } from "../../../MVVM/Components/Pinnable/PinClipButton";
import { Box } from "rebass";
import { useUser } from "../../../MVVM/Hooks/useUser";

// Do not change this code block
// If there is an error with media, this is a backend/cloudflare issue
//
const getClipPaths = async (data, cotd, mobileView) => {
  const paths = [];
  let clientBandwidthHint = 100;
  if (cotd) clientBandwidthHint = 100;
  if (cotd && data?.promotion?.display) clientBandwidthHint = 1;

  if (mobileView && data?.mobile?.length) {
    const mobileData = data.mobile[0];
    if (mobileData.cf?.readyToStream) {
      try {
        await Axios.head(`${mobileData.cf.hls}`);
        paths.unshift({
          src: `${mobileData.cf.hls}?clientBandwidthHint=${clientBandwidthHint}`,
          type: "application/x-mpegurl",
          size: 720,
        });
      } catch {
        paths.push({
          src: `${mobileData.cf.downloadUrl}`,
          type: "video/mp4",
        });
      }
    } else {
      paths.push({
        src: `${clipBucketURL}/${mobileData.clipLink}`,
        type: "video/mp4",
      });
    }

    return paths;
  }

  if (data?.cf?.hls && data?.cf?.readyToStream) {
    try {
      await Axios.head(`${data.cf.hls}`);
      paths.unshift({
        src: `${data.cf.hls}?clientBandwidthHint=${clientBandwidthHint}`,
        type: "application/x-mpegurl",
        size: 720,
      });
    } catch {
      paths.push({
        src: `${data.cf.downloadUrl}`,
        type: "video/mp4",
      });
    }

    return paths;
  }

  try {
    if (data.clipLinkHLS) {
      await Axios.head(`${clipBucketURL}/${data.clipLinkHLS}`);
      paths.unshift({
        src: `${clipBucketURL}/${data.clipLinkHLS}`,
        type: "application/x-mpegurl",
        size: 720,
      });
    }
  } catch (e) {}

  try {
    if (data.fallbackHLS) {
      paths.unshift({
        src: data.fallbackHLS,
        type: "application/x-mpegurl",
        size: 720,
      });
    }
  } catch (e) {}

  paths.push({
    src: `${clipBucketURL}/${data.clipLink}`,
    type: "video/mp4",
  });

  return paths;
};

const Player = ({
  loading,
  clip,
  posterImage,
  index,
  isMontage,
  mobileView,
  isOwnProfile,

  ...props
}) => {
  let posterToShow;
  const { allstarUser } = useUser();

  if (mobileView && clip?.mobile?.length) {
    posterToShow = `${clipBucketURL}/${clip.mobile[0].clipImageThumb}`;
  } else {
    posterToShow =
      posterImage === "og" && (clip.clipSnapshot || clip.clipImageThumb)
        ? `${clipBucketURL}/${clip.clipSnapshot}`
        : `${clipBucketURL}/${clip.clipImageThumb}`;
  }

  const isFirstVideo = index === 0;
  if (clip.placeholderThumbnail) posterToShow = clip.placeholderThumbnail;

  const playerOptionsReducer = (state, action) => {
    switch (action.type) {
      case "init":
        return {
          autoplay: props.autoplay,
          controls: props.controls,
          loop: props.loop,
          muted: props.muted,
          clip: clip,
          totalReactions: props.totalReactions,
          skipBumper: props.skipBumper,
          playsinline: props.playsinline,
          bigPlayButtonCentered: props.bigPlayButtonCentered,
          isFeed: props.isFeed,
          inView: props.inView,
          onCanPlay: props.onCanPlay,
          onTimeUpdate: props.onTimeUpdate,
          onLoadedData: props.onLoadedData,
          onLoadedMetaData: props.onLoadedMetaData,
          onPlay: props.onPlay,
          onReady: props.onReady,
          embedded: props.embedded,
          onEnd: props.onEnd,
          onPause: props.onPause,
          onError: props.onError,
          onStart: props.onStart,
          onSeek: props.onSeek,
          volume: props.volume,

          preload: "none",
          hideControls: ["playbackrates"],
          html5: {
            nativeTextTracks: false,
            hls: {
              overrideNative: true,
            },
            vhs: {
              useDevicePixelRatio: true, // Retina/Scaling considerations
              bandwidth: 15000000, // 1.5Mbps - this is the important one.
              limitRenditionByPlayerDimensions: false, // Dont assume resolution - Use player dimensions
              handlePartialData: true,
            },
          },
        };

      case "update":
        return {
          ...state,
          skipBumper: props.skipBumper,
          poster: posterToShow,
          loop: props.loop,
          controls: props.controls,
          autoplay: props.autoplay,
          muted: props.muted,
          embedded: props.embedded,
          playsinline: props.playsinline,
          sources: action.sourceData,
          bigPlayButtonCentered: props.bigPlayButtonCentered,
          hideControls: ["playbackrates"],
          onCanPlay: props.onCanPlay,
          isFeed: props.isFeed,
          clip: clip,
          totalReactions: props.totalReactions,
          onTimeUpdate: props.onTimeUpdate,
          onLoadedData: props.onLoadedData,
          onLoadedMetaData: props.onLoadedMetaData,
          onPlay: props.onPlay,
          onReady: props.onReady,
          onEnd: props.onEnd,
          onPause: props.onPause,
          onError: props.onError,
          onStart: props.onStart,
          volume: props.volume,
          onSeek: props.onSeek,

          preload: "none",
          html5: {
            nativeTextTracks: false,
            hls: {
              overrideNative: true,
            },
            vhs: {
              useDevicePixelRatio: true, // Retina/Scaling considerations
              bandwidth: 15000000, // 1.5Mbps - this is the important one.
              limitRenditionByPlayerDimensions: false, // Dont assume resolution - Use player dimensions
              handlePartialData: true,
            },
          },
        };

      case "clear":
        return {};

      default:
        throw new Error();
    }
  };

  const [sourceData, setSourceData] = useState([]);
  const [playerOptions, dispatchPlayerOptions] = useReducer(
    playerOptionsReducer,
    {},
  );
  const [wrapperHovering, setWrapperHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    getClipPaths(clip, props.cotd, mobileView).then((paths) => {
      if (props.embedded) {
        paths.forEach((obj, index) => {
          //this solution is not ideal need to think of a better one, will only handle one case
          const hasBandwidthHint = paths[index].src.includes(
            "clientBandwidthHint",
          );
          paths[index].src =
            paths[index].src +
            `${hasBandwidthHint ? "&" : "?"}source=${props.source}`;

          const url =
            window.location !== window.parent.location
              ? document.referrer
              : document.location.href;

          if (url && url.includes("testView"))
            paths[index].src = paths[index].src + `&ts=${Date.now()}`; // the timestamp is to bypass a weird caching issue with our player. If you refresh the page and try to load the same media eventually it starts to fail. IDK WHY!
        });
      }

      setSourceData(paths);
    });
  }, [clip, props.cotd]); //eslint-disable-line

  useEffect(() => {
    dispatchPlayerOptions({
      type: "init",
    });
  }, []);

  useEffect(() => {
    if (sourceData.length === 0) return;

    dispatchPlayerOptions({
      type: "update",
      sourceData: sourceData,
    });
    setIsLoading(false);
    return () => {
      dispatchPlayerOptions({
        type: "clear",
      });
    };
  }, [sourceData]);

  if (
    isLoading ||
    sourceData.length === 0 ||
    Object.keys(playerOptions).length === 0
  ) {
    return props.barebones ? (
      <ReactPlaceholder customPlaceholder={videoPlaceholder} ready={false} />
    ) : (
      <Wrapper fillScreen={props.fillScreen} isFeed={props.isFeed}>
        <ReactPlaceholder customPlaceholder={videoPlaceholder} ready={false} />
      </Wrapper>
    );
  }

  return props.barebones ? (
    <>
      <VideoPlayer
        id={clip._id}
        cotd={props.cotd}
        {...playerOptions}
        className={props.className}
      />
    </>
  ) : (
    <Wrapper
      isFeed={props.isFeed}
      fillScreen={props.fillScreen}
      cardClips={props.cardClips}
      embedded={props.embedded}
      name={isFirstVideo ? "clip-feed-first-video" : "clip-feed-video"}
      className={`${props.className ? props.className : ""} ${
        props.hideBigPlay ? "hide-big-play" : ""
      }`}
      onMouseOver={() => {
        setWrapperHovering(true);
      }}
      onMouseLeave={() => {
        setWrapperHovering(false);
      }}
    >
      <VideoPlayer
        id={clip._id}
        clip={clip}
        {...playerOptions}
        autoplay={props.autoplay}
        platform={props.platform}
        isMontage={isMontage}
        mobileView={mobileView}
        UpNextComponent={
          props.showNextClips ? (
            <UpNextPreview
              setNextClipSoon={props.setNextClipSoon}
              nextClipSoon={props.nextClipSoon}
              clip={props.nextClip}
            />
          ) : (
            <></>
          )
        }
        VideoOverlay={props.VideoOverlay}
      />
    </Wrapper>
  );
};

const PinClipWrapper = styled(Box)`
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1000;
  cursor: pointer;
`;

Player.defaultProps = {
  autoplay: false,
  fillScreen: true,
  loading: false,
  controls: true,
  loop: false,
  playsinline: true,
  muted: false,
  barebones: false,
  showNextClips: false,
  nextClip: {},
  nextClipSoon: false,
  skipBumper: false,
  volume: 100,
  setNextClipSoon: () => {},
  onCanPlay: () => {},
  onReady: () => {},
  onPlay: () => {},
  onSeek: () => {},
  onEnd: () => {},
  onPause: () => {},
  onStart: () => {},
  onError: () => {},
  onTimeUpdate: () => {},
  onLoadedData: () => {},
};

export { Player };

const topContentHeight = process.env.REACT_APP_SHOW_ANNOUNCEMENT_BAR
  ? 290
  : 230;

const retainVideoAspectRatioStyles = {
  position: "relative",
  paddingTop: "56.25%",
  height: "auto !important",
  canvas: {
    position: "absolute",
    top: 0,
  },
};

const embeddedVideoStyles = {
  height: "100% !important",
  "video, canvas": {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
  },
};

const cardClipsStyles = {
  position: "relative",
  paddingTop: "56.25%",
  height: "auto !important",
  width: "100%",
  "video, canvas": {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
  },
};

const constrainScreenAspectRatioStyles = {
  height: `calc(100vh - ${topContentHeight}px) !important`,
  maxHeight: "755px",
  "video, canvas": {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
  },
};

const Wrapper = styled.div`
  position: relative;
  border-radius: 0px;
  overflow: hidden;

  .video-js {
    font-size: initial;
    line-height: initial;
    font-family: inherit;
  }

  .video-js .vjs-time-tooltip {
    font-family: Barlow, sans-serif;
  }

  video,
  .video-js,
  .vjs-poster {
    border-radius: 0px; // 10px needed for iphone

    &:focus {
      outline: none;
    }
  }

  .video-js.vjs-big-play-centered:hover .vjs-big-play-button {
    opacity: 0.9;
  }

  .vjs-seek-to-live-text {
    display: none;
  }

  .vjs-slider-horizontal .vjs-volume-level:before {
    top: -0.4em; // override videojs
  }

  .video-js .vjs-progress-control:hover .vjs-time-tooltip {
    // visibility: hidden;
  }

  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "";
  }

  .video-js .vjs-progress-control .vjs-progress-holder,
  .video-js .vjs-progress-holder .vjs-play-progress,
  .video-js .vjs-progress-holder .vjs-load-progress,
  .video-js .vjs-progress-holder .vjs-load-progress div,
  .vjs-volume-bar.vjs-slider-horizontal,
  .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
    border-radius: 0px;
  }

  .video-js.vjs-big-play-centered .vjs-big-play-button {
    height: 5rem;
    width: 5rem;
    background: ${(props) => props.theme.colors.spectrum};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin: 0; // override videojs
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    border: 6px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.midnightAlpha90};
    opacity: 0.8;
    transition: opacity ${(props) => props.theme.transitionDuration};

    &:before {
      display: none;
    }

    .vjs-icon-placeholder {
      display: block;
      background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 448 512" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>');
      background-repeat: no-repeat;
      width: 43.75%;
      height: 43.75%;
      position: absolute;
      top: 50%;
      left: 57.25%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .video-js .vjs-progress-holder .vjs-play-progress {
    background-color: ${(props) => props.theme.colors.envy};
  }

  &.hide-big-play .video-js .vjs-big-play-button,
  .video-js .vjs-mouse-display:after,
  .video-js .vjs-play-progress:after {
    display: none !important;
  }

  // all border radius needed for iphone
  .video-js .vjs-control-bar {
    font-size: 0.875em;
    background: transparent;

    &:before {
      content: "";
      display: block;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );
      bottom: 0;
      height: 4.3em;
      position: absolute;
    }
  }

  .video-placeholder {
    border-radius: 0px;
    overflow: hidden;
  }

  .vjs-play-control.vjs-paused.vjs-ended:before {
    display: none;
  }

  .video-js,
  .video-placeholder {
    width: 100%;
    ${(props) => {
      if (props.cardClips) {
        return {
          "@media (min-width: 380px) and (max-height: 1350px)": {
            ...cardClipsStyles,
          },
        };
      }
      if (props.embedded) {
        return {
          "@media (min-width: 980px) and (max-height: 1250px)": {
            ...embeddedVideoStyles,
          },
        };
      }
      return props.fillScreen
        ? {
            "@media (max-width: 980px), (min-height: 1250px)": {
              ...retainVideoAspectRatioStyles,
            },
            "@media (min-width: 980px) and (max-height: 1250px)": {
              ...constrainScreenAspectRatioStyles,
            },
          }
        : retainVideoAspectRatioStyles;
    }}
  }

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    .vjs-picture-in-picture-control {
      display: none;
    }
  }

  @media (hover: none), (pointer: coarse) {
    .video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover {
      width: auto !important;
    }

    .video-js
      .vjs-volume-panel.vjs-hover
      .vjs-mute-control
      ~ .vjs-volume-control.vjs-volume-horizontal,
    .video-js
      .vjs-volume-panel.vjs-hover
      .vjs-volume-control.vjs-volume-horizontal {
      display: none;
    }
  }
`;

const videoPlaceholder = (
  <div className="video-placeholder" data-testid="video-placeholder">
    <canvas
      width="16"
      height="9"
      style={{
        objectFit: "contain",
        backgroundColor: theme.colors.chalkAlpha20,
        width: "100%",
      }}
    ></canvas>
  </div>
);
