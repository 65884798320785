import { useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { Avatar } from "MVVM/Components/Avatar";
import { AllstarModalState } from "MVVM/State/modals";
import { useMutateFollowers } from "MVVM/Hooks/useMutateFollowers";
import { IPlayer, ModalType } from "MVVM/@types";
import { FollowButton } from "MVVM/Components/Button";
import { useUser } from "MVVM/Hooks/useUser";
import { AvatarWrapper, getAvatarBorderLevel } from "MVVM/Utilities/User";
import { EmptyList } from "../EmptyList";
import { randomAvatar } from "MVVM/Utilities/Images";
import { IAllstarUser } from "MVVM/Views/Setup/Components/@types";
import SteamIcon from "MVVM/Assets/Platforms/steam.svg";
import { ReactComponent as HistoryLogo } from "MVVM/Assets/Nav/nav-history.svg";
import AllstarIcon from "MVVM/Assets/Brand/A-Mark-Light.svg";
import { useHistory } from "react-router-dom";

interface IFollowProps {
  players: IPlayer[];
}

interface IFollowModal {
  avatar: string;
  displayName: string;
}

const FollowList = ({ players }: IFollowProps) => {
  const { allstarUser } = useUser();
  const sortablePlayers = [...players];
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const setFollowModal = ({ displayName, avatar }: IFollowModal) => {
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.Signup,
      data: {
        target: displayName,
        avatar: avatar,
        action: "follow",
      },
    });
  };
  const sortedPlayers = sortablePlayers
    .sort((a, b) => (a.user ? -1 : b.user ? 1 : 0))
    .filter((player) => player?.user?._id !== allstarUser.user?._id);

  if (!sortedPlayers.length)
    return <EmptyList text="Couldn`t find anyone else in this match" />;

  return (
    <Container>
      {sortedPlayers.map((player: IPlayer) => (
        <PlayerItem
          allstarUser={allstarUser}
          player={player}
          setFollowModal={setFollowModal}
          key={player.gameId}
        />
      ))}
    </Container>
  );
};

interface IPlayerItemProps {
  allstarUser: IAllstarUser;
  player: IPlayer;
  setFollowModal: (arg0: IFollowModal) => void;
}

const PlayerItem = ({
  allstarUser,
  player,
  setFollowModal,
}: IPlayerItemProps) => {
  const [hovered, setHovered] = useState(false);
  const { mutateFollowers } = useMutateFollowers();
  const displayName = player?.user?.username || player?.displayName;

  if (!displayName) return <></>;

  return (
    <Item>
      <Hoverable
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onTouchStart={() => setHovered(!hovered)}
      >
        <HoverableWrapper
          onTouchStart={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <PlayerAvatar player={player} />
          <Flex
            flexDirection="column"
            style={{ gap: 2, marginRight: "auto", marginTop: "-2px" }}
          >
            <Text fontWeight={500}>{displayName}</Text>
            {!player.user && (
              <Text fontWeight={500} color="chalk" fontSize={1}>
                Not on Allstar
              </Text>
            )}
          </Flex>
          <PlayerMenu
            player={player}
            shouldShow={hovered}
            analyticsProperties={{
              location: "clipExperience",
            }}
          />
        </HoverableWrapper>
      </Hoverable>
      <Box>
        {player.user && (
          <StyledFollowButton
            alignSelf="flex-start"
            allstarUser={allstarUser}
            avatar={player.avatar}
            displayName={player.displayName}
            followUnfollow={mutateFollowers}
            followerData={undefined}
            isModal={false}
            ml={1}
            openModal={true}
            py={5}
            setFollowModal={setFollowModal}
            size="tiny"
            userId={player?.user?._id}
          />
        )}
      </Box>
    </Item>
  );
};

export const PlayerMenu = ({
  player,
  shouldShow,
  analyticsProperties,
}: {
  player: IPlayer;
  shouldShow: boolean;
  analyticsProperties?: {
    location: string;
  };
}) => {
  const history = useHistory();
  return (
    <Menu shouldShow={shouldShow}>
      {player.gameId && (
        <PlayerLink
          onClick={() => {
            window.rudderanalytics.track(
              "Match History - Go To Match History",
              { ...analyticsProperties, source: "web" },
            );
            history.push(
              `/match-history?playerSearchIdentifier=${player.gameId}`,
            );
          }}
        >
          <StyledHistoryLogo
            style={{
              height: "20px",
              marginRight: "5px",
              width: "20px",
            }}
          />
          <Text>Go to Match History</Text>
        </PlayerLink>
      )}
      {/* This prevents non-steam users from leaking through when the graph starts exposing players of other games.
    Will need to implement a more robust way of displaying platform specific links at that time. */}
      {player.gameId && /^(\d{17})$/.test(player.gameId) && (
        <PlayerLink
          onClick={() => {
            window.rudderanalytics.track("In This Match - Visit Game Platform");
            window.open(
              `http://steamcommunity.com/profiles/${player.gameId}`,
              "_blank",
            );
          }}
        >
          <img
            height={20}
            src={SteamIcon}
            style={{ marginRight: 5 }}
            width={20}
          />
          <Text>{player?.displayName}</Text>
        </PlayerLink>
      )}
      {player?.user?._id && (
        <PlayerLink
          onClick={() => {
            window.rudderanalytics.track(
              "In This Match - Visit Allstar Profile",
            );
            window.open(`/profile?user=${player.user._id}`, "_blank");
          }}
        >
          <img
            height={20}
            src={AllstarIcon}
            style={{ marginRight: 5 }}
            width={20}
          />
          <Text>{player?.displayName}</Text>
        </PlayerLink>
      )}
    </Menu>
  );
};

const PlayerAvatar = ({ player }: { player: IPlayer }) => {
  const avatar =
    player?.user?.avatarUrl || player.avatar || useMemo(randomAvatar, []);

  return (
    <AvatarWrapper size="nav" borderLevel={getAvatarBorderLevel(player.user)}>
      <Avatar image={avatar} size="nav" />
    </AvatarWrapper>
  );
};

const Hoverable = styled(Flex)`
  margin-right: auto;
`;

const HoverableWrapper = styled(Flex)`
  align-items: center;
  gap: 16px;
  position: relative;
  width: auto;
`;

const Menu = styled(Box)<{ shouldShow: boolean }>`
  background: ${({ theme }) => theme.colors.darkerNed};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.navy};
  display: ${(props) => (props.shouldShow ? "block" : "none")};
  left: 20%;
  padding: 12px;
  position: absolute;
  top: 70%;
  width: max-content;
  z-index: 500;
`;

const PlayerLink = styled(Box)`
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.ice};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  gap: 12px;
  padding: 8px;
  text-decoration: none;

  :hover {
    background: ${({ theme }) => theme.colors.navy};
    color: ${({ theme }) => theme.colors.envy};
  }
`;

const StyledFollowButton = styled(FollowButton)`
  box-sizing: border-box;
  height: 44px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const Container = styled(Flex)`
  background: ${({ theme }) => theme.colors.darkerNed};
  flex-direction: column;
  padding: 12px;
  width: 100%;
`;

const StyledHistoryLogo = styled(HistoryLogo)`
  fill: ${(props) => props.theme.colors.envy};
`;

const Item = styled(Flex)`
  align-items: center;
  color: reset;
  gap: 18px;
  padding: 12px;
  width: 100%;

  :hover {
    background: ${({ theme }) => theme.colors.navy};
    border-radius: 8px;
    cursor: pointer;
  }
`;

export { FollowList };
