import { gql } from "@apollo/client";

export const PIN_CLIP_MUTATION = gql`
  mutation PinClip($input: ClipPinInput!) {
    clipPin(input: $input) {
      clip {
        id
      }
      profile {
        id
        username
        pinnedClips {
          id
          internalId
          thumbnailUrl(style: STANDARD)
          isPinned
          title
          shareId
          createdAt
          views
          previewUrl
          user {
            _id
            username
            avatar
          }
        }
      }
      clientMutationId
    }
  }
`;

export const UNPIN_CLIP_MUTATION = gql`
  mutation UnpinClip($input: ClipUnpinInput!) {
    clipUnpin(input: $input) {
      clip {
        id
      }
      profile {
        id
        username
        pinnedClips {
          id
          internalId
          thumbnailUrl(style: STANDARD)
          isPinned
          title
          shareId
          createdAt
          views
          previewUrl
          user {
            _id
            username
            avatar
          }
        }
      }
      clientMutationId
    }
  }
`;
