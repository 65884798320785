import React, { useEffect } from "react";
import { FloatingContainer } from "../../Wrappers";
import { Flex } from "rebass/styled-components";
import { Title3, Body, Headline } from "../../Text";
import { Error } from "../../Inputs";
import Lottie from "lottie-react";
import crop from "../../../../animations/crop.json";
import styled from "styled-components";
import { getClipTitle } from "../../../Utilities/Clip";

import { useFetch } from "../../../../hooks";
import { useHistory } from "react-router";
import { trackClipDownload } from "../../../../utils";
import { useUser } from "../../../Hooks/useUser";
import { EPaywallType } from "../Paywall";
import { useInteractonInterceptor } from "../../../Hooks/useInteractionInterceptor";
import { URLS } from "../../../Utilities/Maps";
import { RiComputerLine } from "react-icons/ri";
import { ShareButton } from "../../Button/Share";
import { MdPhoneAndroid } from "react-icons/md";
const ButtonText = styled.span``;

const lottieStyle = {
  height: 80,
};

export function DownloadModal({
  clip,
  onSuccess,
  displayPaywall,
  ...props
}: any) {
  const checkFeatureAccessQuery = useInteractonInterceptor();
  const clipLink = `${window.location.hostname}/clip?clip=${clip.shareId}`;
  const history = useHistory();
  const commonProps = {
    title: clip.title || getClipTitle(clip),
    size: "social",
    clip: clip,
    clipLink: clip.url || clipLink,
  };
  const { query, fetch }: any = useFetch(undefined, true);
  const { allstarUser } = useUser();
  const isOwnClip = allstarUser?.user?._id === clip.user._id;
  const mobileData = clip.mobile?.find(
    (x: any) => x.aspectRatio === (props.aspectRatio ?? "9:16"),
  );
  const canRequestMobile =
    !mobileData?.status && (isOwnClip || allstarUser?.user?.admin);
  const canDownloadMobile = ["Processed", "Ready"].includes(mobileData?.status);

  let buttonText = "Save to Mobile Clips";
  const inProcessing = ["Queued", "Submitted"].includes(mobileData?.status);
  if (inProcessing) buttonText = "Requested";
  if (canDownloadMobile) buttonText = "Download now";
  if (mobileData?.status === "Error") buttonText = "Not available";

  const onMobileDownload = async () => {
    if (canDownloadMobile) {
      trackClipDownload(clip, mobileData.aspectRatio, {
        user: allstarUser?.user?.profile?.username || allstarUser?.user?._id,
        originalClip: clip.shareId,
      });

      if (["Processed", "Ready"].includes(mobileData?.status)) {
        try {
          const data = await checkFeatureAccessQuery({
            variables: { feature: "FREE_ENGAGED_DOWNLOAD" },
          });

          const paywalledUser = data?.data?.checkFeatureAccess;

          if (paywalledUser) {
            return closeCurrentModalAndDisplayPaywall(EPaywallType.download);
          }
        } catch (e) {}

        window.open(
          mobileData.url ||
            `${process.env.REACT_APP_S3_CLIP_BUCKET}/${mobileData.clipLink}`,
          "_blank",
        );
      }
    } else if (canRequestMobile) {
      fetch({
        url: `${process.env.REACT_APP_API_URL}/items/clip/mobile`,
        method: "POST",
        data: {
          _id: clip._id,
          aspectRatio: "9:16",
          resolution: "1080x1920",
        },
      });
    } else if (inProcessing) {
      history.push(`${URLS.PROFILE}?view=MOBILE`);
    }
  };

  useEffect(() => {
    if (query.status === "success") {
      history.push(`${URLS.PROFILE}?view=MOBILE`);
      onSuccess(false);
    } else {
      if (query.error && query?.error?.version) {
        closeCurrentModalAndDisplayPaywall(EPaywallType.mobile);
      }
    } // eslint-disable-next-line
  }, [query.status, history, onSuccess]);

  const closeCurrentModalAndDisplayPaywall = (type: any) => {
    displayPaywall(type);
  };
  return (
    <>
      <FloatingContainer
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        maxWidth="525px"
      >
        <>
          <Flex
            mb={4}
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Lottie animationData={crop} style={lottieStyle} loop={true} />
            <Flex mt={2} flexDirection="column">
              <Title3 my={2} textAlign="center" mb={2}>
                Get a Mobile version of your clip!
              </Title3>
              <Body mb={3} textAlign="center" color="chalk">
                All your favorite HUD elements, your kill feed and creator cards
                automatically repositioned, perfect for TikTok and YouTube.
              </Body>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Flex
              flexDirection={["column", "row"]}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <MobileDownload
                {...commonProps}
                // href={
                //   ["Processed", "Ready"].includes(mobileData?.status)
                //     ? `${process.env.REACT_APP_S3_CLIP_BUCKET}/${mobileData.clipLink}`
                //     : undefined
                // }
                // target={
                //   ["Processed", "Ready"].includes(mobileData?.status)
                //     ? "_blank"
                //     : undefined
                // }
                onClick={onMobileDownload}
                disabled={
                  query.status === "loading" ||
                  mobileData?.status === "Error" ||
                  !(canDownloadMobile || canRequestMobile)
                }
                classification={inProcessing ? "secondary" : undefined}
              >
                <ButtonText>{buttonText}</ButtonText>
              </MobileDownload>
              <CinemaDownload
                {...commonProps}
                onClick={async () => {
                  try {
                    const data = await checkFeatureAccessQuery({
                      variables: { feature: "FREE_ENGAGED_DOWNLOAD" },
                    });
                    const paywalledUser = data?.data?.checkFeatureAccess;

                    if (paywalledUser) {
                      return closeCurrentModalAndDisplayPaywall(
                        EPaywallType.download,
                      );
                    }
                  } catch (e) {}

                  window.open(
                    clip.url ||
                      `${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipLink}`,
                    "_blank",
                  );
                  trackClipDownload(clip, false, {});
                }}
              >
                <ButtonText>Download Now</ButtonText>
              </CinemaDownload>
            </Flex>
            {query.error && query?.error?.version !== 2 && (
              <Error>{query.error.message ?? "An error occured"}</Error>
            )}
          </Flex>
        </>
      </FloatingContainer>
    </>
  );
}

function CinemaDownload({ onClick, ...props }: any) {
  return (
    <>
      <StyledContainer
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="column"
      >
        {" "}
        <Flex
          alignItems="center"
          flexDirection={["row", "row", "column", "column"]}
        >
          <StyledComputer size={24}></StyledComputer>
          <Headline mx={[3, 3, 0, 0]} my={[0, 0, 3, 3]}>
            Cinema Style
          </Headline>
          <Body color="chalk">16:9</Body>
        </Flex>
        <ShareButton
          my={4}
          justifyContent="space-between"
          classification="secondary"
          alignItems="center"
          flex
          href={
            onClick
              ? undefined
              : props.clip.url ||
                `${process.env.REACT_APP_S3_CLIP_BUCKET}/${props.clip.clipLink}`
          }
          target={onClick ? undefined : "_blank"}
          onClick={() => {
            onClick();
          }}
          {...props}
        >
          <Flex alignItems="center">{props.children}</Flex>
        </ShareButton>
      </StyledContainer>
    </>
  );
}

function MobileDownload({ onClick, ...props }: any) {
  return (
    <>
      <Flex
        mb={[5, 5, 0, 0]}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <StyledContainer
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="column"
        >
          {" "}
          <Flex
            alignItems="center"
            flexDirection={["row", "row", "column", "column"]}
          >
            <StyledMobile size={24}></StyledMobile>
            <Headline mx={[3, 3, 0, 0]} my={[0, 0, 3, 3]}>
              Mobile Optimized
            </Headline>
            <Body color="chalk">9:16</Body>
          </Flex>
          <ShareButton
            my={4}
            justifyContent="space-between"
            classification="main"
            alignItems="center"
            flex
            onClick={onClick}
            {...props}
          >
            <Flex alignItems="center">{props.children}</Flex>
          </ShareButton>
        </StyledContainer>
      </Flex>
    </>
  );
}

const StyledComputer = styled(RiComputerLine)`
  position: relative;
  color: ${(props) => props.theme.colors.envy};
`;

const StyledContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.ned};
  padding: 10px;
  border-radius: 10px;
  width: 200px;
  height: 175px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 120px;
    width: 290px;
  }
`;
const StyledMobile = styled(MdPhoneAndroid)`
  position: relative;
  color: ${(props) => props.theme.colors.envy};
`;
