import React from "react";
import { Box, Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import theme from "../../../../../theme/themeThree";
import { getCardTitle } from "../../../../Utilities/Studio";
import { Headline } from "../../../Text";
import { AddCard } from "../AddCard";
import { TagRow } from "../TagRow";
import { SeriesRow } from "../../../../Views/CreateMontage/Components/Loadouts/Components/SeriesRow";
import { ReactComponent as Mythic } from "../../../../Assets/Tiers/Badges/mythic.svg";
import { ReactComponent as Legendary } from "../../../../Assets/Tiers/Badges/legendary.svg";
import { ReactComponent as Rare } from "../../../../Assets/Tiers/Badges/rare.svg";
import { ReactComponent as Premium } from "../../../../Assets/Tiers/Badges/premium.svg";
import { ReactComponent as Standard } from "../../../../Assets/Tiers/Badges/standard.svg";
import { Tooltip } from "../../../../Components/Tooltip";
import { RarityTooltipContent } from "../../../Tooltip/Components/RarityToolTIp";
import { useIsDesktopApp } from "../../../../Hooks/useIsDesktopApp";
import { useIsMobileApp } from "../../../../Hooks/useIsMobileApp";

export const Controls = ({
  handleCardClick,
  card,
  handleToggle,
  onQuery,
  isActive,
  outsideControl,
  loadout,
  tooltipContent,
  setModal,
  hideToolTip = true,
  hasScope,
}: any) => {
  const { isMobileApp } = useIsMobileApp();
  const { isDesktop } = useIsDesktopApp();
  const showCampaign = card?.ui?.campaign && !isMobileApp && !isDesktop;

  return (
    <>
      <StyledDescription
        flexDirection="column"
        justifyContent="space-between"
        onClick={() => {
          handleCardClick(card);
        }}
      >
        <GradientBox p={[3, 3, 4, 4]} rarity={card.ui.rarity}>
          <Flex justifyContent="space-between" alignItems={"center"} mb={2}>
            <Headline>{getCardTitle(card)}</Headline>
            <Box>
              <Tooltip
                position={{
                  top: "-45px",
                  padding: "7px",
                  right: "0px",
                }}
                hide={hideToolTip}
                theme="helpTooltip"
                align="right"
                timeout={true}
                tooltipContent={tooltipContent}
              >
                <AddCard
                  card={card}
                  handleToggle={handleToggle}
                  key={card._id}
                  isActive={isActive}
                  outsideControl={outsideControl}
                />
              </Tooltip>
            </Box>
          </Flex>
          <Flex
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Flex flexDirection="row">
              <TagRow card={card} onQuery={onQuery} />{" "}
            </Flex>
            <Flex flexShrink={0}>
              {" "}
              {showCampaign ? (
                <Box
                  onClick={(e) => {
                    setModal({
                      type: "campaign",
                      card: card,
                    });

                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <StyledImage
                    src={card?.ui?.campaign?.campaignBlurbs?.creatorCard?.image}
                  />
                </Box>
              ) : (
                <Tooltip
                  position={{
                    bottom: "3px",
                    right: "35px",
                  }}
                  hide={false}
                  align="right"
                  theme={getVariant(card.ui.rarity)}
                  timeout={true}
                  tooltipContent={
                    <RarityTooltipContent rarity={card.ui.rarity} />
                  }
                >
                  <Box
                    onClick={(e) => {
                      setModal({
                        type: card.ui.rarity,
                        data: { card: card, hasScope: hasScope },
                      });
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <TierBadge rarity={card.ui.rarity} />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </GradientBox>

        {loadout && <SeriesRow {...loadout} />}
      </StyledDescription>
    </>
  );
};

const StyledImage = styled(Image)`
  height: 30px;
  width: auto;
`;

const TierBadge = ({ rarity }: any) => {
  //@chris manages badges
  if (rarity === 500) return <StyledMythic />; //mythic
  if (rarity === 400) return <StyledLegendary />; //legendary
  if (rarity === 300) return <StyledRare />; //rare
  if (rarity === 200) return <StyledPremium />; //premium
  if (rarity === 100) return <StyledStandard />; //standard
  return <></>;
};

const getVariant = (rarity: any) => {
  if (rarity === 500) return "mythicTooltip"; //mythic
  if (rarity === 400) return "legendaryTooltip"; //legendary
  if (rarity === 300) return "rareTooltip"; //rare
  if (rarity === 200) return "premiumTooltip"; //premium
  if (rarity === 100) return "standardTooltip"; //standard
  return "";
};

const GradientBox: any = styled(Box)`
  background: ${(props: any) => {
    if (props.rarity === 500) {
      return `linear-gradient(180deg, ${props.theme.colors.mythicAlpha10} 0%, rgba(0,0,0,0) 70%);`;
    }
    //@Chris, manages gradient in controls area
    //legendary, todo
    if (props.rarity === 400) {
      return `linear-gradient(180deg, ${props.theme.colors.legendaryAlpha10} 0%, rgba(0,0,0,0) 70%);`;
    }

    //rare, todo
    if (props.rarity === 300) {
      return `linear-gradient(180deg, ${props.theme.colors.rareAlpha10} 0%, rgba(0,0,0,0) 70%);`;
    }

    //premium, todo
    if (props.rarity === 200) {
      return `linear-gradient(180deg, ${props.theme.colors.premiumAlpha20} 0%, rgba(0,0,0,0) 70%);`;
    }

    //standard, todo
    if (props.rarity === 100) {
      return `linear-gradient(180deg, ${props.theme.colors.midnightAlpha30} 0%, rgba(0,0,0,0) 70%);`;
    }

    return `linear-gradient(180deg, ${props.theme.colors.midnightAlpha30} 0%, rgba(0,0,0,0) 70%);`;
  }};
`;

const StyledMythic = styled(Mythic)`
  height: 32px;
  width: 32px;
`;

const StyledLegendary = styled(Legendary)`
  height: 32px;
  width: 32px;
`;

const StyledRare = styled(Rare)`
  height: 34px;
  width: 34px;
`;

const StyledPremium = styled(Premium)`
  height: 32px;
  width: 32px;
`;

const StyledStandard = styled(Standard)`
  height: 32px;
  width: 32px;
`;

const StyledDescription = styled(Flex)`
  background: ${theme.colors.midnightAlpha50}};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
