import { atom } from "recoil";
import { persistAtom } from ".";

export const SuggestedFollowing = atom({
  key: "SuggestedFollowing_v1",
  default: {
    ids: [],
  },
  effects_UNSTABLE: [persistAtom],
});
