import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { ESupportedGames, TGameFilterProps } from "../../@types";
import { StudioIcon } from "../../Views/Studio/Components/StudioIcons";
import { EStudioIconType } from "../../Views/Studio/@types";
import {
  RoundedButton,
  RoundedRectangularButton,
} from "../Button/WithActiveState";
import { FaChevronDown } from "react-icons/fa";
import { Tooltip } from "../Tooltip";
import { MdOutlineGamepad } from "react-icons/md";
import { CustomPill, animationProps } from "MVVM/Components/CustomPill";
import theme from "theme/themeThree";

const StyledChevronDown = styled(FaChevronDown)`
  margin-left: 5px;
  fill: ${({ theme }) => theme.colors.chalk};
  height: 14px;
  width: 14px;
  flex-shrink: 0;
`;

const StyledGamePad = styled(MdOutlineGamepad)`
  color: ${(props: any) => {
    return props.theme.colors.envy;
  }};
`;

const StyledCustomPill = styled(CustomPill)`
  position: absolute;
  top: -12px;
  right: -10px;
  padding: 5px;
  font-size: 12px;
`;

type TGameToIconMap = {
  [key in ESupportedGames]: React.ReactNode;
};

export const GAME_TO_ICON_MAP: TGameToIconMap = {
  [ESupportedGames.CS2]: (
    <StudioIcon
      type={EStudioIconType.CS2}
      size={20}
      styles={{ flexShrink: 0 }}
    />
  ),
  [ESupportedGames.CSGO]: (
    <StudioIcon
      type={EStudioIconType.CSGO}
      size={20}
      styles={{ flexShrink: 0 }}
    />
  ),
  [ESupportedGames.DOTA2]: (
    <StudioIcon
      type={EStudioIconType.Dota2}
      size={20}
      styles={{ flexShrink: 0 }}
    />
  ),
  [ESupportedGames.FORTNITE]: (
    <StudioIcon
      type={EStudioIconType.Fortnite}
      size={20}
      styles={{ flexShrink: 0 }}
    />
  ),
  [ESupportedGames.LOL]: (
    <StudioIcon
      type={EStudioIconType.LoL}
      size={20}
      styles={{ flexShrink: 0 }}
    />
  ),
  [ESupportedGames.ALL]: (
    <>
      <StyledGamePad size={20} />
      <div
        style={{
          paddingLeft: "5px",
          color: theme.colors.envy,
          whiteSpace: "nowrap",
        }}
      >
        Filter Feed
      </div>
    </>
  ),
  [ESupportedGames.NONE]: <StyledGamePad size={20} />,
};

export function GameSelector({
  filterGame,
  game,
  dropdown,
  handleResetToAllGames,
}: TGameFilterProps) {
  const handleGameClick = (game: ESupportedGames) => {
    filterGame(game);
  };

  if (dropdown)
    return (
      <Box style={{ minWidth: "87px" }}>
        <StyledToolTip
          position={{
            top: 40,
          }}
          tooltipContent={
            <>
              <Flex
                flexDirection="column"
                style={{ gap: 5, width: "110px", zIndex: 100 }}
              >
                <RoundedRectangularButton
                  active={game === ESupportedGames.ALL}
                  onClick={() => {
                    if (handleResetToAllGames) {
                      handleResetToAllGames();
                    } else {
                      handleGameClick(ESupportedGames.ALL);
                    }
                  }}
                >
                  <StyledGamePad size={20} />
                  <Box style={{ marginLeft: "5px" }}>ALL</Box>
                </RoundedRectangularButton>
                <RoundedRectangularButton
                  mr={2}
                  active={game === ESupportedGames.CS2}
                  onClick={() => {
                    handleGameClick(ESupportedGames.CS2);
                  }}
                >
                  {GAME_TO_ICON_MAP[ESupportedGames.CS2]}
                  <Box>CS2</Box>
                </RoundedRectangularButton>
                <RoundedRectangularButton
                  mr={2}
                  active={game === ESupportedGames.CSGO}
                  onClick={() => {
                    handleGameClick(ESupportedGames.CSGO);
                  }}
                >
                  {GAME_TO_ICON_MAP[ESupportedGames.CSGO]}
                  <Box>CS:GO</Box>
                </RoundedRectangularButton>
                <RoundedRectangularButton
                  mr={2}
                  active={game === ESupportedGames.DOTA2}
                  onClick={() => {
                    handleGameClick(ESupportedGames.DOTA2);
                  }}
                >
                  {GAME_TO_ICON_MAP[ESupportedGames.DOTA2]}
                  <Box>Dota 2</Box>
                </RoundedRectangularButton>
                <RoundedRectangularButton
                  mr={2}
                  active={game === ESupportedGames.FORTNITE}
                  onClick={() => {
                    handleGameClick(ESupportedGames.FORTNITE);
                  }}
                >
                  {GAME_TO_ICON_MAP[ESupportedGames.FORTNITE]}
                  <Box>Fortnite</Box>
                </RoundedRectangularButton>
                <RoundedRectangularButton
                  active={game === ESupportedGames.LOL}
                  onClick={() => {
                    handleGameClick(ESupportedGames.LOL);
                  }}
                >
                  {GAME_TO_ICON_MAP[ESupportedGames.LOL]}
                  <Box>LoL</Box>
                </RoundedRectangularButton>
              </Flex>
            </>
          }
        >
          <RoundedButton
            active={game !== ESupportedGames.ALL}
            onClick={() => {}}
            style={{ justifyContent: "center", minHeight: "46px" }}
          >
            {game === ESupportedGames.ALL && (
              <StyledCustomPill {...animationProps}>
                <span>NEW</span> {}
              </StyledCustomPill>
            )}
            {GAME_TO_ICON_MAP[game]}
            <StyledChevronDown />
          </RoundedButton>
        </StyledToolTip>
      </Box>
    );

  return (
    <StyledGameSelector>
      <RoundedButton
        mr={2}
        active={game === ESupportedGames.CS2}
        onClick={() => {
          handleGameClick(ESupportedGames.CS2);
        }}
      >
        <StudioIcon type={EStudioIconType.CS2} size={["20", "25"]} />
        <TextHide>CS2</TextHide>
      </RoundedButton>
      <RoundedButton
        mr={2}
        active={game === ESupportedGames.CSGO}
        onClick={() => {
          handleGameClick(ESupportedGames.CSGO);
        }}
      >
        <StudioIcon type={EStudioIconType.CSGO} size={["20", "25"]} />
        <TextHide>CS:GO</TextHide>
      </RoundedButton>
      <RoundedButton
        mr={2}
        active={game === ESupportedGames.DOTA2}
        onClick={() => {
          handleGameClick(ESupportedGames.DOTA2);
        }}
      >
        <StudioIcon type={EStudioIconType.Dota2} size={["20", "25"]} />
        <TextHide>Dota 2</TextHide>
      </RoundedButton>
      <RoundedButton
        mr={2}
        active={game === ESupportedGames.FORTNITE}
        onClick={() => {
          handleGameClick(ESupportedGames.FORTNITE);
        }}
      >
        <StudioIcon type={EStudioIconType.Fortnite} size={["20", "25"]} />
        <TextHide>Fortnite</TextHide>
      </RoundedButton>
      <RoundedButton
        active={game === ESupportedGames.LOL}
        onClick={() => {
          handleGameClick(ESupportedGames.LOL);
        }}
      >
        <StudioIcon type={EStudioIconType.LoL} size={["20", "25"]} />
        <TextHide>LoL</TextHide>
      </RoundedButton>
    </StyledGameSelector>
  );
}

const StyledToolTip = styled(Tooltip)`
  &:hover {
    cursor: pointer;
  }
`;
const StyledGameSelector = styled(Flex)`
  background-color: ${(props: any) => {
    return props.theme.colors.ned;
  }};
  padding-bottom: 15px;
  z-index: 21;
  @media (max-width: ${(props: any) => props.theme.breaks.medium}) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;

const TextHide = styled(Box)`
  display: flex;
  @media (max-width: ${(props: any) => props.theme.breaks.medium}) {
    display: none;
  }
`;
