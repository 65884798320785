import React, { useCallback, useEffect } from "react";
import { ReactComponent as TikTok } from "../../../../Assets/Platforms/tiktokLight.svg";
import { Button } from "../..";
import { useUser } from "../../../../Hooks/useUser";
import { ModalType } from "../../../../@types";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../../State/modals";
import styled from "styled-components";

export function TikTokUpload({ clip, hasMobile, children }: any) {
  const { allstarUser } = useUser();
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const actualClipDuration = clip.clipLengthSeconds;
  let disableReason = "";

  const MIN_SECONDS = 3;
  const MAX_SECONDS = 60;
  const FIFTY_MB = 50 * 1024 * 1000;

  const handleOpenTikTokModal = useCallback(() => {
    if (!allstarUser?.loggedIn) {
      return setAllstarModalState({
        data: {
          action: "signup",
        },
        functions: {},
        isOpen: ModalType.Signup,
      });
    }

    if (!allstarUser?.user?.tiktok?.hasUploadScope) {
      return setAllstarModalState({
        data: {
          clip: clip,
          platform: "TikTok",
          duration: actualClipDuration,
          next: `/clip?clip=${clip.shareId}`,
        },
        functions: {},
        isOpen: ModalType.LinkYourSocial,
      });
    }

    if (!hasMobile) {
      return setAllstarModalState({
        data: {
          clip: clip,
          disableReason,
          duration: actualClipDuration,
          platform: "TikTok",
        },
        functions: {},
        isOpen: ModalType.TikTokUpload,
      });
    }

    setAllstarModalState({
      data: {
        clip: clip,
        disableReason,
        duration: actualClipDuration,
        platform: "TikTok",
      },
      functions: {},
      isOpen: ModalType.MobileOrDesktopUpload,
    });
  }, [
    allstarUser?.loggedIn,
    clip,
    actualClipDuration,
    hasMobile,
    setAllstarModalState,
    allstarUser?.user?.tiktok,
  ]);

  useEffect(() => {
    if (window.location.search.includes("tiktok")) handleOpenTikTokModal();
  }, [handleOpenTikTokModal]);

  const clipTooLarge =
    clip.clipSizeBytes > FIFTY_MB || (clip.bytes && clip.bytes > FIFTY_MB);

  if (
    actualClipDuration < MIN_SECONDS ||
    actualClipDuration + 5 > MAX_SECONDS
  ) {
    disableReason = "Cannot be published to TikTok as it's ";
    disableReason += `${
      actualClipDuration < MIN_SECONDS ? "shorter" : "longer"
    } than ${actualClipDuration < MIN_SECONDS ? MIN_SECONDS : MAX_SECONDS}s`;
  } else if (clipTooLarge) {
    disableReason =
      "Sorry, this clip is too large to share directly on TikTok. You can try downloading it and uploading it yourself in the TikTok app.";
  }

  return (
    <StyledButton
      title={disableReason || "Publish to TikTok"}
      display="flex"
      size="publish"
      classification="tiktok"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      flex={true}
      onClick={handleOpenTikTokModal}
    >
      <TikTok
        style={{
          height: "20px",
          width: "20px",
          position: "relative",
        }}
      />
      {children}
    </StyledButton>
  );
}
const StyledButton = styled(Button)`
  background-color: black;
  :hover {
    background-color: black;
    opacity: 0.8;
  }
`;
