import { Box, Text } from "rebass/styled-components";
import styled from "styled-components";

import { FeedItem } from "./FeedItem";
import { FeedType } from "../@types";
import { FeedViewModel } from "./ViewModel";
import { TClip } from "MVVM/@types";
import { Link } from "react-router-dom";
import { LoadingSkeleton } from "MVVM/Components/Skeloton";
import { analytics } from "MVVM/Analytics";
import { EmptyList } from "../EmptyList";

interface IFeedProps {
  referrer?: string;
  type: FeedType;
}

const Feed = ({ referrer, type }: IFeedProps) => {
  const { data, error, loading } = FeedViewModel(type);

  if (loading)
    return (
      <FeedContainer>
        {Array(8)
          .fill("")
          .map((_, index) => (
            <LoadingSkeleton
              height={100}
              style={{ margin: "6px 0" }}
              key={index}
            />
          ))}
      </FeedContainer>
    );

  if (error || !data)
    return (
      <FeedContainer>
        <Text fontWeight={500}>Something went wrong.</Text>
      </FeedContainer>
    );

  if (!data.clipsRecommended.length)
    return <EmptyList text="Nothing else to see" />;

  return (
    <FeedContainer>
      {data.clipsRecommended.map((clip: TClip, index: number) => (
        <Link
          to={{
            pathname: `/clip/${clip.shareId}`,
            state: { autoplay: true, referrer },
          }}
          key={index}
          style={{ color: "unset", textDecoration: "none" }}
          onClick={() => analytics.track("Clip Page - Feed Clip Clicked", {})}
        >
          <FeedItem clip={clip} />
        </Link>
      ))}
    </FeedContainer>
  );
};

const FeedContainer = styled(Box)`
  background: ${({ theme }) => theme.colors.darkerNed};
  height: 100%;
  padding: 12px;
  width: 100%;
  overflow: auto;
`;

export { Feed };
