import { flexShrink, opacity } from "styled-system";
import { rgba } from "../utils";
import { colors } from "./colors";
import { themeOne } from "./themeOne";
import merge from "lodash.merge";

const navAndFooterZIndex = 20;

const theme = {
  breakpoints: ["425px", "715px", "905px", "1280px"],
  breaks: {
    narrow: "425px",
    small: "715px",
    medium: "905px",
    theater: "1100px",
    standard: "1280px",
  },
  space: [0, 2, 5, 10, 15, 20, 25, 30, 40, 60, 90, 120],

  sizes: [0, 80, 320, 400, 425, 675, 905, 1080, 1280, 1790],
  fontSizes: [
    ".725em",
    "0.875em",
    "1em",
    "1.125em",
    "1.25em",
    "1.375em",
    "1.75em",
    "2.125em",
    "3em",
    "3.75em",
  ],

  lineHeights: [
    "1.8em",
    "1.4285em",
    "1.375em",
    "1.222em",
    "1.25em",
    "1.272em",
    "1.214em",
    "1.205em",
    "1.458em",
    "1.866em",
  ],

  fontWeights: {
    footnote: 400,
    subheader: 500,
    body: 400,
    headline: 500,
    title3: 500,
    title2: 400,
    title1: 400,
    large3: 500,
    large3bold: 700,
    large2: 400,
    large1: 400,
    large1Bold: 700,
  },
  text: {
    footnote: {
      fontSize: ".725em",
      fontWeight: 400,
      lineHeight: "1.8em",
    },
    subheader: {
      fontSize: "0.875em",
      fontWeight: 500,
      lineHeight: "1.4285em",
    },
    body: {
      fontSize: "1em",
      fontWeight: 400,
      lineHeight: "1.375em",
    },
    headline: {
      fontSize: "1.125em",
      fontWeight: 500,
      lineHeight: "1.222em",
    },
    title3: {
      fontSize: "1.25em",
      fontWeight: 500,
      lineHeight: "1.25em",
    },
    title2: {
      fontSize: "1.375em",
      fontWeight: 400,
      lineHeight: "1.272em",
    },
    title1: {
      fontSize: "1.75em",
      fontWeight: 400,
      lineHeight: "1.214em",
    },
    large3: {
      fontSize: "2.125em",
      fontWeight: 500,
      lineHeight: "1.205em",
    },
    large3bold: {
      fontSize: "2.125em",
      fontWeight: 700,
      lineHeight: "1.458em",
    },
    large2: {
      fontSize: "3em",
      fontWeight: 400,
      lineHeight: "1.866em",
    },
    large1: {
      fontSize: "3.75em",
      fontWeight: 400,
      lineHeight: "1.866em",
    },
    large1Bold: {
      fontSize: "3.75em",
      fontWeight: 400,
      lineHeight: "1.866em",
    },

    //new font sizes
    paragraphSmall: {
      fontFamily: "Barlow",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
    },
  },
  maxWidth: "1790px",

  colors: {
    ...colors,
    bodyColor: colors.navyAlpha90,
    spectrum: "",
    reverseSpectrum: "",
    InfluencerAvatarGradient: "",
    MontageGradient: "",
    SuperstarsGradient: "",
    ClipOfTheDay: "",
    redGradient: "",
    saleGradient: "",
    saleCarouselGradient: "",
    remixGradientDark: "",
    remixGradientDarkReverse: "",
    remixOverlayGradient: "",
    carouselGradient: "",
  },

  fonts: {
    body: "Barlow",
  },

  transitionDuration: ".15s",

  input: {
    bg: "#fff",
    borderWidth: "1px",
    borderColor: "#fff",
    borderRadius: 8,
    color: "navy",
    fontFamily: "Barlow",
    transitionProperty: "border",
    transitionDuration: "",
    py: 0,
    ":disabled": {
      color: "chalkAlpha60",
      cursor: "no-drop",
    },
    ":not(:disabled):hover": {
      borderColor: "chalkAlpha40",
      bg: "nedLight",
    },
    ":not(:disabled):active": {
      borderColor: "envy",
    },
    ":not(:disabled):focus": {
      outline: "0",
      borderColor: "envy",
    },
    "::placeholder": {
      color: "chalk",
    },
    "::boxshadow": {
      color: "ice",
    },
  },

  inlineInput: {
    bg: "ned",
    borderWidth: "1px",
    borderColor: "ned",
    borderRadius: 8,
    color: "ice",
    fontFamily: "Barlow",
    fontWeight: 500,
    transitionProperty: "border",
    py: 3,
    ":disabled": {
      color: "chalkAlpha60",
      cursor: "no-drop",
    },
    ":not(:disabled):hover": {
      bg: "#224040",
      borderColor: "ned",
    },
    ":not(:disabled):active": {
      borderColor: "envy",
    },
    ":not(:disabled):focus": {
      outline: "0",
      borderColor: "ned",
      bg: "ned",
    },
    "::placeholder": {
      color: "chalk",
    },
    "::boxshadow": {
      color: "darkerNed",
    },
  },

  labeledInput: {
    borderColor: "chalkAlpha20",
    borderWidth: "1px",
  },

  link: {
    textDecoration: "none",
    transitionProperty: "color",
    color: "inherit",
    transitionDuration: "",
  },

  navBase: {
    height: "100vh",
    width: "96px",
    padding: "15px",
    bg: "nedLight",
    borderRight: "1px solid",
    borderColor: "darkerNavy",
    "@media (max-width: 905px)": {
      height: "60px",
      width: "100vw",
      padding: "15px",
      borderRight: "0px",
      bg: "ned",
      borderBottom: "1px solid",
      borderColor: "darkerNavy",
    },
  },

  studioNavBase: {
    height: "64px",
    width: "100vw",
    position: "fixed",
    padding: "15px",
    zIndex: 20,
  },

  studioNav: {
    bg: "turbo",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
  },

  headerNavBase: {
    height: "64px",
    width: "100%",
    position: "fixed",
    padding: "15px",
    zIndex: 20,
  },

  headerNav: {
    bg: "turbo",
    ".scrolled &": {
      bg: "ned",
      // boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
    },
  },

  nav: {
    color: "ice",
  },

  navPlaceholder: {
    height: "60px",
    width: "100%",
  },

  navSignup: {
    width: "100%",
    bg: "transparent",
    position: "absolute",
    zIndex: 21,
  },

  navMobile: {
    color: "ice",
    background: "darkerNed",
    width: "100%",
    height: "100vh",
    pb: 7,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99,
  },

  settingsContainer: {
    borderWidth: "1px",
    borderColor: "chalkAlpha20",
    borderStyle: "solid",
    borderRadius: "10px",
    bg: "white",
    px: [5, 5, 7],
    py: [5, 5, 7],
    width: "100%",
  },

  subscriptionsContainer: {
    borderWidth: "1px",
    borderColor: "midnightAlpha50",
    borderStyle: "solid",
    borderRadius: "10px",
    bg: "ned",
    px: [5, 5, 7],
    py: [5, 5, 7],
    width: "100%",
  },

  descriptionContainer: {
    borderRadius: "10px",
    px: [5, 5, 7],
    py: [5, 5, 7],
    width: "100%",
  },

  remixContainer: {
    p: 5,
    width: "100%",
    bg: "midnightAlpha50",
    "@supports (backdrop-filter: blur(70px))": {
      "&": {
        bg: "midnightAlpha50",
        "backdrop-filter": "blur(70px)",
      },
    },
  },

  menu: {
    borderWidth: "1px",
    borderColor: "chalkAlpha20",
    borderStyle: "solid",
    borderRadius: "10px",
    bg: "white",
    padding: 3,
    color: "navy",
  },

  floatingNav: {
    borderRadius: "10px",
    color: "navy",
    bg: "#fff",
    padding: 5,
  },

  tabNav: {
    bg: "transparent",
    minHeight: "60px",
    width: "100%",
  },

  floatingContainer: {
    bg: "rgba(28, 42, 52, 1)",
    "@supports (backdrop-filter: blur(70px))": {
      "&": {
        bg: "rgba(28, 42, 52, 1)",
        "backdrop-filter": "blur(70px)",
      },
    },
  },

  bottomContainer: {
    bg: "darkerNed",
  },

  helpTooltip: {
    boxShadow: "0px 4px 24px rgba(0, 0, 0, .1)",
    bg: "midnight",
    color: "ice",
    fontSize: 1,
    fontWeight: 500,
    padding: 5,
    borderRadius: "6px",
    "&:before": {
      borderBottomColor: "midnight",
    },
  },

  mythicTooltip: {
    boxShadow: "0px 4px 24px rgba(0, 0, 0, .4)",
    bg: "#313452",
    color: "ice",
    fontSize: "14px",
    padding: 2,
    borderRadius: "10px",
    borderStroke: "1px",
    borderColor: "legendary",
    borderStyle: "solid",
  },

  legendaryTooltip: {
    boxShadow: "0px 4px 24px rgba(0, 0, 0, .4)",
    bg: "#313452",
    color: "ice",
    fontSize: "14px",
    padding: 2,
    borderRadius: "10px",
    width: "145px",
    borderStroke: "1px",
    borderColor: "legendary",
    borderStyle: "solid",
  },

  rareTooltip: {
    boxShadow: "0px 4px 24px rgba(0, 0, 0, .4)",
    bg: "#493A56",
    color: "ice",
    fontSize: "14px",
    padding: 2,
    borderRadius: "10px",
    width: "108px",
    borderStroke: "1px",
    borderColor: "rare",
    borderStyle: "solid",
  },

  premiumTooltip: {
    boxShadow: "0px 4px 24px rgba(0, 0, 0, .4)",
    bg: "#2B4A55",
    color: "ice",
    fontSize: "14px",
    padding: 2,
    borderRadius: "10px",
    width: "135px",
    borderStroke: "1px",
    borderColor: "premium",
    borderStyle: "solid",
  },

  standardTooltip: {
    boxShadow: "0px 4px 24px rgba(0, 0, 0, .4)",
    bg: "#212A32",
    color: "ice",
    fontSize: "14px",
    padding: 2,
    borderRadius: "10px",
    width: "137px",
    borderStroke: "1px",
    borderColor: "envy",
    borderStyle: "solid",
  },

  menuLink: {
    color: "navy",
    display: "flex",
    fontSize: 1,
    fontWeight: 500,
    alignItems: "center",
    px: 4,
    py: 3,
    marginBottom: 2,
    borderRadius: 8,
    ":hover": {
      bg: "darkerIce",
    },
    "&.active": {
      color: "navy",
      bg: "envyAlpha10",
      svg: {
        stroke: "envy",
      },
    },
  },
  menuButton: {
    color: "navy",
    display: "flex",
    backgroundColor: "transparent",
    fontSize: 1,
    fontWeight: 500,
    alignItems: "center",
    px: 4,
    py: 3,
    marginBottom: 2,
    borderRadius: 8,
    ":hover": {
      bg: "darkerIce",
    },
    "&.active": {
      color: "navy",
      bg: "envyAlpha10",
      svg: {
        stroke: "envy",
      },
    },
  },
  menuContainer: {
    color: "inherit",
    display: "flex",
    fontSize: 1,
    fontWeight: 500,
    alignItems: "center",
    px: 4,
    py: 3,
    borderRadius: "3px",
    ":hover": {
      bg: "darkerIce",
    },
  },
  menuDivider: {
    mx: 4,
    my: 3,
    height: "1px",
    bg: rgba("#8FA9AF", 0.3),
  },

  staticContainer: {
    flexGrow: 1,
    color: "navy",
  },

  comingSoon: {
    bg: "darkerIce",
  },

  modal: {
    position: "fixed",
    zIndex: 101,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bg: rgba("#000000", 0.75),
    justifyContent: "center",
    alignItems: "center",
    py: [5, 5, 8],
    px: [5, 5, 8],
  },

  footer: {
    bg: "darkerNed",
    py: 90,
    width: "100%",
    position: "relative",
    zIndex: navAndFooterZIndex,
  },

  share: {
    borderRadius: "8px",
    py: 2,
    pr: 2,
    pl: 5,
    height: "50px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    bg: "chalkAlpha20",
    svg: {
      transitionDuration: "",
      opacity: 0.6,
      flexShrink: 0,
      transitionProperty: "opacity",
      "&:hover": {
        opacity: 1,
      },
    },
    a: {
      color: "ice",
    },
  },

  switch: {
    verticalAlign: "middle",
    marginLeft: "4px",
  },

  shareLink: {
    borderRadius: "8px",
    cursor: "pointer",
    py: 3,
    px: 4,
    transitionDuration: "",
    color: "navy",
    bg: "iceAlpha60",
    alignItems: "center",
    transitionProperty: "background",
    ":hover": {
      bg: "ice",
    },
  },

  filterDropdown: {
    bg: "#fff",
  },

  button: {
    display: "inline-block",
    border: 0,
    outline: 0,
    py: 0,
    fontFamily: "inherit",
    borderRadius: 8,
    transitionProperty: "background",
    cursor: "pointer",
    fontWeight: 500,
    textAlign: "center",
    textDecoration: "none",
    whiteSpace: "nowrap",
    transitionDuration: "",
  },

  borders: {
    overlayRemix: {
      backgroundColor: "nedLight",
      px: "3px",
      py: "3px",

      borderRadius: "999px",
      ":hover": {
        background:
          "linear-gradient(30deg, rgba(249,83,112,0.623686974789916) 16%, rgba(114,67,154,0.4332107843137255) 91%);",
        backgroundColor: "transparent",
      },
      "@media (max-width: 650px)": {
        px: "2px",
        py: "2px",
      },
    },
    overlayDefault: {
      backgroundColor: "nedLight",
      px: "3px",
      py: "3px",
      color: "ice",
      borderRadius: "999px",
      ":hover": {
        color: "ice",
        backgroundColor: "envyAlpha50",
      },
      "@media (max-width: 650px)": {
        px: "2px",
        py: "2px",
      },
    },
    replayDefault: {
      backgroundColor: "nedLight",
      px: "3px",
      py: "3px",
      color: "ice",
      borderRadius: "999px",
      ":hover": {
        color: "ice",
        backgroundColor: "envyAlpha50",
      },
      "@media (max-width: 650px)": {
        px: "2px",
        py: "2px",
      },
    },
  },
  buttons: {
    main: {
      bg: "envy",
      color: "ice",
      ":hover": {
        transition: "background-color 0.6s ease 0s, border-color 0.6s ease 0s",
        bg: "darkerEnvy",
      },
      "&[disabled]": {
        bg: "chalkAlpha10",
        color: "chalkAlpha40",
        cursor: "default",
        ":hover": {
          bg: "chalkAlpha10",
        },
      },
    },
    secondary: {
      bg: "navy",
      color: "ice",
      borderColor: "navy",
      borderWidth: "1px",
      borderStyle: "solid",
      ":hover": {
        bg: "darkerNavy",
        borderColor: "ned",
        borderWidth: "1px",
        borderStyle: "solid",
      },
      "&[disabled]": {
        opacity: 0.6,
        cursor: "default",
        ":hover": {
          bg: "navy",
        },
      },
    },
    secondaryRounded: {
      bg: "navy",
      color: "ice",
      px: "10px",
      borderRadius: "999px",
      maxWidth: "38px",
      height: "38px",
      width: "100%",
      ":hover": {
        bg: "envy",
        color: "ice",
      },
      "&[disabled]": {
        opacity: 0.6,
        cursor: "default",
        ":hover": {
          bg: "navy",
        },
      },
      "@media (max-width: 650px)": {
        height: "36px",
        maxWidth: "36px",
      },
    },

    options: {
      bg: "transparent",
      color: "chalk",
      ":hover": {
        bg: "midnightAlpha40",
        color: "envy",
      },
      "&[disabled]": {
        opacity: 0.4,
        cursor: "default",
        ":hover": {
          color: "chalk",
          bg: "transparent",
        },
      },
    },
    overlayDefault: {
      backgroundColor: "darkerNed",
      alignItems: "center",
      color: "ice",
      height: "40px",
      width: "40px",
      px: "5px",
      py: "5px",
      borderRadius: "999px",
      ":hover": {
        bg: "envy",
        color: "ice",
      },
      "@media (max-width: 650px)": {
        height: "33px",
        width: "33px",
        px: "0px",
        py: "0px",
      },
    },
    overlayRemix: {
      backgroundColor: "darkerNed",
      alignItems: "center",
      color: "chalk",
      height: "40px",
      width: "40px",
      px: "5px",
      py: "5px",
      borderRadius: "999px",
      ":hover": {
        color: "ice",
        background:
          "linear-gradient(30deg, rgba(249,83,112,1) 16%, rgba(114,67,154,1) 91%);",
        backgroundColor: "transparent",
      },
      "@media (max-width: 650px)": {
        height: "33px",
        width: "33px",
        px: "0px",
        py: "0px",
      },
    },
    actionIcons: {
      backgroundColor: "#0C1318",
      alignItems: "center",
      justifyContent: "center",
      color: "ice",
      height: "100%",
      minWidth: "140px",
      px: "15px",
      py: "15px",

      borderRadius: "8px",
      border: "1px solid #2F3D48",
      ":hover": {
        border: "1px solid #4DBD94",
        backgroundColor: "#060A0C",
      },
      "@media (max-width: 715px)": {
        minWidth: "60px",

        px: "0px",
        py: "0px",
      },
    },
    alert: {
      bg: "transparent",
      color: "alert",
      ":hover": {
        bg: "midnightAlpha40",
        color: "alert",
      },
      "&[disabled]": {
        opacity: 0.4,
        cursor: "default",
        ":hover": {
          bg: "midnightAlpha40",
        },
      },
    },
    mobileShare: {
      bg: "transparent",
      color: "darkerIceAlpha90",
      ":hover": {
        bg: "midnightAlpha50",
        color: "envy",
      },
      "&[disabled]": {
        opacity: 0.4,
        cursor: "default",
        ":hover": {
          bg: "midnightAlpha50",
        },
      },
    },
    modalShare: {
      display: "flex",
      bg: "darkerNed",
      color: "ice",
      borderColor: "darkerNed",
      borderWidth: "1px",
      borderStyle: "solid",
      textAlign: "left",
      transitionProperty: "border",
      marginTop: "5px",
      ":hover": {
        bg: "darkerNed",
        color: "envy",
        borderColor: "darkerEnvy",
      },
      "&[disabled]": {
        opacity: 0.6,
        cursor: "default",
        ":hover": {
          bg: "navy",
        },
      },
    },
    transparent: {
      bg: "transparent",
      color: "chalk",
      borderColor: "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "999px",
      px: "5px",
      ":hover": {
        bg: "darkerNed",
        color: "envy",
        borderColor: "darkerEnvy",
      },
    },
    subtle: {
      bg: "transparent",
      color: "navyAlpha90",
      ":hover": {
        color: "envy",
      },
    },
    light: {
      bg: "transparent",
      color: "ice",
      borderColor: "iceAlpha40",
      borderWidth: "1px",
      borderStyle: "solid",
      ":hover": {
        color: "ice",
        borderColor: "iceAlpha60",
        bg: "chalkAlpha20",
      },
    },
    round: {
      height: "40px",
      width: "40px",
      bg: "navy",
      color: "ice",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        color: "envy",
        bg: "darkerNed",
      },
      "@media (max-width: 1278px)": {
        width: "36px",
        height: "36px",
        px: "3px",
        py: "3px",
      },
      "@media (max-width: 425px)": {
        width: "36px",
        height: "36px",
        px: "3px",
        py: "3px",
      },
    },
    youTube: {
      height: "40px",
      width: "40px",
      bg: "#ffffff",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        bg: "darkerIceAlpha90",
      },
      "@media (max-width: 1278px)": {
        width: "36px",
        height: "36px",
        px: "3px",
        py: "3px",
      },
      "@media (max-width: 425px)": {
        width: "36px",
        height: "36px",
        px: "3px",
        py: "3px",
      },
    },
    tikTok: {
      height: "40px",
      width: "40px",
      bg: "#ffffff",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        bg: "darkerIceAlpha90",
      },
      "@media (max-width: 1278px)": {
        width: "36px",
        height: "36px",
        px: "3px",
        py: "3px",
      },
      "@media (max-width: 425px)": {
        width: "36px",
        height: "36px",
        px: "3px",
        py: "3px",
      },
    },
    youtube: {
      height: "54px",
      width: "54px",
      bg: "red",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        opacity: 0.8,
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    tiktok: {
      height: "54px",
      width: "54px",
      bg: "black",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        opacity: 0.8,
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    whatsApp: {
      height: "54px",
      width: "54px",
      bg: "#1bdf5e",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        bg: "#1CB856",
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    twitter: {
      height: "54px",
      width: "54px",
      bg: "black",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        opacity: 0.8,
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    telegram: {
      height: "54px",
      width: "54px",
      bg: "#69a8e5",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        bg: "#5592CD",
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    facebook: {
      height: "54px",
      width: "54px",
      bg: "#0B81ED",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        bg: "#1573C9",
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    reddit: {
      height: "54px",
      width: "54px",
      bg: "#eb5428",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        bg: "#DA4C23",
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    email: {
      height: "54px",
      width: "54px",
      bg: "envy",
      px: "5px",
      py: "5px",
      borderRadius: "50%",
      ":hover": {
        bg: "darkerEnvy",
      },
      "@media (max-width: 500px)": {
        width: "46px",
        height: "46px",
      },
      "@media (max-width: 425px)": {
        width: "42px",
        height: "42px",
      },
    },
    remixFeed: {
      borderColor: "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: "2rem",
      fontSize: 1,
      height: "40px",
      width: "120px",
      background: "",
      px: 4,
      borderRadius: "999px",
      transitionProperty: "border",
      ":hover": {
        borderColor: "alert",
        borderWidth: "2px",
        borderStyle: "solid",
        background: "",
      },
      "&[disabled]": {
        opacity: 0.4,
        cursor: "default",
        borderColor: "transparent",
        borderWidth: "2px",
        borderStyle: "solid",
        background: "",
      },
      "@media (max-width: 1280px)": {
        height: "36px",
      },
      "@media (max-width: 425px)": {
        fontSize: ".825rem",
        width: "92px",
      },
    },

    bigReplay: {
      borderColor: "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: "2rem",
      fontSize: 1,
      height: "75px",
      width: "75px",
      px: 3,
      py: 6,
      borderRadius: "9999px",
      background: "",
      transitionProperty: "border",
      ":hover": {
        borderColor: "alert",
        borderWidth: "2px",
        borderStyle: "solid",
      },
      "&[disabled]": {
        opacity: 0.4,
        cursor: "default",
      },
      "@media (max-width: 650px)": {
        fontSize: ".825rem",
        px: 3,
        py: 4,
        height: "50px",
        width: "50px",
      },
    },
    rounded: {
      bg: "envyAlpha10",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: "envy",
      borderColor: "envy",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "999px",
      textAlign: "center",
      px: 4,
      lineHeight: "1rem",
      transitionProperty: "background",
      py: 2,
      ":not(.hold):hover": {
        bg: "envy",
        color: "ice",
        borderColor: "envy",
      },
    },
    roundedFilled: {
      bg: "envy",
      display: "inline-flex",
      color: "ice",
      alignItems: "center",
      justifyContent: "center",
      transitionProperty: "background",
      py: 2,
      borderRadius: "999px",
      "&[disabled]": {
        bg: "chalkAlpha10",
        color: "chalkAlpha40",
        cursor: "default",
        ":hover": {
          bg: "chalkAlpha10",
          color: "chalkAlpha40",
        },
      },
      px: 5,
      ":hover": {
        bg: "darkerEnvy",
        color: "ice",
      },
    },
    roundedFilledAlt: {
      bg: "navy",
      display: "inline-flex",
      color: "ice",
      alignItems: "center",
      justifyContent: "center",
      transitionProperty: "background",
      py: 2,
      borderRadius: "999px",
      "&[disabled]": {
        bg: "chalkAlpha10",
        color: "chalkAlpha40",
        cursor: "default",
        ":hover": {
          bg: "chalkAlpha10",
          color: "chalkAlpha40",
        },
      },
      px: 5,
      ":hover": {
        bg: "darkerNavy",
        color: "ice",
      },
    },

    finishMontage: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: "2rem",
      fontSize: 1,
      height: "40px",
      px: 5,
      borderRadius: "999px",
      transitionProperty: "border",
      ":hover": {},
      "&[disabled]": {
        opacity: 0.4,
        cursor: "default",
      },
      "@media (max-width: 425px)": {
        height: "36px",
        fontSize: ".825rem",
      },
    },
    following: {
      bg: "ned",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: "envy",
      borderColor: "rgba(48,95,86,1)",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "999px",
      textAlign: "center",
      px: 4,
      lineHeight: "22px",
      minWidth: "110px",
      transitionProperty: "border",
      py: 2,
      ":not(.hold):hover": {
        color: "alert",
        bg: "darkerNed",
        borderColor: "rgba(141,63,83,1)",
      },
    },
    follow: {
      bg: "envy",
      display: "inline-flex",
      color: "ice",
      alignItems: "center",
      justifyContent: "center",
      transitionProperty: "background",
      lineHeight: "22px",
      minWidth: "110px",
      py: 2,
      borderRadius: "999px",
      px: 5,
      ":hover": {
        bg: "darkerEnvy",
        color: "ice",
      },
    },
    ownerFollow: {
      bg: "envy",
      display: "inline-flex",
      color: "ice",
      alignItems: "center",
      justifyContent: "center",
      transitionProperty: "background",
      lineHeight: "22px",
      minWidth: "93px",
      py: 2,
      borderRadius: "999px",
      px: 5,
      ":hover": {
        bg: "darkerEnvy",
        color: "ice",
      },
    },
    ownerFollowing: {
      bg: "ned",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: "envy",
      borderColor: "rgba(48,95,86,1)",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "999px",
      textAlign: "center",
      px: 4,
      lineHeight: "22px",
      minWidth: "93px",
      transitionProperty: "border",
      py: 2,
      ":not(.hold):hover": {
        color: "alert",
        bg: "darkerNed",
        borderColor: "rgba(141,63,83,1)",
      },
    },

    roundedSecondary: {
      bg: "transparent",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: "envy",
      borderColor: "envy",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "999px",
      textAlign: "center",
      px: 3,
      lineHeight: "1rem",
      transitionProperty: "background",
      py: 2,
      ":not(.hold):hover": {
        bg: "envy",
        color: "ice",
        borderColor: "envy",
      },
    },
    filter: {
      bg: "transparent",
      color: "navy",
      borderColor: "chalkAlpha20",
      borderWidth: "1px",
      borderStyle: "solid",
      fontSize: 1,
      ":hover": {
        bg: "darkerIce",
      },
      "&.active": {
        bg: "chalkAlpha20",
      },
    },
    faceit: {
      bg: "#FF6900",
      color: "ice",
      ":hover": {
        bg: "#E56409",
      },
      "&[disabled]": {
        opacity: 0.6,
        cursor: "default",
        ":hover": {
          bg: "#FF6900",
        },
      },
    },
    tertiary: {
      bg: "transparent",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: "envy",
      borderColor: "envy",
      borderWidth: "2px",
      borderStyle: "solid",
      textAlign: "center",
      px: 3,
      lineHeight: "1rem",
      transitionProperty: "background",
      py: 2,
      ":not(.hold):hover": {
        bg: "envy",
        color: "ice",
        borderColor: "envy",
      },
    },
    white: {
      bg: "white",
      color: "midnight",
      borderColor: "chalkAlpha40",
      borderWidth: "1px",
      borderStyle: "solid",
      ":hover": {
        bg: "darkerIce",
      },
      "&[disabled]": {
        opacity: 0.6,
        cursor: "default",
        ":hover": {
          bg: "envy",
        },
      },
    },
  },

  checkbox: {
    height: 18,
    width: 18,
  },

  tagKey: {
    bg: "midnightAlpha30",
    color: "darkerIceAlpha80",
  },

  tag: {
    display: "inline-flex",
    bg: "midnightAlpha30",
    borderWidth: 2,
    borderColor: "midnightAlpha30",
    borderStyle: "solid",
    borderRadius: "999px",
    color: "envyAlpha80",
    fontSize: ".8em",
    lineHeight: "2em",
    transitionDuration: "",
    fontWeight: 500,
    mr: 3,
    mb: 3,
    transitionProperty: "border-color",
    whiteSpace: "nowrap",
    flexShrink: 0,
    flexGrow: 0,
    overflow: "hidden",
    ":not(.no-hover):hover": {
      borderColor: "darkerEnvy",
      cursor: "pointer",
      color: "envy",
    },
    "> div": {
      lineHeight: "inherit",
      px: 3,
    },
  },

  linkedAccount: {
    borderWidth: 1,
    borderColor: "chalkAlpha20",
    borderStyle: "solid",
    bg: "rgba(77, 189, 148, 0.1)",
    borderRadius: "999px",
    display: "inline-flex",
    alignItems: "center",
    mr: 5,
    mb: 5,
    padding: 3,
  },

  avatar: {
    borderRadius: "50% 50%",
    bg: "chalkAlpha20",
    color: "chalk",
    backgroundSize: "contain",
    flexShrink: 0,
    flexGrow: 0,
  },

  headerClip: {
    gradient:
      "linear-gradient(-15deg, rgba(69,47,87,1) 50%, rgba(38,63,70,0.75) 100%)",
  },

  card: {
    bg: "white",
    color: "navy",
    maxWidth: 4,
    overflow: "hidden",
    width: "100%",
    transitionDuration: "",
    transitionProperty: "box-shadow",
    "&:hover": {
      boxShadow: "0px 6px 26px rgba(28, 42, 52, 0.15)",
    },
    meta: {
      borderTopColor: "darkerNavy",
      borderBottomColor: "navy",
    },
    date: {
      fontSize: 1,
      lineHeight: 1,
      fontWeight: 500,
      color: "chalkAlpha60",
      alignItems: "center",
    },
    flava: {
      display: "flex",
      flex: 1,
      px: 3,
      py: 1,
      fontSize: 2,
      lineHeight: 1,
      fontWeight: 500,
      color: "ice",
      alignItems: "center",
      textAlign: "center",
      fontStyle: "italic",
    },
    views: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: 1,
      lineHeight: 1,
      fontWeight: 500,
      color: "chalk",
      alignItems: "center",
    },
    length: {
      borderRadius: "5px",
      px: 2,
      py: 1,
      fontSize: 1,
      lineHeight: 1,
      fontWeight: 500,
      color: "ice",
      alignItems: "center",
    },
  },

  reactionCount: {
    bg: "transparent",
    color: "ice",
    alignItems: "center",
    fontSize: ".825rem",
    fontWeight: 500,
  },

  badge: {
    fontSize: ".725rem" /* remove when all units are rem */,
    lineHeight: ".725rem" /* remove when all units are rem */,
    fontWeight: 600,
    textTransform: "uppercase",
    borderRadius: "999px",
    color: "ice",
    px: 3,
    pt: "4px",
    pb: "5px",
    flexGrow: 0,
    flexShrink: 0,
    display: "inline-block",
    transform: "translateY(-100%) translateX(10%)",
    background: "",
  },

  callout: {
    fontSize: ".725rem" /* remove when all units are rem */,
    lineHeight: ".725rem" /* remove when all units are rem */,
    fontWeight: 600,
    textTransform: "uppercase",
    borderRadius: "999px",
    color: "ice",
    px: 3,
    pt: "4px",
    pb: "5px",
    flexGrow: 0,
    flexShrink: 0,
    display: "inline-block",
    transform: "translateY(0%) translateX(50%)",
    background: "",
  },

  new: {
    fontSize: ".725rem" /* remove when all units are rem */,
    lineHeight: ".725rem" /* remove when all units are rem */,
    fontWeight: 600,
    textTransform: "uppercase",
    borderRadius: "999px",
    color: "ice",
    px: 3,
    pt: "4px",
    pb: "5px",
    flexGrow: 0,
    flexShrink: 0,
    display: "inline-block",
    transform: "translateY(40%) translateX(0%)",
  },

  introducing: {
    fontSize: ".725rem" /* remove when all units are rem */,
    lineHeight: ".725rem" /* remove when all units are rem */,
    fontWeight: 600,
    textTransform: "uppercase",
    borderRadius: "999px",
    color: "ice",
    px: 3,
    pt: "4px",
    pb: "5px",
    flexGrow: 0,
    flexShrink: 0,
    display: "inline-block",
    background: "",
  },

  studio: {
    fontSize: ".725rem" /* remove when all units are rem */,
    fontWeight: 600,
    textTransform: "uppercase",
    textAlign: "center",
    borderRadius: "999px",
    color: "ice",
    px: 3,
    pt: "7px",
    pb: "8px",
    flexGrow: 0,
    flexShrink: 0,
    display: "inline-block",
    background: "",
  },

  montage: {
    bg: "transparent",
    fontSize: "1rem" /* remove when all units are rem */,
    fontWeight: 600,
    textAlign: "center",
    borderRadius: "999px",
    color: "ice",
    px: 3,
    pt: "7px",
    pb: "8px",
    flexGrow: 0,
    flexShrink: 0,
    display: "inline-block",
    background: "",
    // border: "2px solid #71ABEE",
  },

  frequency: {
    bg: "ned",
    borderRadius: "999px",
    color: "chalk",
    px: "12px",
    pt: "3px",
    pb: "3px",
    flexGrow: 0,
    flexShrink: 0,
    display: "inline-block",
  },

  noticepill: {
    fontSize: 0,
    fontWeight: 600,
    textTransform: "uppercase",
    bg: "midnightAlpha50",
    borderRadius: "999px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "darkerNavy",
    px: 2,
    pt: "3px",
    pb: "4px",
    pl: "8px",
    pr: "8px",
    flexGrow: 0,
    flexShrink: 0,
    color: "turbo",
    display: "inline-block",
  },

  signupbacking: {
    bg: "rgba(12, 19, 24, 0.1)",
    borderRadius: "999px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(12, 19, 24, 0.4)",
    pt: "8px",
    pb: "8px",
    pl: "8px",
    pr: "8px",
  },

  play: {
    color: "ice",
    height: 1,
    width: 1,
    cursor: "pointer",
    position: "relative",
    borderRadius: "50% 50%",
    overflow: "hidden",
    tiny: {
      width: "48px",
      height: "48px",
    },
    small: {
      width: "68px",
      height: "68px",
    },
    spectrum: {
      transitionDuration: "",
      background: "spectrum",
      color: "ice",
      opacity: 0.7,
      transitionProperty: "opacity",
      ":hover": {
        opacity: 1,
      },
    },
  },

  variants: {
    input: {
      default: {
        fontSize: 2,
        px: 5,
        lineHeight: "3.125rem",
        height: "3.125rem",
      },
      title: {
        fontSize: 7,
        fontWeight: 500,
        px: 5,
        lineHeight: "3.125rem",
        height: "3.125rem",
      },
    },

    textarea: {
      default: {
        fontSize: 2,
        px: 5,
        py: 3,
        lineHeight: "1.65rem",
        height: "4.687rem",
        maxWidth: "100%",
      },
    },
    select: {
      default: {
        borderColor: "darkerIce",
        bg: "darkerIce",
        color: "envy",
        fontSize: 2,
        px: 5,
        py: 3,
        height: "3rem",
        maxWidth: "100%",
      },
      small: {
        borderColor: "darkerIce",
        bg: "darkerIce",
        color: "envy",
        fontSize: 1,
        px: 4,
        py: 3,
        height: "3rem",
        maxWidth: "100%",
      },
    },
    button: {
      overlayOption: {
        fontSize: 3,
        px: 6,
        lineHeight: "35px",
        height: "40px",
      },
      overlayRemixOption: {
        fontSize: 3,
        px: 6,
        lineHeight: "37px",
        height: "40px",
      },
      social: {
        fontSize: 1,
        px: 3,
        lineHeight: "2rem",
        height: "2rem",
      },
      publish: {
        fontSize: 1,
        px: 4,
        lineHeight: "3rem",
        height: "3rem",
        borderRadius: "8px",
      },
      tiny: {
        fontSize: 1,
        px: 1,
        lineHeight: "2rem",
        height: "2rem",
      },
      small: {
        fontSize: 2,
        px: 4,
        lineHeight: "2.5rem",
        height: "2.5rem",
      },
      default: {
        fontSize: 3,
        px: 6,
        lineHeight: "3.125rem",
        height: "3.125rem",
      },
    },
    logo: {
      nav: {
        width: "100px",
        height: "55px",
        "@media (max-width: 905px)": {
          width: "30px",
          height: "30px",
        },
      },
    },
    link: {
      clipPageOwner: {
        color: "inherit",
        fontWeight: 500,
        ":hover": {
          color: "envy",
        },
      },
      clipTitle: {
        color: "inherit",
        fontWeight: 500,
        ":hover": {
          color: "envy",
        },
      },
      mobileClipTitle: {
        color: "ice",
        fontWeight: 500,
        ":hover": {
          color: "envy",
        },
      },
      title: {
        transitionDuration: "",
        color: "inherit",
        transitionProperty: "color",
        fontWeight: 500,
        borderBottom: "1px solid",
        borderBottomColor: "envy",
        ":hover": {
          color: "envy",
          cursor: "pointer",
        },
      },
      profileSocial: {
        color: "ice",
        ":hover": {
          color: "envy",
        },
      },
      nav: {
        color: "inherit",
        fontSize: [2],
        ":hover": {
          color: "envy",
        },
        "&.active:not(.logo)": {
          color: "envy",
        },
      },
      mobileNav: {
        display: "flex",
        color: "inherit",
        fontSize: [3],
        ":hover": {
          color: "envy",
        },
        "&.active:not(.logo)": {
          color: "envy",
        },
      },
      job: {
        color: "inherit",
        fontSize: [4],
        fontWeight: 500,
        ":hover": {
          color: "envy",
        },
      },
      tabs: {
        color: "inherit",
        marginTop: 4,
        paddingBottom: 4,
        paddingRight: "4px",
        borderBottom: "2px solid",
        borderBottomColor: "darkerNavy",
        fontSize: [1],
        fontWeight: 500,
        ":hover": {
          color: "envy",
          borderBottomColor: "envy",
        },
        "&.active": {
          color: "envy",
          borderBottomColor: "envy",
        },
      },
      floatingNav: {
        fontSize: 3,
        fontWeight: 500,
        lineHeight: 3,
        color: "navy",
        ":hover": {
          color: "envy",
        },
        "&.active": {
          color: "envy",
        },
      },
      footer: {
        display: "inline-block",
        fontSize: 2,
        color: "chalk",
        ":hover": {
          color: "envy",
        },
      },
      railFooter: {
        display: "inline-block",
        fontSize: 1,
        color: "chalk",
      },

      form: {
        color: "inherit",
        borderBottom: "1px solid",
        borderBottomColor: "envy",
        ":hover": {
          color: "envy",
        },
      },
      help: {
        color: "envy",
        // borderBottom: '1px solid',
        // borderBottomColor: 'envy',
        // ':hover': {
        //   color: 'envy',
        // }
      },
    },
    divider: {
      alignItems: "center",
      justifyContent: "space-evenly",
      opacity: 1,
      fontSize: "0.8125em",
      marginTop: 4,
      marginBottom: 4,
    },

    pinnedClips: {
      alignItems: "center",
      justifyContent: "center",
      opacity: 1,
      fontSize: "0.8125em",
      marginTop: 0,
      marginBottom: 0,
    },
    pinnedClipsBottom: {
      alignItems: "center",
      justifyContent: "center",
      opacity: 1,
      fontSize: "0.8125em",
      marginTop: 0,
      marginBottom: 3,
    },
    dividerAlt: {
      alignItems: "center",
      height: "1px",
      justifyContent: "space-evenly",
      marginTop: 4,
      marginBottom: 4,
      bg: "chalkAlpha20",
    },
    form: {
      marginBottom: 20,
    },
    formError: {
      bg: "#FFEEE5",
      color: "error",
      px: 4,
      py: 3,
      borderRadius: "8px",
      borderColor: "error",
      borderStyle: "solid",
      borderWidth: "2px",
      alignItems: "center",
    },
    formSuccess: {
      bg: "#E4F9F1",
      color: "darkerEnvy",
      px: 4,
      py: 3,
      borderRadius: "8px",
      borderColor: "envy",
      borderStyle: "solid",
      borderWidth: "2px",
      alignItems: "center",
    },
    avatar: {
      extraTiny: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 20,
        height: 20,
      },

      tiny: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 30,
        height: 30,
      },
      nav: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 38,
        height: 38,
        marginLeft: "2px",
      },
      small: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 50,
        height: 50,
      },
      medium: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 70,
        height: 70,
      },
      large: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 88,
        height: 88,
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: "10px",
      },
      extraLarge: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 100,
        height: 100,
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: "10px",
      },
      huge: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "50% 50%",
        width: 192,
        height: 192,
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: "10px",
      },
    },
    avatarBadge: {
      nav: {
        superstar: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        pro: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        creatorPlat: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        proPlus: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        officialPartner: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
      },
      sideRail: {
        superstar: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        pro: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        creatorPlat: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        proPlus: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
        officialPartner: {
          position: "absolute",
          left: "-4px",
          bottom: "-9px",
          width: "56px",
          height: "58px",
          zIndex: "2",
        },
      },
      tiny: {
        superstar: {
          position: "absolute",
          left: "-6px",
          bottom: "-8px",
          width: "47px",
          height: "49px",
          zIndex: "2",
        },
        pro: {
          position: "absolute",
          left: "-6px",
          bottom: "-8px",
          width: "47px",
          height: "49px",
          zIndex: "2",
        },
        creatorPlat: {
          position: "absolute",
          left: "-6px",
          bottom: "-8px",
          width: "47px",
          height: "49px",
          zIndex: "2",
        },
        proPlus: {
          position: "absolute",
          left: "-6px",
          bottom: "-8px",
          width: "47px",
          height: "49px",
          zIndex: "2",
        },
        officialPartner: {
          position: "absolute",
          left: "-6px",
          bottom: "-8px",
          width: "47px",
          height: "49px",
          zIndex: "2",
        },
      },
      small: {
        superstar: {
          position: "absolute",
          left: "30px",
          bottom: "24px",
          transform: "scale(.7)",
          zIndex: "2",
        },
        pro: {
          position: "absolute",
          left: "30px",
          bottom: "24px",
          transform: "scale(.7)",
          zIndex: "2",
        },
        officialPartner: {
          position: "absolute",
          left: "30px",
          bottom: "24px",
          transform: "scale(.7)",
          zIndex: "2",
        },
      },
      medium: {
        superstar: {
          position: "absolute",
          left: "-14px",
          top: "-7.3px",
          width: "99px",
          height: "92.5px",
          zIndex: "2",
        },
        pro: {
          position: "absolute",
          left: "-14px",
          top: "-7.2px",
          width: "98px",
          height: "92.5px",
          zIndex: "2",
        },
        officialPartner: {
          position: "absolute",
          left: "-14px",
          top: "-7.2px",
          width: "98px",
          height: "92.5px",
          zIndex: "2",
        },
        creatorPlat: {
          position: "absolute",
          left: "-14px",
          top: "-7.2px",
          width: "98px",
          height: "92.5px",
          zIndex: "2",
        },
        proPlus: {
          position: "absolute",
          left: "-14px",
          top: "-7.2px",
          width: "98px",
          height: "92.5px",
          zIndex: "2",
        },
      },
      large: {
        superstar: {
          position: "absolute",
          left: "1px",
          top: "1px",
          width: "106.5px",
          height: "116px",
          zIndex: "2",
        },
        pro: {
          position: "absolute",
          left: "1px",
          top: "1px",
          width: "106.5px",
          height: "116px",
          zIndex: "2",
        },
        officialPartner: {
          position: "absolute",
          left: "1px",
          top: "1px",
          width: "106.5px",
          height: "116px",
          zIndex: "2",
        },
        creatorPlat: {
          position: "absolute",
          left: "1px",
          top: "1px",
          width: "106.5px",
          height: "116px",
          zIndex: "2",
        },
        proPlus: {
          position: "absolute",
          left: "1px",
          top: "1px",
          width: "106.5px",
          height: "116px",
          zIndex: "2",
        },
      },
    },

    icon: {
      card: {
        color: "envy",
        mr: 2,
      },
      description: {
        color: "envy",
        mr: 2,
      },
    },
    sidebarIcon: {
      rising: {
        color: "champion",
        ml: 5,
      },
      trending: {
        color: "champion",
        ml: 5,
      },
    },
    sidebar: {
      green: {
        boxShadow: "0px 4px 44px rgba(37, 58, 73, 0.1)",
        borderRadius: "10px",
        color: "ice",
        padding: 5,
      },
      dark: {
        bg: "darkerNavy",
        borderRadius: "10px",
        color: "ice",
        padding: 5,
      },
      new: {
        bg: "transparent",
        borderRadius: "10px",
        border: "1px solid #34444d",
        color: "ice",
        padding: 5,
      },
      main: {
        bg: "darkerNavy",
        borderRadius: "10px",
        color: "ice",
      },
      blue: {
        boxShadow: "0px 4px 44px rgba(37, 58, 73, 0.1)",
        borderRadius: "10px",
        color: "ice",
        padding: 5,
      },
    },
  },

  margins: {
    input: "13px",
    navLinks: "30px",
    large3: "25px",
    body: "25px",
  },
};

theme.colors.spectrum = `linear-gradient(
    225deg, 
    ${theme.colors.royalty} 0%, 
    ${theme.colors.champion} 50%, 
    ${theme.colors.envy} 100%)`;

theme.colors.reverseSpectrum = `linear-gradient(
      225deg, 
      ${theme.colors.envy} 0%, 
      ${theme.colors.champion} 50%, 
      ${theme.colors.royalty} 100%)`;
theme.colors.InfluencerAvatarGradient = `linear-gradient(
    45deg,
    ${theme.colors.champion} 10%,30%,
    ${theme.colors.royalty} 90%
  )`;

theme.colors.MontageGradient = `linear-gradient(
    45deg,
    rgba(113,209,221,0.4)  10%,30%,
    rgba(113,171,238,0.4) 90%
  )`;

theme.colors.SuperstarsGradient = `linear-gradient(
    45deg,
    #25A19A 10%,
    ${theme.colors.champion} 90%
  )`;

theme.colors.ClipOfTheDay = `linear-gradient(
  90deg, rgba(24,37,45,1) 10%, rgba(38,63,70,0.75) 25%, rgba(38,63,70,0) 50%, rgba(38,63,70,0.75) 75%, rgba(24,37,45,1) 90%
  )`;

theme.colors.redGradient = `linear-gradient(
    90deg,
    ${theme.colors.error} 10%,50%,
    ${theme.colors.alert} 100%
  )`;

theme.colors.saleGradient = `linear-gradient(
    45deg,
    #EFAF09 0%,
    #DA7F0C 50%,
    #EFAF09 100%
  )`;

theme.colors.saleCarouselGradient =
  "linear-gradient(90deg, rgba(69,47,87,1) 50%, rgba(38,63,70,0.75) 100%)";

theme.colors.remixGradientDark =
  "linear-gradient(-15deg, rgba(46,48,73,1) 0%, rgba(72,51,65,1) 100%);";
theme.colors.remixGradientDarkReverse =
  "linear-gradient(-15deg, rgba(69,47,87,1) 0%, rgba(107,53,89,1) 100%);";
theme.colors.remixOverlayGradient =
  "linear-gradient(30deg, rgba(249,83,112,1) 0%, rgba(114,67,154,1) 76%);";
theme.colors.carouselGradient =
  "linear-gradient(90deg, rgba(93,147,145,.5) 0%, rgb(6 24 37 / 75%) 25%, rgba(92, 99, 210, 0.1) 50%, rgb(6 18 37 / 75%) 75%, rgba(93,147,145,.5) 100%);";
// theme.colors.carouselGradient = `linear-gradient(
//   90deg, rgba(24,37,45,1) 10%, rgba(38,63,70,0.75) 25%, rgba(38,63,70,0) 50%, rgba(38,63,70,0.75) 75%, rgba(24,37,45,1) 90%
//   )`;
// theme.variants.avatarBorder.officialPartner.background =
//   theme.colors.InfluencerAvatarGradient;
// theme.variants.avatarBorder.pro.background = theme.colors.envy;
// theme.variants.avatarBorder.superstar.background =
//   theme.colors.SuperstarsGradient;
theme.play.spectrum.background = theme.colors.spectrum;
theme.callout.background = theme.colors.redGradient;
theme.introducing.background = theme.colors.InfluencerAvatarGradient;
theme.studio.background = theme.colors.InfluencerAvatarGradient;
theme.montage.background = theme.colors.MontageGradient;
theme.badge.background = theme.colors.redGradient;
theme.badge.background = theme.colors.saleGradient;
theme.buttons.remixFeed.background = theme.colors.remixGradientDark;

theme.buttons.bigReplay.background = theme.colors.midnightAlpha90;
theme.buttons.remixFeed[":hover"].background =
  theme.colors.remixGradientDarkReverse;

theme.buttons.remixFeed["&[disabled]"].background = theme.colors.navy;

theme.navMobile.background = theme.colors.darkerNed;

theme.input.transitionDuration = theme.transitionDuration;
theme.link.transitionDuration = theme.transitionDuration;
theme.variants.link.title.transitionDuration = theme.transitionDuration;
theme.button.transitionDuration = theme.transitionDuration;
theme.tag.transitionDuration = theme.transitionDuration;
theme.card.transitionDuration = theme.transitionDuration;
theme.share["svg"].transitionDuration = theme.transitionDuration;
theme.play.spectrum.transitionDuration = theme.transitionDuration;
theme.shareLink.transitionDuration = theme.transitionDuration;

const themeTwo = merge(theme, themeOne);

export { themeTwo };
