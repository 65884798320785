import React from "react";
import { Button } from "../button";
import { HiX } from "react-icons/hi";
import styled from "styled-components";

const StyledClose = styled(HiX)`
  position: relative;
  top: 1px;
  @media (max-width: 425px) {
    top: 4px;
  }
`;

let StyledCloseButton = styled(Button)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => (props.hideOnMobile ? "display:none" : null)};
  }
`;

export default function CloseButton({
  classification,
  onClick,
  size,
  hideOnMobile,
  ...props
}) {
  classification = classification ? classification : "secondary";
  return (
    <StyledCloseButton
      hideOnMobile={hideOnMobile}
      title="Cancel"
      size={size}
      classification={classification}
      flex={true}
      justifyContent="center"
      alignIContent="center"
      onClick={(e) => {
        onClick && onClick(e);
      }}
      {...props}
    >
      <StyledClose size={24}></StyledClose>
      {props.children}
    </StyledCloseButton>
  );
}
