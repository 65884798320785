export const preloadImages = (imageUrls: string[]) => {
  imageUrls.forEach((image) => {
    new Image().src = image;
  });
};

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export const randomAvatar = () => {
  const colors = ["blue", "envy", "orange", "purple"];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  return `${REACT_APP_S3_CLIP_BUCKET}/userdata/avatar-${randomColor}.svg`;
};
