import { useMemo } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { BiMinusCircle, BiSolidFlagAlt, BiSolidTrashAlt } from "react-icons/bi";
import { useMutation } from "@apollo/client";

import { IClipComment } from "../@types";
import { Avatar } from "MVVM/Components/Avatar";
import { AvatarWrapper, getAvatarBorderLevel } from "MVVM/Utilities/User";
import { randomAvatar } from "MVVM/Utilities/Images";
import { dateFormatWithDistanceSuffix } from "MVVM/Utilities/Clip";
import { Tooltip } from "MVVM/Components/Tooltip";
import { useUser } from "MVVM/Hooks/useUser";
import {
  CLIP_COMMENT_FIELDS,
  DELETE_COMMENT,
  REPORT_COMMENT,
} from "MVVM/GraphQL/clipComments";
import { analytics } from "MVVM/Analytics";
import { usePortal } from "MVVM/Hooks/usePortal";
import { FloatingContent } from "MVVM/Components/Wrappers";

interface IClipCommentProps {
  comment: IClipComment;
}

const ClipComment = ({ comment }: IClipCommentProps) => {
  const avatarBorderLevel = getAvatarBorderLevel(comment.user);
  const history = useHistory();
  const avatar = comment.user?.avatarUrl || useMemo(randomAvatar, []);
  const username = comment.user?.username || "Allstar Gamer";
  const { allstarUser } = useUser();

  if (comment.deletedAt)
    return (
      <StyledComment>
        <Flex
          color="chalk"
          style={{ fontStyle: "italic", gap: "4px" }}
          variant="text.paragraphSmall"
          fontWeight={500}
          alignItems="center"
        >
          <StyledBiMinusCircle />
          This comment has been removed.
        </Flex>
      </StyledComment>
    );

  return (
    <StyledComment>
      <Box
        style={{ cursor: "pointer", transform: "scale(1.1)" }}
        pt={2}
        flexShrink={0}
      >
        <AvatarWrapper size="nav" borderLevel={avatarBorderLevel}>
          <Box
            onClick={() =>
              comment.user?.username && history.push(`/u/${username}`)
            }
          >
            <Avatar image={avatar} size="nav" />
          </Box>
        </AvatarWrapper>
      </Box>
      <Flex flexDirection="column" width="100%">
        <Flex>
          <Flex style={{ gap: "4px" }} flexGrow="1">
            <Text color="chalk" variant="text.paragraphSmall" fontWeight={500}>
              {username}
            </Text>
            <Text color="chalk" variant="text.paragraphSmall" fontWeight={500}>
              &middot;
            </Text>
            <Text color="chalk" variant="text.paragraphSmall" fontWeight={500}>
              {dateFormatWithDistanceSuffix(comment.createdAt)}
            </Text>
          </Flex>
          {allstarUser.loggedIn && (
            <Tooltip
              tooltipContent={<CommentTooltip comment={comment} />}
              type="click"
              position={{
                right: "18px",
                top: "2px",
              }}
            >
              <Text
                color="chalk"
                variant="text.paragraphMedium"
                fontWeight={500}
              >
                &#8942;
              </Text>
            </Tooltip>
          )}
        </Flex>
        <Text
          color="ice"
          variant="text.paragraphMedium"
          style={{ overflowWrap: "break-word" }}
        >
          {comment.text}
        </Text>
      </Flex>
    </StyledComment>
  );
};

const CommentTooltip = ({ comment }: { comment: IClipComment }) => {
  const { allstarUser } = useUser();
  const [deleteComment] = useMutation(DELETE_COMMENT);
  const [reportComment] = useMutation(REPORT_COMMENT);

  const handleDelete = (comment: IClipComment) => {
    deleteComment({
      variables: {
        clipId: comment.clip.id,
        commentId: comment._id,
      },
      onCompleted: () =>
        analytics.track("Comment Deleted", { platform: "web" }),
      update(cache, { data: { commentDelete } }) {
        cache.modify({
          fields: {
            comments(existingComments = {}) {
              const comments = existingComments.comments || [];
              const newCommentRef = cache.writeFragment({
                data: commentDelete.comments[commentDelete.comments.length - 1],
                fragment: CLIP_COMMENT_FIELDS,
              });
              return [...comments, newCommentRef];
            },
          },
        });
      },
    });
  };

  const handleReport = (comment: IClipComment) => {
    reportComment({
      variables: { commentId: comment._id },
      onCompleted: () => {
        analytics.track("Comment Reported", { platform: "web" });
        setPortalOpen(true);
      },
    });
  };

  const { portalOpen, setPortalOpen, Portal } = usePortal(
    <StyledBox py={4} px={6}>
      <Box>
        <FloatingContent
          title={
            <Flex alignItems="center">
              <Text mr={4} pt={2}>
                Thanks!
              </Text>
              🙌
            </Flex>
          }
          body={
            <Text>Your feedback helps make Allstar awesome for everyone!</Text>
          }
        ></FloatingContent>
      </Box>
    </StyledBox>,
  );

  return (
    <Flex flexDirection="column" style={{ gap: "8px" }} px="2">
      {portalOpen && Portal}
      <StyledAction onClick={() => handleReport(comment)}>
        <BiSolidFlagAlt /> Report Comment
      </StyledAction>
      {allstarUser.user._id === comment.user._id && (
        <StyledAction onClick={() => handleDelete(comment)}>
          <BiSolidTrashAlt /> Delete comment
        </StyledAction>
      )}
    </Flex>
  );
};

const StyledBox = styled(Box)`
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  background-color: #10161a;
  max-width: 400px;
`;

const StyledBiMinusCircle = styled(BiMinusCircle)`
  color: ${({ theme }) => theme.colors.alert};
`;

const StyledAction = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.colors.chalk};
  font-size: ${({ theme }) => theme.text.paragraphSmall};
  gap: 4px;

  :hover {
    color: ${({ theme }) => theme.colors.ice};
  }
`;

const StyledComment = styled(Flex)`
  border-radius: 8px;
  cursor: pointer;
  gap: 20px;
  padding: 12px;

  :hover {
    background: ${({ theme }) => theme.colors.navy};
  }
`;

export { ClipComment };
