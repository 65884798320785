import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { PinClipButton } from "MVVM/Components/Pinnable/PinClipButton";

export const Pinnable = ({
  clipId,
  children,
}: {
  clipId: string;
  children: React.ReactNode;
}) => {
  return (
    <PinnedWrapper>
      {clipId && (
        <PinnedButtonWrapper className="pinned-wrapper">
          <PinClipButton clipId={clipId} isVisible={true} />
        </PinnedButtonWrapper>
      )}
      {children}
    </PinnedWrapper>
  );
};
const PinnedButtonWrapper = styled(Box)`
  position: absolute;
  z-index: 20;
  top: 5px;
  left: 5px;
  opacity: 0.5;
  cursor: pointer;
`;

const PinnedWrapper = styled(Flex)`
  position: relative;
  width: 100%;

  &:hover .pinned-wrapper {
    opacity: 1;
  }
`;
