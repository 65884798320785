import { useMutation } from "@apollo/client";
import {
  PIN_CLIP_MUTATION,
  UNPIN_CLIP_MUTATION,
} from "MVVM/GraphQL/mutatePinnedClips";
import { useSetRecoilState } from "recoil";
import { PinnedClipsAtom } from "MVVM/State/clip";
export const PinnedClipsViewModel = () => {
  const setPinnedClips = useSetRecoilState(PinnedClipsAtom);
  const [pinClip, { loading: pinClipLoading, error: pinClipError }] =
    useMutation(PIN_CLIP_MUTATION, {
      onCompleted: (data) => {
        setPinnedClips(data?.clipPin?.profile?.pinnedClips);
      },
    });

  const [unpinClip, { loading: unpinClipLoading, error: unpinClipError }] =
    useMutation(UNPIN_CLIP_MUTATION, {
      onCompleted: (data) => {
        setPinnedClips(data?.clipUnpin?.profile?.pinnedClips);
      },
    });

  return {
    unpinClip,
    pinClip,
    pinClipLoading,
    pinClipError,
    unpinClipLoading,
    unpinClipError,
  };
};
