import { gql } from "@apollo/client";

const CLIP_FIELDS = gql`
  fragment ClipFields on Clip {
    createdAt
    previewUrl
    score
    shareId
    thumbnailOverlayUrl: thumbnailUrl(style: OVERLAY)
    thumbnailStandardUrl: thumbnailUrl(style: STANDARD)
    title
    user {
      _id
      avatarUrl
      username
    }
    views
  }
`;

const CLIPS_RECOMMENDED = gql`
  ${CLIP_FIELDS}

  query clipsRecommended($limit: Int) {
    clipsRecommended(limit: $limit) {
      ...ClipFields
    }
  }
`;

export { CLIPS_RECOMMENDED };
