import { useQuery } from "@apollo/client";

import { FeedType } from "../@types";
import { CLIPS_RECOMMENDED } from "MVVM/GraphQL/feed";

const limit = 8;

const FeedViewModel = (type: FeedType) => {
  const { data, error, loading } = useQuery(CLIPS_RECOMMENDED, {
    variables: {
      limit,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    data,
    error,
    loading,
  };
};

export { FeedViewModel };
