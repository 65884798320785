import React, { useState, useEffect, useRef } from "react";
import { FaYoutube } from "react-icons/fa";
import styled from "styled-components";
import { useFetch } from "../../../../../hooks";
import { FormLink } from "../../../Form/Link";
import { Modal } from "../../../Wrappers";
import { Row } from "../../../Form";
import { InputWithLabel } from "../../../Inputs";
import { Flex } from "rebass";
import { useForm } from "react-hook-form";
import { SelectWithLabel } from "../../../Inputs";
import { Note, Button } from "../../../Button";
import { Error, Label } from "../../../Inputs";
import { Title3 } from "../../../Text";
import theme from "../../../../../theme/themeThree";
import { FloatingContainer } from "../../../Wrappers";
import { getClipTitle } from "../../../../Utilities/Clip";
import { trackClipSocialShare } from "../../../../Analytics";
import { useRecoilState } from "recoil";
import { getQueryParam } from "../../../../Utilities/QueryParam";
import { TextArea } from "../../../Form/Existing/textarea";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";

const StyledFormLink = styled(FormLink)`
  font-size: inherit;
  white-space: nowrap;
`;

const privacyOptions = [
  { label: "Public", value: "public" },
  { label: "Private", value: "private" },
  { label: "Unlisted", value: "unlisted" },
];

type TYoutubeUploadProps = {
  title: string;
  description: string;
  privacy: string;

  queue: boolean;
};

export function UploadDetails() {
  const { fetch, query }: any = useFetch(undefined, true);
  const [isModalOpen, setModalOpen] = useState(
    Boolean(getQueryParam("youtube")),
  );

  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const formData = useRef({
    title: "",
    description: "",
    privacy: "",
  });
  const aspectRatioRef = useRef(allstarModalState.data.aspectRatio);

  useEffect(() => {
    if (query.statusCode === 409) {
      setAllstarModalState({
        data: formData.current,
        functions: {},
        isOpen: ModalType.DuplicateUploadTikTok,
      });
    }
    if (query.status === "success") {
      setAllstarModalState({
        data: { platform: "Youtube" },
        functions: {},
        isOpen: ModalType.Uploading,
      });
    }
  }, [query.statusCode, query.status]);

  if (!allstarModalState.data.clip) return null;

  const onSubmit: any = ({
    queue,
    title,
    description,
    privacy,
  }: TYoutubeUploadProps) => {
    // formData.current = data;

    trackClipSocialShare({
      platform: "YouTube",
      handler: fetch,
      clip: allstarModalState.data.clip,
      queue: true,
      data: { title, description, privacy, queue },
      aspectRatio: aspectRatioRef.current,
    });
  };
  const isLoading = query.status === "loading";
  return (
    <>
      <Modal
        isModalOpen={
          Boolean(getQueryParam("youtube")) ||
          allstarModalState.isOpen === ModalType.YoutubeUploadDetails
        }
        setModalOpen={() => {
          setAllstarModalState({
            data: {},
            functions: {},
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FloatingContainer>
          <Flex flexDirection="column">
            <Title3 mb={5}>Upload to YouTube</Title3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputWithLabel
                input={{
                  id: "title",
                  placeholder: "Enter clip title",
                  defaultValue:
                    allstarModalState?.data?.clip?.title ||
                    getClipTitle(allstarModalState.data.clip),
                  ...register("title", {
                    required: true,
                  }),
                }}
                label="Title*"
                applySpace={true}
                error={errors.title}
              />
              <Row mt={3}>
                <Label applySpace={true} htmlFor="description">
                  Description
                </Label>
                <TextArea
                  mt={3}
                  defaultValue="Clip made with allstar.gg #playsharestar #allstargg"
                  input={{
                    id: "description",
                    defaultValue:
                      "Clip made with allstar.gg #playsharestar #allstargg",
                    ...register("description", {
                      required: true,
                    }),
                  }}
                />
              </Row>
              <Row mt={3}>
                <SelectWithLabel
                  applySpace={true}
                  input={{
                    id: "privacy",
                    options: privacyOptions,
                    ...register("privacy", {}),
                  }}
                  label="Privacy"
                  error={errors.privacy}
                  variant={theme.variants.select.default}
                />
              </Row>
              <Flex mt={3}>
                <Note>
                  When you upload to YouTube, you certify that the (Title) and
                  (Description) you use complies with YouTube’s{" "}
                  <StyledFormLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/static?&template=terms"
                  >
                    Terms of Use
                  </StyledFormLink>
                </Note>
              </Flex>

              <Flex flexDirection="column" mt={4}>
                <Button
                  mb={4}
                  size="small"
                  disabled={query.status === "success" || isLoading}
                  type="submit"
                >
                  {isLoading
                    ? "Loading..."
                    : query.status === "success"
                    ? "Publish queued"
                    : "Publish"}
                </Button>
                <Button
                  classification="subtle"
                  size="small"
                  onClick={() => {
                    setAllstarModalState({
                      data: {},
                      functions: {},
                      isOpen: ModalType.None,
                    });
                  }}
                >
                  Cancel
                </Button>
              </Flex>
              {query.error && (
                <Error>{query.error.msg ?? "An error occured"}</Error>
              )}
            </form>
          </Flex>
        </FloatingContainer>
      </Modal>
    </>
  );
}
