import { useRef, useState } from "react";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { useHistory } from "react-router-dom";

import { VideoContainerFeed } from "../styledComponents";
import {
  countClipImpression,
  dateFormatWithDistanceSuffix,
} from "../../../Utilities/Clip";
import { VideoPlayer } from "../../../VideoPlayer";
import { Avatar } from "../../../Components/Avatar";
import { getAvatarImage } from "../../../Utilities/User";
import { IContentFeedStats, IContentItem } from "../@types";
import { Link } from "../../../Components/Link";
import { CONTENT_BADGES, ContentItemBadge } from "../ContentItemBadge";
import styled from "styled-components";
import { Wrapped } from "MVVM/Views/Wrapped";
import { usePortal } from "MVVM/Hooks/usePortal";
import { analytics } from "MVVM/Analytics";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export const HOT_NEW_TIME_THRESHOLD = 1000 * 60 * 60 * 24;
export const HOT_NEW_VIEWS_THRESHOLD = 20;

export const calculateStats = (data: IContentItem[]): IContentFeedStats => {
  const numItems = data.length;

  let totalScore = 0;
  let totalViews = 0;
  let maxScore = 0;
  let maxViews = 0;

  for (const item of data) {
    const score =
      typeof item.score === "number" ? item.score : item.score?.value ?? 0;

    totalScore += score;
    totalViews += item.views;
    maxScore = Math.max(maxScore, score);
    maxViews = Math.max(maxViews, item.views);
  }

  return {
    maxScore,
    maxViews,
    avgScore: numItems > 0 ? totalScore / numItems : 0,
    avgViews: numItems > 0 ? totalViews / numItems : 0,
  };
};

export const applyHotNewBadge = (item: IContentItem): IContentItem => {
  let itemCreatedDate = item.createdAt
    ? new Date(item.createdAt)
    : new Date(item.createdDate);
  // Montages return createdDate as an epoch timestamp
  if (itemCreatedDate.toString() === "Invalid Date")
    itemCreatedDate = new Date(parseInt(item.createdDate));
  const hotNewTimeThreshold = new Date(Date.now() - HOT_NEW_TIME_THRESHOLD);
  const isNew = itemCreatedDate > hotNewTimeThreshold;
  const isHotNew = isNew && item.views > HOT_NEW_VIEWS_THRESHOLD;

  return isHotNew ? { ...item, badge: CONTENT_BADGES.HOT_NEW } : item;
};

export const applyAllStarBadge = (
  stats: IContentFeedStats | undefined,
): ((item: IContentItem) => IContentItem) => {
  return (item: IContentItem) => {
    if (!stats) return item;

    const score =
      typeof item.score === "number" ? item.score : item.score?.value ?? 0;
    const maxScore = stats.maxScore;
    const avgScore = stats.avgScore;
    const carouselScoreThreshold = (maxScore + avgScore) / 2;
    const isAllStar = score > 0 && score >= carouselScoreThreshold;

    return isAllStar ? { ...item, badge: CONTENT_BADGES.ALLSTAR_SCORE } : item;
  };
};

interface IContentItemProps {
  isMontage?: boolean;
  isWrapped?: boolean;
  item: IContentItem;
  subtextColor?: string;
  title: string;
  borderColor?: string;
}

const ContentItem = ({
  isMontage,
  isWrapped,
  item,
  subtextColor = "chalk",
  title,
  borderColor,
}: IContentItemProps) => {
  const [active, setActive] = useState(false);
  const [missingThumbnailChecked, setMissingThumbnailChecked] = useState(false);
  const history = useHistory();
  const thumbnail =
    item.thumbnailStandardUrl ||
    `${REACT_APP_S3_CLIP_BUCKET}/${item.clipImageThumb}`;
  const thumbnailOg =
    item.thumbnailOverlayUrl ||
    `${REACT_APP_S3_CLIP_BUCKET}/${item.clipSnapshot}`;
  const userProfileUrl = `/profile?user=${item.user?._id}`;
  const clipLink = `/clip/${item.shareId}`;

  const {
    portalOpen: wrappedPortalOpen,
    setPortalOpen: setWrappedPortalOpen,
    Portal: WrappedPortal,
  } = usePortal(
    <Wrapped period="2024" username={item.user?.username} />,
    "root",
  );

  const { badge } = item;

  const handleClick = () => {
    if (isWrapped) {
      analytics.track("Wrapped 2024 - Modal Click", {
        source: "Homepage Carousel",
        username: item.user?.username,
      });

      return setWrappedPortalOpen(true);
    }

    window.rudderanalytics.track("Homepage Feed - Click", {
      type: title,
    });

    return history.push({
      pathname: clipLink,
      state: { autoplay: true, referrer: "/" },
    });
  };

  return (
    <Flex
      flexDirection="column"
      style={{ height: "auto", width: "100%", position: "relative" }}
    >
      {wrappedPortalOpen && WrappedPortal}
      <ResponsiveContainer
        onMouseEnter={() => {
          if (!(item.previewUrl || item.clipPreviewPath)) return;

          setActive(true);
          countClipImpression(item.shareId, isMontage);
        }}
        onMouseLeave={() => setActive(false)}
        style={{
          border: borderColor ? `1px solid ${borderColor}` : "none",
          borderRadius: 9,
          overflow: "hidden",
        }}
      >
        {badge && <ContentItemBadge badge={badge} />}
        <Image
          src={thumbnailOg}
          width="100%"
          style={{ position: "absolute", zIndex: 1, borderRadius: 9 }}
          onClick={handleClick}
          onError={(e) => {
            if (missingThumbnailChecked) return true;
            e.currentTarget.src = thumbnail;
            setMissingThumbnailChecked(true);
          }}
        />
        <VideoPreview
          active={active}
          item={item}
          title={title}
          handleClick={handleClick}
        />
      </ResponsiveContainer>
      <Flex mt={4} style={{ gap: 12 }}>
        <Avatar
          image={getAvatarImage(item.user)}
          size="tiny"
          to={userProfileUrl}
        />
        <Box>
          <Link
            to={{
              pathname: clipLink,
              state: { autoplay: true, referrer: "/" },
            }}
          >
            <Text fontWeight={500}>
              {item.title || item?.userClipTitle || item.clipTitle}
            </Text>
          </Link>
          <Link
            to={userProfileUrl}
            onClick={() =>
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            <Text fontWeight={500} fontSize={1} color={subtextColor} mt={3}>
              {item.user?.username}
            </Text>
          </Link>
          <Flex
            style={{ gap: 4 }}
            fontSize={1}
            color={subtextColor}
            fontWeight={500}
            mt={2}
          >
            {item.views >= 5 && (
              <>
                <Text>{item.views} views</Text>
                <Text>&bull;</Text>
              </>
            )}
            <Text>
              {dateFormatWithDistanceSuffix(item.createdAt || item.createdDate)}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

const VideoPreview = ({
  active,
  handleClick,
  item,
  title,
}: {
  active: boolean;
  handleClick: () => void;
  item: IContentItem;
  title: string;
}) => {
  const mp4src =
    item.previewUrl || `${REACT_APP_S3_CLIP_BUCKET}/${item.clipPreviewPath}`;
  const poster =
    item.thumbnailStandardUrl ||
    `${REACT_APP_S3_CLIP_BUCKET}/${item.clipImageThumb}`;
  const videoPlayerRef = useRef(null);

  return (
    <VideoContainerFeed
      clickable={true}
      onClick={handleClick}
      style={{
        transition: "opacity 0.5s ease-in-out",
        opacity: `${active ? 1 : 0}`,
      }}
    >
      {active && (
        <VideoPlayer
          refForVideoPlayer={videoPlayerRef}
          mp4Source={mp4src}
          hlsSource={item.hlsSrc}
          loop={true}
          playAnalyticsProperties={{
            shareId: item.shareId,
            clipId: item._id,
            clipOwner: item.user,
            total_views: item.views,
            game: item.game,
          }}
          watchAnalyticsProperties={{
            collection: title,
            collectionType: "Generic",
            item: item.shareId,
            itemType: "Clip",
            action: "preview",
          }}
          options={{
            controls: false,
            muted: true,
            aspectRatio: "16:9",
            poster,
            playsinline: true,
            autoplay: "muted",
            userActions: {
              click: false,
            },
          }}
        />
      )}
    </VideoContainerFeed>
  );
};

const ResponsiveContainer = styled(Box)`
  aspect-ratio: 16/9;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export { ContentItem };
