import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { Flex, Image } from "rebass/styled-components";

import Remix from "components/atoms/form/button/remix";
import { ModalType } from "MVVM/@types";
import { ClipReactions } from "MVVM/Components/ClipReactions";
import { AllstarModalState } from "MVVM/State/modals";
import { IClip } from "MVVM/@types";
import { Button, MobileDownload } from "MVVM/Components/Button";
import { RiShareForwardFill } from "react-icons/ri";
import { CopyLink } from "MVVM/Components/Button/Share";
import { More } from "MVVM/Components/Clip/Components/ClipOptions/Components/MoreClipOptionsDropdown";
import { useInteractonInterceptor } from "MVVM/Hooks/useInteractionInterceptor";
import { EPaywallType } from "MVVM/Components/Modals/Paywall";
import { trackClipDownload } from "utils";
import { useUser } from "MVVM/Hooks/useUser";
import { usePortal } from "MVVM/Hooks/usePortal";
import { ClipDownload } from "MVVM/Components/Modals/ClipDownload";

interface IShareSectionProps {
  clip: IClip;
}

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const ShareSection = ({ clip }: IShareSectionProps) => {
  const { allstarUser } = useUser();
  const setAllstarModalState = useSetRecoilState(AllstarModalState);
  const checkFeatureAccessQuery = useInteractonInterceptor();
  const toggleModal = (modalType: ModalType) => {
    setAllstarModalState({
      data: clip,
      functions: {},
      isOpen: modalType,
    });
  };
  const setReportModalOpen = () => {
    setAllstarModalState({
      data: { _id: clip.id, user: { _id: clip.user._id } },
      functions: {},
      isOpen: ModalType.Report,
    });
  };
  const {
    portalOpen: clipDownloadPortalOpen,
    setPortalOpen: setClipDownloadPortalOpen,
    Portal: ClipDownloadPortal,
  } = usePortal(<ClipDownload clip={clip} />, "root");

  const handleDownloadClick = async () => {
    const downloadLink = clip.url;

    const { data } = await checkFeatureAccessQuery({
      variables: { feature: "FREE_ENGAGED_DOWNLOAD" },
    });

    if (data.checkFeatureAccess) {
      return setAllstarModalState({
        isOpen: ModalType.Download,
        data: {
          type: EPaywallType.download as EPaywallType,
        },
        functions: {},
      });
    }
    trackClipDownload(clip);
    window.open(downloadLink);
  };

  return (
    <ShareContainer>
      {clipDownloadPortalOpen && ClipDownloadPortal}
      {clip.isRemixable || allstarUser.isAdmin ? (
        <Remix
          classification={undefined}
          classificiation={undefined}
          clip={clip}
          disableOnClick={false}
          onClick={undefined}
          setModalOpen={() => toggleModal(ModalType.Remix)}
        />
      ) : clip.type === "SQUAD_MONTAGE" ? (
        <Image
          width={125}
          my={4}
          src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/montages/SquadMontage_Horizontal@2x.webp`}
        />
      ) : clip.type === "MATCH_MONTAGE" ? (
        <Image
          width={125}
          my={4}
          src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/montages/MatchMontage_Horizontal@2x.webp`}
        />
      ) : (
        <></>
      )}
      <StyledClipReactions clip={clip} />
      {allstarUser.loggedIn ? (
        <MobileDownload
          size="social"
          clip={clip}
          clipLink={clip.url}
          onClick={() => setClipDownloadPortalOpen(true)}
        />
      ) : (
        <CopyLink
          clip={clip}
          clipLink={`${window.location.origin}/clip/${clip.shareId}`}
          onClick={undefined}
          isMontage={false}
          isProfile={false}
        ></CopyLink>
      )}
      <StyledShareButton
        classification="secondaryRounded"
        onClick={() => toggleModal(ModalType.Share)}
      >
        <StyledShareIcon size={20} />
      </StyledShareButton>
      <More
        clip={clip}
        setShareModalOpen={() => toggleModal(ModalType.Share)}
        setReportModalOpen={setReportModalOpen}
        onDownloadClick={handleDownloadClick}
        position={{ top: "-20px", right: "28px" }}
      />
    </ShareContainer>
  );
};

const ShareContainer = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 0 25px;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    padding: 0;
    transform: scale(0.9);
  }
`;

const StyledShareButton = styled(Button)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.envy};
  display: flex;

  :hover {
    background-color: ${({ theme }) => theme.colors.darkerEnvy};
  }
`;

const StyledShareIcon = styled(RiShareForwardFill)``;

const StyledClipReactions = styled(ClipReactions)`
  min-height: 40px;
  width: 80px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 36px;
    width: 65px;
  }
`;

export { ShareSection };
