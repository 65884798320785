import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ClipsEmpty } from "../../../../Assets/Placeholders/hiddenclips.svg";
import { UserNotificationCTA } from "../../../UserNotificationCTA";
import { PartnerViewSession } from "../../../../State";
import { useRecoilState } from "recoil";

type TPrunedClipProps = {
  onClick: () => void;
  onDemand?: boolean;
  clipTitle?: string;
  clipId?: string;
};

const ClipPruned: React.FC<TPrunedClipProps> = ({
  onClick,
  onDemand,
  clipTitle,
  clipId,
}) => {
  const { t } = useTranslation();
  const [partnerViewSession] = useRecoilState(PartnerViewSession);

  const handleClick = () => {
    window.rudderanalytics.track("Button Click - Generate Clip", {
      useCase: partnerViewSession?.clip?.partner?.useCase,
      partner: partnerViewSession?.overlayConfig?.partner?.name,
      path: window.location.pathname,
      game: partnerViewSession?.clip?.game,
      clipId: clipId,
      type: onDemand ? "onDemand" : "rehydrate",
      // future improvement - track if user clicking is same as clip owner
    });

    onClick();
  };

  if (onDemand) {
    return (
      <UserNotificationCTA
        title={"Get your clip on Demand!"}
        subtitle={`We have the blueprints to make your ${clipTitle} clip, but we haven’t generated it yet. Press Create Clip to process it on Allstar.gg, and we’ll have it ready in no time!`}
        buttonCopy={"Create Clip"}
        onClick={handleClick}
      />
    );
  }
  return (
    <UserNotificationCTA
      title={"This Clip Was Archived"}
      subtitle={
        "Sorry, no one was watching this clip, so we didn't think we needed to keep it around. But no worries! You can create it again right now by clicking below."
      }
      buttonCopy={t("PRUNED.BUTTON_COPY")}
      onClick={handleClick}
    />
  );
};

export { ClipPruned };
