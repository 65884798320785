import React, { useEffect, useRef, memo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import videojs from "video.js";
import VideojsStyles from "./videojs/css";
import playerjs from "player.js";
import { useRecoilState, useRecoilValue } from "recoil";
import { cotdPlayer, muteState } from "../../../globalStorage/autoplay";
import styled from "styled-components";
import { Box } from "rebass/styled-components";

import { v4 as uuid } from "uuid";
import { PartnerOverlay } from "../../../MVVM/Views/Iframe/Components/PartnerOverlay";
import { PartnerViewSession } from "../../../MVVM/State";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { analytics } from "../../../MVVM/Analytics";

import { useIsDesktopApp } from "../../../MVVM/Hooks/useIsDesktopApp";

window.VIDEOJS_NO_DYNAMIC_STYLE = true;

const Controls = {
  play: "playToggle",
  volume: "volumePanel",
  seekbar: "progressControl",
  timer: "remainingTimeDisplay",
  playbackrates: "playbackRateMenuButton",
  fullscreen: "fullscreenToggle",
};

const VideoPlayer = memo(
  ({
    id,
    VideoOverlay,
    overlayConfig = { display: false },
    isMontage,
    mobileView,
    ...props
  }) => {
    const [showOverlay, setShowOverlay] = useState(false);
    //prevents cotd from having 2 players with the same id on the same page
    const playerId = `video-player-${props.cotd ? props.clip.shareId : id}`;
    const player = useRef();
    const previousProps = useRef();
    const { t } = useTranslation("translation");
    const playerRef = useRef();
    const partnerViewSession = useRecoilValue(PartnerViewSession);
    const { isDesktop } = useIsDesktopApp();
    /* eslint-disable */
    const [cotdPlaying, setCotdPlaying] = useRecoilState(cotdPlayer);
    const [muted, setMuted] = useRecoilState(muteState);
    const [secondsWatched, setSecondsWatched] = useState(0);
    const [trackedView, setTrackedView] = useState(false);
    const [analyticsEventType, setAnalyticsEventType] = useState("default");

    let interval = useRef(null);
    /* eslint-enable */

    const playerOptions = {};

    const startTrackingSecondsWatched = useCallback(() => {
      if (!interval.current) {
        interval.current = setInterval(() => {
          setSecondsWatched((prev) => prev + 1);
        }, 1000);
      }
    }, [interval]);

    const stopTrackingSecondsWatched = useCallback(() => {
      clearInterval(interval.current);
      interval.current = null;
    }, [interval]);

    useEffect(() => {
      if (secondsWatched >= 5 && !trackedView) {
        analytics.track("Video Player - Watched 5 seconds", {
          shareId: props.clip.shareId,
          clipId: props.clip._id,
          clipOwner: props.clip.user,
          total_views: props?.clip?.views,
          total_reactions: props?.totalReactions,
          total_length: player.current.duration(),
          tags: props?.clip?.clipProperties,
          game: props?.clip?.game,
          file_extension: player.current.currentSrc().split(".").pop(),
          muted: player.current.muted(),
          volume: player.current.volume(),
        });
        setTrackedView(true);
      }
    }, [
      secondsWatched,
      trackedView,
      props.clip.shareId,
      props.clip._id,
      props.clip.user,
      props?.clip?.views,
      props?.totalReactions,
      props?.clip?.clipProperties,
      props?.clip?.game,
    ]);

    useEffect(() => {
      return () => {
        if (player.current) {
          player.current.dispose();
        }
      };
    }, []);

    //This useEffect is specific to the COTD at the top of the clips page. It will control play/pause depending on the recoilState cotdPlaying (imported from globalStorage)
    useEffect(() => {
      if (!player.current) return;
      if (cotdPlaying && props.cotd) {
        player.current
          .play()
          .then(() => {})
          .catch((e) => {});
      } else if (props.cotd) {
        player.current.pause();
      }
    }, [cotdPlaying]); // eslint-disable-line

    const hadGoodReasonToUpdate = () => {
      // console.time("hadGoodReasonToUpdate");

      const changesObj = {};
      if (previousProps.current) {
        // Get all keys from previous and current props
        const allKeys = Object.keys({ ...previousProps.current, ...props });
        // Use this object to keep track of changed props
        // Iterate through keys
        allKeys.forEach((key) => {
          // If previous is different from current
          if (previousProps.current[key] !== props[key]) {
            // Add to changesObj
            changesObj[key] = {
              from: previousProps.current[key],
              to: props[key],
            };
          }
        });

        // If changesObj not empty then output to console
        // if (Object.keys(changesObj).length) {
        //   console.log('[why-did-you-update]', changesObj);
        // }
      }

      previousProps.current = Object.assign({}, props);
      // console.timeEnd("hadGoodReasonToUpdate");

      return Object.keys(changesObj).includes("sources");
    };

    useEffect(() => {
      // console.log("INSIDE", props.sources);
      if (props.sources.length === 0) {
        return;
      }

      if (!player.current) {
        generate_player_options();

        player.current = videojs(playerRef.current, playerOptions, () => {
          const adapter = new playerjs.VideoJSAdapter(player.current);
          adapter.ready();
        });

        set_controls_visibility(props.hideControls);
        if (props.isFeed) player.current.userActive(false);
        init_player_events();
      } else if (!hadGoodReasonToUpdate()) {
        return;
      }

      player.current.src(
        props.sources && props.sources.length ? props.sources : props.src,
      );

      player.current.poster(props.poster);

      player.current.ready(() => {
        // if (props.skipBumper) {
        //   let bumperLength = getBumperLength(props.clip);
        //   if (bumperLength > 0 && props.skipBumper) {
        //     player.current.currentTime(0);
        //   }
        // }

        if (props.autoplay) {
          player.current
            .play()
            .then(() => {})
            .catch((e) => {
              console.log(e);
            });
        }
      });
    }, [props]); // eslint-disable-line

    function getBumperLength(clip) {
      // console.time("getBumperLength");

      let bumperLength;
      if (clip?.personalizations?.cc?.length) {
        const ccArray = clip.personalizations.cc;
        const introIndex = ccArray.findIndex((obj) => {
          return obj.slot === "intro";
        });
        bumperLength =
          introIndex >= 0 ? ccArray[introIndex].metadata.totalLength : null;
      }
      if (bumperLength === null || bumperLength === undefined) {
        bumperLength = 5;
      }
      // console.timeEnd("getBumperLength");

      return bumperLength;
    }

    const generate_player_options = () => {
      playerOptions.controls = props.controls;
      playerOptions.autoplay = props.autoplay;

      playerOptions.playsinline = props.playsinline;
      playerOptions.preload = props.preload;
      playerOptions.width = props.width;
      playerOptions.height = props.height;
      playerOptions.bigPlayButton = props.bigPlayButton;
      playerOptions.html5 = props.html5;
      playerOptions.loop = props.loop;
      playerOptions.muted = props.muted;
      const hidePlaybackRates =
        props.hidePlaybackRates || props.hideControls.includes("playbackrates");
      if (!hidePlaybackRates) playerOptions.playbackRates = props.playbackRates;
    };

    // const countImpression = (shareId) => {
    //   window.rudderanalytics.track("Video Player - Loop", {
    //     path: window.location.pathname,
    //     shareId: shareId,
    //     clipId: props.clip._id,
    //     clipOwner: props.clip.user,
    //   });
    // };
    const clearImpressionCount = (shareId) => {
      try {
        localStorage.setItem("lastViewedClip", shareId);
        localStorage.setItem("impressions", "0");
      } catch (e) {}
    };
    const countImpression = (shareId, isClip) => {
      // console.time("countImpression");

      let sendEvent = true;
      const payload = JSON.parse(localStorage.getItem("payload"));

      const isThisClip = localStorage.getItem("lastViewedClip");
      if (!isThisClip || isThisClip !== shareId) {
        // If they dont have a lastViewedClip OR if they do and its not the same shareid store it
        clearImpressionCount(shareId);
      } else {
        // here it is the same share id and we should count how many
        let impressions = parseInt(localStorage.getItem("impressions"));
        localStorage.setItem("impressions", ++impressions);
        // Here we block anything over 100 continus loops.
        // users can still game the system but will prevent just leaving the profile page open.
        if (impressions > 100) sendEvent = false;
      }

      if (sendEvent) {
        Axios.post(`${process.env.REACT_APP_AWS_SERVERLESS}/clips/views`, {
          shareId: shareId,
          isClip: !isMontage,
        }).catch(() => {});
      } else {
        window.rudderanalytics.track("Impression Block", {
          path: window.location.pathname,
          shareId: shareId,
          clipId: props.clip._id,
          clipOwner: props.clip.user,
          viewerEmail: payload.sub,
        });
      }
      // console.timeEnd("countImpression");
    };
    const set_controls_visibility = (hidden_controls) => {
      Object.keys(Controls).map((x) => {
        return player.current.controlBar[Controls[x]].show();
      });
      hidden_controls.map((x) => {
        return player.current.controlBar[Controls[x]].hide();
      });
    };

    const init_player_events = () => {
      // console.time("init_player_events");

      let currentTime = 0;
      let previousTime = 0;
      let position = 0;
      let lastProgressPercent = 0;
      const sessionId = uuid();

      player.current.ready(() => {
        props.onReady(player.current);
        window.player = player.current;
        setShowOverlay(true);
        setAnalyticsEventType("pre");
      });
      player.current.on("canplay", () => {
        props.onCanPlay(player.current);
      });
      player.current.on("error", () => {
        if (props.onError) {
          props.onError(player.current);
        }
      });
      player.current.on("play", () => {
        // adjust volume to match what is saved in localstorage
        try {
          const playerVolume = localStorage.getItem("playerVolume");
          if (props.volume)
            player.current.volume(parseFloat(props.volume) / 100);
          if (playerVolume) player.current.volume(parseFloat(playerVolume));

          setShowOverlay(false);
          window.rudderanalytics.track("Video Player - Play", {
            session_id: sessionId,
            shareId: props.clip.shareId,
            clipId: props.clip._id,
            clipOwner: props.clip.user,
            total_views: props?.clip?.views,
            total_reactions: props?.totalReactions,
            total_length: player.current.duration(),
            tags: props?.clip?.clipProperties,
            game: props?.clip?.game,
            file_extension: player.current.currentSrc().split(".").pop(),
          });
          startTrackingSecondsWatched();

          if (props.onPlay)
            props.onPlay(sessionId, player.current.currentTime());
          if (
            partnerViewSession?.overlayConfig?.fullscreen &&
            !player.current.forcedFullscreen
          ) {
            const wrapper = document.getElementsByClassName("iframeWrapper")[0];
            wrapper.requestFullscreen().catch((err) => {
              console.log(err);
            });
            HijackFullscreen();
            player.current.forcedFullscreen = true;
          }
        } catch (e) {}
      });
      player.current.on("pause", () => {
        setShowOverlay(true);
        setAnalyticsEventType("pause");
        stopTrackingSecondsWatched();
        if (props.onPause) props.onPause(player.current.currentTime());
      });

      // saves the volume when the user changes it, only if it isn't COTD player. Cotd player changes volume automatically and we need to ignore it
      player.current.on("volumechange", () => {
        try {
          if (!props.cotd && player.current.duration()) {
            localStorage.setItem("playerVolume", player.current.volume());
            setMuted(player.current.muted());
          }
        } catch (e) {}
      });

      player.current.on("timeupdate", () => {
        if (props.onTimeUpdate) {
          props.onTimeUpdate(
            player.current.currentTime(),
            player.current.duration(),
            sessionId,
          );
        }
        previousTime = currentTime;
        currentTime = player.current.currentTime();
        if (previousTime < currentTime) {
          position = previousTime;
          previousTime = currentTime;
        }
        const percentageDone = Math.floor(
          (currentTime / player.current.duration()) * 100,
        );
        if (percentageDone - lastProgressPercent >= 10) {
          // window.rudderanalytics.track("Video Player - % Progress", {
          //   session_id: sessionId,
          //   position: Math.floor(currentTime),
          //   total_length: player.current.duration(),
          //   percent_watched: percentageDone,
          //   shareId: props.clip.shareId,
          //   clipId: props.clip._id,
          //   clipOwner: props.clip.user,
          // });

          lastProgressPercent = percentageDone;
        }
      });

      player.current.on("seeking", () => {
        setShowOverlay(false);
        player.current.off("timeupdate", () => {});
        player.current.on("seeked", () => {});
        if (props.onSeeking) props.onSeeking(player.current.currentTime());
      });

      player.current.on("seeked", () => {
        setShowOverlay(false);
        const completeTime = Math.floor(player.current.currentTime());
        lastProgressPercent = Math.floor(
          (currentTime / player.current.duration()) * 100,
        );
        if (props.onSeeked) props.onSeeked(position, completeTime);
      });

      player.current.on("ended", () => {
        if (player.current.isFullscreen()) {
          if (videojs.browser.IS_SAFARI) {
            player.current.exitFullscreen();
          }
          // player.current.exitFullWindow();
        }

        //if it is a video player in the feed, loop is set to false, and we manually handle looping here so that we can skip the bumper.
        if ((props.isFeed || props.loop) && !isDesktop) {
          const bumperLength = getBumperLength(props.clip);
          if (bumperLength > 0 && props.skipBumper) {
            //this chunk is where you would enable skipping the bumper. Set current time to bumperLength variable above. Currently set to 0 (dont skip)
            player.current.currentTime(0);
          } else {
            player.current.currentTime(0);
          }
          player.current
            .play()
            .then(() => {})
            .catch((e) => {});

          countImpression(props.clip.shareId, !isMontage);
          window.rudderanalytics.track("Video Player - Loop", {
            path: window.location.pathname,
            shareId: props.clip.shareId,
            clipId: props.clip._id,
            clipOwner: props.clip.user,
          });
        }

        setSecondsWatched(0);
        setTrackedView(false);

        window.rudderanalytics.track("Video Player - End", {
          session_id: sessionId,
          shareId: props.clip.shareId,
          clipId: props.clip._id,
          clipOwner: props.clip.user,
          total_length: player.current.duration(),
          tags: props?.clip?.clipProperties,
          game: props?.clip?.game,
          total_views: props?.clip?.views,
          total_reactions: props?.totalReactions,
        });
        if (window.Gleam) {
          window.Gleam.push("watchClip");
        }
        if (props.onEnd) props.onEnd(sessionId);
        setShowOverlay(true);
        setAnalyticsEventType("end");
      });

      player.current.on("loadeddata", () => {
        if (props.onLoadedData) props.onLoadedData(sessionId);

        // safari will only let you set currentTime after clip metadata has loaded
        // let bumperLength = 0;
        // if (bumperLength > 0 && props.skipBumper) {
        //   player.current.currentTime(0);
        //   player.current
        //     .play()
        //     .then(() => {})
        //     .catch((e) => {});
        // }
        countImpression(props.clip.shareId, !isMontage);
      });

      if (props.onLoadedMetaData) {
        player.current.on("loadeddata", (meta) => {
          props.onLoadedMetaData(player.current.duration());
        });
      }
      // console.timeEnd("init_player_events");
    };

    const UpNextComponent = props.UpNextComponent;
    // let VideoOverlay = props.VideoOverlay;

    if (partnerViewSession.overlayConfig.display) {
      //this block basically hijacks the fullscreen button so we can fullscreen our iframe wrapper instead of the
      //videojs player. we were losing our overlay since the video would go fullscreen
      HijackFullscreen();
    }

    return (
      <VideojsStyles data-vjs-player embedded={props.embedded}>
        <HoverWrapper>
          {UpNextComponent}

          <PartnerOverlay
            display={showOverlay}
            platform={props.platform}
            shareId={props.clip.shareId}
            playerId={playerId}
            clip={props.clip}
            translateHook={t}
            onReplayClick={() => {
              player.current.currentTime(0);
              player.current.play().catch(() => {});
              setShowOverlay(false);
            }}
            analyticsEventType={analyticsEventType}
          />

          <video
            ref={playerRef}
            onTouchStart={() => {
              if (player.current) player.current.controlBar.show();
            }}
            id={playerId}
            data-testid="video-player"
            className={`
            video-js
            vjs-big-play-centered 
            vjs-big-play-button
            ${mobileView && "vjs-9-16"}
          `}
          ></video>
        </HoverWrapper>
      </VideojsStyles>
    );
  },
);

const HijackFullscreen = () => {
  const fullscreen = document.getElementsByClassName("vjs-fullscreen-control");
  let clonedFullscreen, originalButton;

  if (fullscreen.length) {
    originalButton = fullscreen[0];
    clonedFullscreen = originalButton.cloneNode(true);
    clonedFullscreen.addEventListener("click", () => {
      const wrapper = document.getElementsByClassName("iframeWrapper")[0];
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        wrapper.requestFullscreen().catch((err) => {
          console.log(err);
        });
      }
    });
    originalButton.replaceWith(clonedFullscreen);
  }
};

VideoPlayer.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.string,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
  playsinline: PropTypes.bool,
  loop: PropTypes.bool,
  skipBumper: PropTypes.bool,
  preload: PropTypes.oneOf(["auto", "none", "metadata"]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideControls: PropTypes.arrayOf(PropTypes.string),
  bigPlayButton: PropTypes.bool,
  bigPlayButtonCentered: PropTypes.bool,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onCanPlay: PropTypes.func,
  onPause: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onSeeking: PropTypes.func,
  onSeeked: PropTypes.func,
  onEnd: PropTypes.func,
  sources: PropTypes.arrayOf(PropTypes.object),
  html5: PropTypes.object,
  playbackRates: PropTypes.arrayOf(PropTypes.number),
  hidePlaybackRates: PropTypes.bool,
  className: PropTypes.string,
};

VideoPlayer.defaultProps = {
  bumperLength: 4.8,
  src: "",
  poster: "",
  controls: true,
  autoplay: false,
  playsinline: true,
  preload: "auto",
  playbackRates: [0.5, 1, 1.5, 2],
  hidePlaybackRates: false,
  className: "",
  hideControls: [],
  bigPlayButton: true,
  bigPlayButtonCentered: true,
  sources: [],
  html5: {},
  loop: false,
  skipBumper: false,
  muted: true,
  onReady: () => {},
  onCanPlay: () => {},
  onPlay: () => {},
  onPause: () => {},
  onTimeUpdate: () => {},
  onSeeking: () => {},
  onSeeked: () => {},
  onEnd: () => {},
};

export default VideoPlayer;

const HoverWrapper = styled(Box)`
  position: relative;
  top: 0;
  left: 0;

  .hover-controls {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
  }

  // this is the hover logic in Partner Overlay i.e. className hover-controls
  &:hover .hover-controls {
    opacity: 1;
    pointer-events: auto;
  }
`;
