import React, { useState, useEffect } from "react";
import { Body } from "../../Text";
import { FloatingContent } from "../../Wrappers";
import { Row } from "../../Form";
import { Button } from "../../Button";
import { TextArea } from "../../Form/Existing/textarea";
import { Error } from "../../Inputs";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { CheckboxWithLabel } from "../../Checkbox";
import { useUser } from "../../../Hooks/useUser";
import { Box } from "rebass";

export function Report({
  setModalOpen,
  reportedUserId,
  reportedClipId,
  isProfile,
}: any) {
  const [state, setState] = useState({
    error: false,
    errorMsg: "",
    loading: false,
    complete: false,
  });
  const { allstarUser } = useUser();
  const TEXT_AREA_NAME = "additionalFeedback"; // this name is being specially handled in a few places
  useEffect(() => {
    window.rudderanalytics.track("Open Report Form");
  });
  const { handleSubmit, register, getValues, setValue } = useForm();
  const onSubmit = async (form: any) => {
    if (state.loading) {
      return;
    }

    const asArray = Object.entries(form);
    const filtered = asArray.filter(
      ([key, value]) => value === true || key === TEXT_AREA_NAME,
    );

    let category: any = filtered.filter((subArray) => {
      return subArray[1] === true;
    });

    if (category[0]) {
      category = category[0][0];
    } else {
      category = "OTHER";
    }
    const message = form[TEXT_AREA_NAME];

    setState({
      ...state,
      loading: true,
      error: false,
    });
    const opt: any = {
      method: "POST",
      url: `${process.env.REACT_APP_AWS_SERVERLESS}/users/reportuser`,
      data: {
        reporting: allstarUser?.user?._id,
        reported: reportedUserId,
        reportedClipId: reportedClipId,
        message: message,
        category: category,
      },
    };

    console.log("opt", opt);

    try {
      const response = await Axios(opt);
      if (response.status === 200) {
        setState({
          ...state,
          loading: false,
          error: false,
          complete: true,
        });
      }
    } catch (e: any) {
      setState({
        ...state,
        loading: false,
        error: true,
        errorMsg: e.response && e.response.data ? e.response.data.msg : "",
      });
    }
  };

  if (state.complete) {
    return <Thanks setModalOpen={setModalOpen} />;
  }

  function manageCheckboxes(e: any) {
    const values = Object.keys(getValues());
    for (const value of values) {
      //if its a checkbox and not the one we just clicked, set its value to false
      if (value !== e.target.name && value !== TEXT_AREA_NAME) {
        setValue(value, false);
      }
    }
  }
  return (
    <Box name="report-content-modal">
      {" "}
      <FloatingContent
        title="Report Profile"
        body={
          <Body>
            Help us understand the problem. What's the issue with this users
            profile?
          </Body>
        }
      >
        {" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <CheckboxWithLabel
              checkbox={{
                value: "AVATAR",
                id: "AVATAR",
                name: "AVATAR",
                ref: register("AVATAR", { required: false }),
              }}
              onClick={(e: any) => {
                manageCheckboxes(e);
              }}
            >
              Innappropriate profile image
            </CheckboxWithLabel>
          </Row>
          {isProfile ? (
            <></>
          ) : (
            <Row>
              <CheckboxWithLabel
                checkbox={{
                  value: "CLIP_TITLE",
                  id: "CLIP_TITLE",
                  name: "CLIP_TITLE",
                  ref: register("CLIP_TITLE", { required: false }),
                }}
                onClick={(e: any) => {
                  manageCheckboxes(e);
                }}
              >
                Innappropriate clip title
              </CheckboxWithLabel>
            </Row>
          )}

          <Row>
            {" "}
            <CheckboxWithLabel
              checkbox={{
                value: "IMPERSONATION",
                id: "IMPERSONATION",
                name: "IMPERSONATION",
                ref: register("IMPERSONATION", { required: false }),
              }}
              onClick={(e: any) => {
                manageCheckboxes(e);
              }}
            >
              Impersonation
            </CheckboxWithLabel>
          </Row>
          <Row>
            {" "}
            <CheckboxWithLabel
              checkbox={{
                value: "SPAM",
                id: "SPAM",
                name: "SPAM",
                ref: register("SPAM", { required: false }),
              }}
              onClick={(e: any) => {
                manageCheckboxes(e);
              }}
            >
              Spam
            </CheckboxWithLabel>
          </Row>
          <Row>
            {" "}
            <CheckboxWithLabel
              checkbox={{
                value: "OTHER",
                id: "OTHER",
                name: "OTHER",
                ref: register("OTHER", { required: false }),
              }}
              onClick={(e: any) => {
                manageCheckboxes(e);
              }}
              name="report-modal-other-checkbox"
            >
              Other
            </CheckboxWithLabel>
          </Row>
          <Row>
            <TextArea
              id={TEXT_AREA_NAME}
              {...register(`${TEXT_AREA_NAME}`, {
                required: false,
              })}
            />
          </Row>

          {state.error && state.errorMsg ? (
            <Error>{state.errorMsg}</Error>
          ) : (
            <></>
          )}

          <Button
            disabled={state.loading}
            type="submit"
            name="report-modal-submit-button"
          >
            {state.loading ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </FloatingContent>
    </Box>
  );
}

function Thanks({ setModalOpen }: any) {
  return (
    <Box name="report-modal-thanks">
      {" "}
      <FloatingContent
        title={
          <>
            Thanks!{" "}
            <span aria-label="thanks" role="img">
              🙌🏼
            </span>
          </>
        }
        body={
          <Body>Your feedback helps make Allstar awesome for everyone.</Body>
        }
      >
        <Button
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Close
        </Button>
      </FloatingContent>
    </Box>
  );
}
