import { Flex, Text } from "rebass/styled-components";
import { CountdownDate, StyledBody } from "./styledComponents";

type TCountDownRendererProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  color: string;
  variant?: string;
};

export const CountDownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  color,
}: TCountDownRendererProps) => {
  return (
    <Flex
      justifyContent={["center", "center", "center", "space-between"]}
      flexDirection={"column"}
    >
      <CountdownDate flexDirection={"row"} justifyContent="start">
        <StyledBody color={color}>
          <Text display={"inline"} color="ice">
            Ends in
          </Text>{" "}
          {days < 10 ? "0" : ""}
          {days}:{hours < 10 ? "0" : ""}
          {hours}:{minutes < 10 ? "0" : ""}
          {minutes}:{seconds < 10 ? "0" : ""}
          {seconds}
        </StyledBody>
      </CountdownDate>
    </Flex>
  );
};

export const MinimalCountDownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  color,
  variant,
}: TCountDownRendererProps) => {
  return (
    <Flex
      justifyContent={["center", "center", "center", "space-between"]}
      flexDirection={"column"}
    >
      <CountdownDate flexDirection={"row"} justifyContent="start">
        <Text
          color={color}
          variant={variant || "text.labelSmall"}
          fontWeight={600}
        >
          <Text
            color={"ice"}
            variant={variant || "text.labelSmall"}
            fontWeight={600}
            style={{ display: "inline" }}
          >
            Ends in{" "}
          </Text>
          {days < 10 ? "0" : ""}
          {days}:{hours < 10 ? "0" : ""}
          {hours}:{minutes < 10 ? "0" : ""}
          {minutes}:{seconds < 10 ? "0" : ""}
          {seconds}
        </Text>
      </CountdownDate>
    </Flex>
  );
};
