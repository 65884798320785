import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

import { dateFormatWithDistanceSuffix } from "MVVM/Utilities/Clip";
import { TClip } from "MVVM/@types";

interface IFeedItemProps {
  clip: TClip;
  active?: boolean;
}

const FeedItem = ({ clip, active }: IFeedItemProps) => {
  if (!clip) return <></>;

  return (
    <FeedItemContainer active={active}>
      <Thumbnail
        style={{
          backgroundImage: `url(${clip?.thumbnailOverlayUrl})`,
        }}
      />
      <Flex
        flexDirection="column"
        justifyContent={["space-around", "space-around", "space-evenly"]}
        style={{ gap: 2 }}
      >
        <Text fontWeight={500}>{clip.title}</Text>
        <Text fontWeight={500} fontSize={1} color="chalk">
          {clip.user.username}
        </Text>
        <Flex alignItems="center">
          {!!clip.views && clip.views >= 5 && (
            <>
              <Text fontWeight={500} fontSize={1} color="chalk">
                {clip.views} views
              </Text>
              <Text fontWeight={500} fontSize={1} color="chalk" px={2}>
                &bull;
              </Text>
            </>
          )}
          <Text fontWeight={500} fontSize={1} color="chalk">
            {dateFormatWithDistanceSuffix(clip.createdAt)}
          </Text>
        </Flex>
      </Flex>
    </FeedItemContainer>
  );
};

const FeedItemContainer = styled(Flex)<{ active?: boolean }>`
  gap: 18px;
  padding: 12px;
  width: 100%;
  overflow: hidden;
  color: reset;

  :hover {
    background: ${({ theme }) => theme.colors.navy};
    border-radius: 8px;
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    flex-direction: column;
  }
`;

const Thumbnail = styled(Box)`
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;
  height: 90px;
  position: relative;
  width: 160px;

  @media (max-width: ${({ theme }) => theme.breaks.theater}) {
    width: 25%;
    aspect-ratio: 16/9;
    height: inherit;
  }

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    flex-grow: 1;
    width: 100%;
    aspect-ratio: 16/9;
    height: inherit;
  }
`;

export { FeedItem };
