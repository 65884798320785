import { useMutation, useQuery } from "@apollo/client";

import { GET_CLIP } from "MVVM/GraphQL/clip";
import { RESTORE_PRUNED_CLIP } from "MVVM/GraphQL/restorePrunedClip";

interface ClipExperienceViewModelProps {
  id: string;
}

const ClipExperienceViewModel = ({ id }: ClipExperienceViewModelProps) => {
  const { data, loading, error } = useQuery(GET_CLIP, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });
  const [createPrunedClip] = useMutation(RESTORE_PRUNED_CLIP, {
    onError: () => {},
    refetchQueries: [GET_CLIP],
  });

  return {
    data,
    error,
    loading,
    createPrunedClip,
  };
};

export { ClipExperienceViewModel };
