import React from "react";
import { useGateValue } from "@statsig/react-bindings";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { ClipFullModalState } from "../../../State/modals";
import ClipFull from "../../../Views/ClipFull";
import { FloatingContainer, Modal } from "../../Wrappers";
import { ModalType } from "../../../@types";
import { StyledFloatingContainer, CloseIcon } from "./styledComponents";
import Close from "../../../../assets/images/floatingannouncement/close.svg";

const ClipModal = () => {
  const clipExperienceEnabled = useGateValue("new_clip_page");
  const [clipFullModalState, setClipFullModalState] =
    useRecoilState(ClipFullModalState);
  const { isOpen, shareId } = clipFullModalState;
  const closeModal = () =>
    setClipFullModalState({
      ...clipFullModalState,
      isOpen: ModalType.None,
    });

  if (isOpen !== ModalType.ClipFull) return <></>;
  return (
    <Modal
      isModalOpen={isOpen === ModalType.ClipFull}
      setModalOpen={() => null}
      handleModalClose={closeModal}
      disableOutsideClose={undefined}
      style={{ overflow: "auto" }}
    >
      {clipExperienceEnabled ? (
        <StyledFloatingContainerNew>
          <CloseIcon size={28} src={Close} onClick={() => closeModal()} />
          {shareId && (
            <ClipFull
              shareId={shareId}
              modalView={true}
              closeModal={closeModal}
            />
          )}
        </StyledFloatingContainerNew>
      ) : (
        <StyledFloatingContainer>
          <CloseIcon size={28} src={Close} onClick={() => closeModal()} />
          {shareId && (
            <ClipFull
              shareId={shareId}
              modalView={true}
              closeModal={closeModal}
            />
          )}
        </StyledFloatingContainer>
      )}
    </Modal>
  );
};

// @TODO Temporary, replace <StyledFloatingContainer> when feature gate is removed
const StyledFloatingContainerNew = styled(FloatingContainer)`
  align-items: stretch;
  background: ${({ theme }) => theme.colors.darkerNed};
  border-radius: 16px;
  border-width: 0;
  border: 1px solid ${({ theme }) => theme.colors.ned};
  box-shadow: 0 0 0px #233438;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 20px;
  z-index: 101;

  padding: 8px;
  max-width: 1680px;
  height: 80%;
  width: 80%;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    width: 90%;
  }
`;

export { ClipModal };
