import { Flex } from "rebass/styled-components";
import { Headline } from "../../../components/text";
import { getClipTitle } from "../../Utilities/Clip";
import {
  TwitterShare,
  FacebookShare,
  ClipboardShare,
  WhatsAppShare,
} from "../Button/Share";
import { YouTubeShare } from "../Button/PlatformShare/Youtube";
import { TikTokUpload } from "../Button/PlatformShare/TikTok";

interface ShareContentsProps {
  clip?: any;
  card?: any;
  shareLink?: string;
}

export function ShareContents({ clip, card, shareLink }: ShareContentsProps) {
  const isMontage = window.location.href.includes("montage");
  const hasMobile =
    clip.portraitUrl ||
    Boolean(
      clip.mobile?.find(
        (x: any) =>
          x.aspectRatio === "9:16" && ["Processed", "Ready"].includes(x.status),
      ),
    );

  const clipLink =
    clip.url || !isMontage
      ? `https://${window.location.hostname}/clip?clip=${clip.shareId}`
      : `https://${window.location.hostname}/montage?montage=${clip.shareId}`;
  const link =
    shareLink ||
    (card
      ? `https://${window.location.hostname}/studio/${card._id}`
      : `https://${window.location.hostname}/clip?clip=${clip.shareId}`);

  const title = card
    ? card.ui.title
    : clip.title
    ? clip.title
    : getClipTitle(clip);

  return (
    <>
      <Flex
        flexDirection="column"
        alignContent="center"
        maxWidth="490px"
        width="100%"
      >
        <Flex
          mb={[5, 7, 7, 7]}
          flexDirection="row"
          justifyContent="space-evenly"
        >
          <YouTubeShare
            hasMobile={hasMobile}
            size="social"
            classification="youTube"
            clip={clip}
            clipLink={clipLink}
          />
          <TikTokUpload
            hasMobile={hasMobile}
            size="social"
            classification="tikTok"
            clip={clip}
            clipLink={clipLink}
          />
          <WhatsAppShare link={link} clip={clip}></WhatsAppShare>
          <TwitterShare link={link} title={title} clip={clip}></TwitterShare>
          <FacebookShare link={link} clip={clip}></FacebookShare>
        </Flex>
        <Headline mb={4}>Copy Link</Headline>
        <ClipboardShare link={link} clip={clip}></ClipboardShare>
      </Flex>
    </>
  );
}
