import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import { useGateValue } from "@statsig/react-bindings";

import { ShareContents } from "MVVM/Components/ShareOptions";
import { Title1 } from "MVVM/Components/Text";
import { FloatingContainer } from "MVVM/Components/Wrappers";

interface IShareModalProps {
  clip: {
    clipImageThumb: string;
    clipSnapshot?: string;
    fromEdit?: boolean;
    shareId: string;
    thumbnailStandardUrl?: string;
  };
}

const ShareModal = ({ clip }: IShareModalProps) => {
  const img =
    clip.thumbnailStandardUrl ||
    `${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipSnapshot}`;
  const { origin } = window.location;
  const clipExperienceEnabled = useGateValue("new_clip_page");

  const shareLink = clipExperienceEnabled
    ? `${origin}/clip/${clip.shareId}`
    : `${origin}/clip?clip=${clip.shareId}`;

  return (
    <>
      <FloatingContainer maxWidth="500px" maxHeight="700px">
        {(clip.clipSnapshot || clip.thumbnailStandardUrl) && (
          <Flex
            mb={10}
            style={{ position: "relative", justifyContent: "center" }}
          >
            <StyledThumb src={img} />
          </Flex>
        )}
        <Flex
          flexDirection="column"
          alignItems="stretch"
          justifyContent="flex-start"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            name="clip-share-modal"
          >
            <StyledTitle1 mb={5}>Share with your friends</StyledTitle1>
            <ShareContents clip={clip} shareLink={shareLink}></ShareContents>
          </Flex>
        </Flex>
      </FloatingContainer>
    </>
  );
};

const StyledThumb = styled(Image)`
  border-radius: 10px;
  box-shadow: 0 0 20px 20px rgb(0 0 0 / 15%);
  color: ${({ theme }) => theme.colors.ice};
  display: flex;
  height: auto;
  max-width: 410px;
  object-fit: contain;
  overflow: hidden;
  position: absolute;
  top: -155px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    max-width: 280px;
    top: -70px;
  }
`;

const StyledTitle1 = styled(Title1)`
  font-weight: 500;
`;

export { ShareModal };
