import { Box } from "rebass/styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";

import { SectionTitle } from "../styledComponents";
import { SuggestedItem } from "../SuggestedItem";
import { SuggestedFeedViewModel } from "./ViewModel";
import { ISuggestedFollower } from "../@types";
import { Loading } from "../Loading";

const SuggestedFeed = () => {
  const { suggestedFollowers, error, loading } = SuggestedFeedViewModel();

  if (loading) return <Loading />;
  if (!suggestedFollowers || !suggestedFollowers.length || error) return <></>;

  return (
    <Box>
      <SectionTitle mb={4}>Suggested</SectionTitle>
      <Swiper
        centeredSlides={false}
        spaceBetween={32}
        slidesPerView="auto"
        mousewheel={true}
        modules={[Mousewheel, Pagination, Navigation]}
      >
        {suggestedFollowers.map((user: ISuggestedFollower) => (
          <SwiperSlide key={user._id} style={{ width: "auto" }}>
            <SuggestedItem user={user} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export { SuggestedFeed };
