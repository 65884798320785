import { useState } from "react";
import { Flex } from "rebass/styled-components";
import { useRecoilState } from "recoil";

import Remix from "../../../../components/atoms/form/button/remix";
import { FloatingContainer, Modal } from "../../../Components/Wrappers";
import { RemixModal } from "../../../Components/Modals/RemixSurpriseOrStudio";
import { ClipShare, CopyLink } from "../../../Components/Button/Share";
import { ShareModal } from "../../../Components/Modals/ShareOptions";
import { IClipPreview } from "../@types";
import { ClipFullViewModel } from "../ViewModel";
import { StyledClipReactions } from "../styledComponents";
import { More } from "../../../Components/Clip/Components/ClipOptions/Components/MoreClipOptionsDropdown";
import { trackClipDownload } from "../../../../utils";
import { EPaywallType } from "../../../Components/Modals/Paywall";
import { ModalType } from "../../../@types";
import { AllstarModalState } from "../../../State/modals";
import { useInteractonInterceptor } from "../../../Hooks/useInteractionInterceptor";
import { Report } from "../../../Components/Modals/ReportClip";

interface IShareOptionsProps {
  clip: IClipPreview;
}

const ShareOptions = ({ clip }: IShareOptionsProps) => {
  const { mutateReaction } = ClipFullViewModel({});
  const [isRemixModalOpen, setRemixModalOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const [, setTotalReactions] = useState(0);
  const { origin } = window.location;
  const shareLink = `${origin}/clip?clip=${clip.shareId}`;
  const checkFeatureAccessQuery = useInteractonInterceptor();
  const isMontage = false;

  const handleDownloadClick = async () => {
    const downloadLink = clip.cf?.downloadUrl
      ? clip.cf?.downloadUrl
      : `${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipLink}`;
    let data;
    try {
      data = await checkFeatureAccessQuery({
        variables: { feature: "FREE_ENGAGED_DOWNLOAD" },
      });
    } catch (e) {
      console.log("e:", e);
    }
    const paywalledUser = data?.data?.checkFeatureAccess;
    if (paywalledUser) {
      return setAllstarModalState({
        isOpen: ModalType.Download,
        data: {
          type: EPaywallType.download as EPaywallType,
        },
        functions: {},
      });
    }
    trackClipDownload(clip);
    window.open(downloadLink);
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{ gap: "8px" }}
      mt={4}
    >
      <Modal
        isModalOpen={isRemixModalOpen}
        setModalOpen={setRemixModalOpen}
        disableOutsideClose={undefined}
        handleModalClose={undefined}
      >
        <RemixModal
          clip={clip}
          closeModal={() => setRemixModalOpen(false)}
          onSuccess={undefined}
          onRemixError={() => setRemixModalOpen(false)}
        />
      </Modal>
      <Modal
        isModalOpen={isShareModalOpen}
        setModalOpen={setShareModalOpen}
        disableOutsideClose={undefined}
        handleModalClose={undefined}
      >
        <ShareModal clip={clip}></ShareModal>
      </Modal>
      <Modal
        isModalOpen={isReportModalOpen}
        setModalOpen={setReportModalOpen}
        disableOutsideClose={undefined}
        handleModalClose={undefined}
      >
        <FloatingContainer>
          <Report
            setModalOpen={setReportModalOpen}
            reportedClipId={clip._id}
            reportedUserId={clip.user._id}
          />
        </FloatingContainer>
      </Modal>
      <StyledClipReactions
        clipId={clip._id}
        clip={clip}
        setTotalReactions={setTotalReactions}
        mutateReaction={mutateReaction}
      />
      <CopyLink
        clip={clip}
        clipLink={shareLink}
        onClick={undefined}
        isMontage={isMontage}
        isProfile={false}
      ></CopyLink>
      <ClipShare
        title="Share Clip"
        clip={clip}
        onClick={() => {
          setShareModalOpen(true);
        }}
      ></ClipShare>
      <More
        clip={clip}
        setShareModalOpen={setShareModalOpen}
        setReportModalOpen={setReportModalOpen}
        onDownloadClick={handleDownloadClick}
      />
      <Remix
        clip={clip}
        classificiation={undefined}
        onClick={() => setRemixModalOpen(true)}
        classification={undefined}
        isFeed={false}
        disableOnClick={false}
      />
    </Flex>
  );
};

export { ShareOptions };
