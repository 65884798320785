import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { CreatorCardCategoryIcon } from "MVVM/Components/Icons/CreatorCardCategoryIcon";
import { analytics } from "MVVM/Analytics";
import { EmptyList } from "../EmptyList";
import { IEffect } from "MVVM/@types";

interface ICreatorCardsProps {
  effects: IEffect[];
}

const CreatorCards = ({ effects }: ICreatorCardsProps) => {
  const uniqueCards = effects.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => t.title === obj.title),
  );

  if (!uniqueCards.length) return <EmptyList text="No creator cards applied" />;

  return (
    <CreatorCardContainer>
      {uniqueCards.map((effect) => {
        return (
          <Link
            to={{ pathname: `/studio/${effect.internalId}` }}
            target="_blank"
            key={effect.id}
            style={{ color: "unset", textDecoration: "none" }}
            onClick={() =>
              analytics.track("Clip Page - Creator Card Click", {
                type: "Creator Card List",
              })
            }
          >
            <CreatorCardItem>
              <CreatorCardCategoryIcon
                category={effect.types[0]}
                style={{ borderRadius: "8px", padding: "8px" }}
              />
              <Flex flexDirection="column" justifyContent="center">
                <Text fontWeight={500}>{effect.title}</Text>
              </Flex>
            </CreatorCardItem>
          </Link>
        );
      })}
    </CreatorCardContainer>
  );
};

const CreatorCardItem = styled(Flex)<{ active?: boolean }>`
  color: reset;
  gap: 18px;
  padding: 12px;
  width: 100%;

  :hover {
    background: ${({ theme }) => theme.colors.navy};
    border-radius: 8px;
    cursor: pointer;
  }
`;

const CreatorCardContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.darkerNed};
  flex-direction: column;
  padding: 12px;
  width: 100%;
`;

export { CreatorCards };
