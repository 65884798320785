import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { useSetRecoilState } from "recoil";
import { useMutation } from "@apollo/client";

import { ModalType } from "MVVM/@types";
import { useUser } from "MVVM/Hooks/useUser";
import { SVG_MAP } from "utils";
import { AllstarModalState } from "MVVM/State/modals";
import { IClip } from "MVVM/@types";
import { MUTATE_REACTION } from "MVVM/GraphQL/addReaction";
import { useComments } from "./hooks/useComments";
import { Reaction } from "./Reaction";

interface IReaction {
  count: number;
  type: keyof typeof SVG_MAP;
}

interface IReactable {
  reactions?: IReaction[];
}

interface IClipReactionProps {
  clip: IClip & IReactable;
}

const emulateClipComments = (reactions: IReaction[] | undefined) => {
  if (!reactions) return [];

  return Object.keys(SVG_MAP).map((commentType: string) => {
    const reaction = reactions.find((r) => r.type === commentType);

    return {
      count: reaction?.count || 0,
      reaction: reaction?.type || commentType,
    };
  });
};

const ClipReactions = ({ clip }: IClipReactionProps) => {
  const { allstarUser } = useUser();
  const setAllstarModalState = useSetRecoilState(AllstarModalState);
  const { comments, optimisticReactionUpdate, topReaction } = useComments(
    clip.comments || emulateClipComments(clip.reactions),
  );
  const [mutateReaction] = useMutation(MUTATE_REACTION, {
    ignoreResults: true,
  });

  if (!comments.length) return <></>;

  const submitReaction = async (type: keyof typeof SVG_MAP) => {
    if (!allstarUser.loggedIn)
      return setAllstarModalState({
        isOpen: ModalType.Signup,
        data: {
          action: type,
          emoji: SVG_MAP[type],
        },
        functions: {},
      });

    mutateReaction({
      variables: {
        input: { clipId: clip.internalId || clip.shareId, reaction: type },
      },
    });

    optimisticReactionUpdate(type);
  };

  return (
    <Flex justifyContent="space-between">
      <StyledWrapper>
        <ShowReactionWrapper id="like-link-wrapper">
          <ReactionsContainer id="reactions">
            <ReactionsWrapper id="reactions-wrapper">
              {comments.map((comment) => (
                <ReactionItem className="reactions-item" key={comment.reaction}>
                  <Reaction onClick={submitReaction} comment={comment} />
                </ReactionItem>
              ))}
            </ReactionsWrapper>
          </ReactionsContainer>
          <ReactionLink id="like-link">
            <Reaction
              onClick={submitReaction}
              comment={topReaction}
              emptyDisplay="Like"
            />
          </ReactionLink>
        </ShowReactionWrapper>
      </StyledWrapper>
    </Flex>
  );
};

const ReactionsContainer = styled(Box)`
  background: ${(props) => props.theme.colors.darkerNavy};
  border-radius: 20px;
  display: inline-block;
  opacity: 0;
  padding: 5px 5px;
  position: absolute;
  right: -130px;
  top: -50px;
  transform: translate(0px, calc(-0% - 10px));
  visibility: hidden;

  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.08),
    0 2px 2px rgba(0, 0, 0, 0.15);
  transition:
    0.1s transform 0.1s,
    0.1s visibility 0.1s,
    0.1s opacity 0.1s;

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    right: -71px;
    top: -50px;
  }

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    right: -71px;
    top: -50px;
  }
`;

const ReactionsWrapper = styled(Box)`
  transform-origin: center;
  transform: translateY(3px);
  transition: 0.2s transform 0.2s cubic-bezier(0.06, 1.51, 0.73, 1.81);
`;

const ReactionItem = styled(Box)`
  border-right: 0px solid transparent;
  cursor: pointer;
  display: table-cell;
  min-width: 50px;
  position: relative;
  transform-origin: bottom center;

  svg {
    width: 40px;
    transform: scale(0.5);
    transition: 0.2s transform 0.2s;
  }

  &:last-child {
    border-right: 0;
  }
`;

const ReactionLink = styled(Box)`
  cursor: pointer;
  display: block;
  position: relative;
`;

const ShowReactionWrapper = styled(Box)`
  position: relative;
  right: 0px;
  top: 0px;

  &:hover {
    #reactions {
      visibility: visible;
      opacity: 1;
      transform: translate(-100px, 60%) translateX(-100px, -80%);
      transition:
        0.2s transform 0.5s,
        0.2s visibility 0.5s,
        0 opacity;
    }

    #reactions-wrapper {
      transform: translateY(0);
    }

    .reactions-item svg {
      transform: scale(1.2);
    }
  }
`;

const StyledWrapper = styled(Flex)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  width: 100%;
`;

export { ClipReactions };
