import { useState } from "react";
import Lottie from "lottie-react";
import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { MdPhoneAndroid } from "react-icons/md";
import { RiComputerLine } from "react-icons/ri";

import { Body, Headline, Title3 } from "MVVM/Components/Text";
import { FloatingContainer } from "MVVM/Components/Wrappers";
import { ShareButton } from "MVVM/Components/Button/Share";
import { CLIP_REQUEST_PORTRAIT } from "MVVM/GraphQL/clipRequestPortrait";
import { IClip } from "MVVM/@types";
import { trackClipDownload } from "utils";
import { useUser } from "MVVM/Hooks/useUser";
import crop from "../../../../animations/crop.json";

interface IClipDownloadProps {
  clip: IClip;
}

const ClipDownload = ({ clip }: IClipDownloadProps) => {
  const { allstarUser } = useUser();
  const [requestPortrait, { error }] = useMutation(CLIP_REQUEST_PORTRAIT);
  const [mobileRequested, setMobileRequested] = useState(false);
  const isUsersClip = clip.user._id === allstarUser.user._id;
  const ineligible = !clip.hasPortrait && !clip.canRequestPortrait;

  const downloadClip = () => {
    window.open(clip.url, "_blank");
    trackClipDownload(clip, false, {});
  };

  const downloadMobileClip = () => {
    if (clip.portraitUrl) {
      window.open(clip.portraitUrl, "_blank");
      trackClipDownload(clip, "9:16", {});
    } else {
      requestPortrait({ variables: { input: { clipId: clip.id } } });
      setMobileRequested(true);
    }
  };

  if (error)
    return (
      <FloatingContainer style={{ maxWidth: "575px" }}>
        Something went wrong
      </FloatingContainer>
    );

  return (
    <FloatingContainer style={{ maxWidth: "575px" }}>
      <>
        <Flex
          mb={4}
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Lottie animationData={crop} style={{ height: 80 }} loop={true} />
          <Flex mt={2} flexDirection="column">
            <Title3 my={2} textAlign="center" mb={2}>
              Get a Mobile version of your clip!
            </Title3>
            <Body mb={3} textAlign="center" color="chalk">
              All your favorite HUD elements, your kill feed and creator cards
              automatically repositioned, perfect for TikTok and YouTube.
            </Body>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Flex
            flexDirection={["column", "row"]}
            justifyContent="center"
            alignItems="flex-end"
            style={{ gap: 40 }}
          >
            <Flex
              mb={[5, 5, 0, 0]}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <StyledContainer
                alignItems="center"
                justifyContent="flex-end"
                flexDirection="column"
              >
                <Flex
                  alignItems="center"
                  flexDirection={["row", "row", "column", "column"]}
                >
                  <StyledMobile size={24}></StyledMobile>
                  <Headline mx={[3, 3, 0, 0]} my={[0, 0, 3, 3]}>
                    Mobile Optimized
                  </Headline>
                  <Body color="chalk">9:16</Body>
                </Flex>
                {(!clip.hasPortrait && !isUsersClip) || ineligible ? (
                  <DisabledButton text="Unavailable" />
                ) : mobileRequested ||
                  (clip.hasPortrait && !clip.portraitUrl) ? (
                  <DisabledButton text="Processing..." />
                ) : (
                  <DownloadButton
                    text={
                      clip.hasPortrait ? "Download Mobile" : "Request Mobile"
                    }
                    onClick={downloadMobileClip}
                  />
                )}
              </StyledContainer>
            </Flex>
            <Flex
              mb={[5, 5, 0, 0]}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <StyledContainer
                alignItems="center"
                justifyContent="flex-end"
                flexDirection="column"
              >
                <Flex
                  alignItems="center"
                  flexDirection={["row", "row", "column", "column"]}
                >
                  <StyledComputer size={24}></StyledComputer>
                  <Headline mx={[3, 3, 0, 0]} my={[0, 0, 3, 3]}>
                    Cinema Style
                  </Headline>
                  <Body color="chalk">16:9</Body>
                </Flex>
                <DownloadButton onClick={downloadClip} text="Download Now" />
              </StyledContainer>
            </Flex>
          </Flex>
        </Flex>
      </>
    </FloatingContainer>
  );
};

const DisabledButton = ({ text }: { text: string }) => (
  <ShareButton
    my={4}
    justifyContent="space-between"
    classification="secondary"
    alignItems="center"
    flex
    disabled
    style={{ cursor: "not-allowed" }}
  >
    <Flex alignItems="center">
      <Text variant="text.paragraphSmall" fontWeight={500}>
        {text}
      </Text>
    </Flex>
  </ShareButton>
);

const DownloadButton = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) => (
  <ShareButton
    my={4}
    justifyContent="space-between"
    classification="main"
    alignItems="center"
    flex
    onClick={onClick}
  >
    <Flex alignItems="center">
      <Text variant="text.paragraphSmall" fontWeight={500}>
        {text}
      </Text>
    </Flex>
  </ShareButton>
);

const StyledContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.ned};
  border-radius: 10px;
  height: 175px;
  padding: 10px;
  width: 200px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 120px;
    width: 290px;
  }
`;

const StyledMobile = styled(MdPhoneAndroid)`
  color: ${(props) => props.theme.colors.envy};
  position: relative;
`;

const StyledComputer = styled(RiComputerLine)`
  position: relative;
  color: ${(props) => props.theme.colors.envy};
`;

export { ClipDownload };
