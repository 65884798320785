import { ReactNode, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

import { analytics } from "MVVM/Analytics";

interface ITab {
  component: ReactNode;
  title: string;
  type: string;
}

interface ITabsProps {
  tabs: ITab[];
}

const Tabs = ({ tabs }: ITabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Flex height="100%" flexDirection="column">
      <Flex>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.title}
            active={activeTab === index}
            onClick={() => {
              setActiveTab(index);
              analytics.track("Tab Clicked", { type: tab.type });
            }}
            flex="1 1 0"
          >
            <Text variant="text.labelSmall">{tab.title}</Text>
          </Tab>
        ))}
      </Flex>
      <Flex flexGrow="1">
        {tabs.map((tab, index) => (
          <TabContent key={index} active={activeTab === index}>
            {tab.component}
          </TabContent>
        ))}
      </Flex>
    </Flex>
  );
};

const TabContent = styled(Box)<{ active: boolean }>`
  display: ${(props) => (props.active ? "block" : "none")};
  width: 100%;
`;

const Tab = styled(Flex)<{ active: boolean }>`
  border-bottom: ${(props) =>
    props.active
      ? `2px solid ${props.theme.colors.envy}`
      : `2px solid ${props.theme.colors.navy}`};
  color: ${(props) =>
    props.active ? props.theme.colors.envy : props.theme.colors.chalk};
  cursor: pointer;
  font-weight: 500;
  justify-content: center;
  padding: 0 0 12px 0;
`;

export { Tabs };
