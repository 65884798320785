import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { BiInfoCircle } from "react-icons/bi";

import { Button } from "../Button";

type TUserNotificationCTAProps = {
  title: string;
  subtitle: string;
  buttonCopy: string;
  onClick: () => void;
};

export function UserNotificationCTA({
  title,
  subtitle,
  buttonCopy,
  onClick,
}: TUserNotificationCTAProps) {
  return (
    <Wrapper>
      <HeaderContainer>
        <Flex
          flexDirection={"row"}
          alignItems="center"
          justifyContent="start"
          style={{ gap: 8 }}
          width={"100%"}
        >
          <StyledInfoCircle size={32} />
          <Text variant="text.headingSmall" my={3}>
            {title}
          </Text>
        </Flex>
        <Text variant="text.paragraphMedium" color="ice" textAlign="left">
          {subtitle}
        </Text>
      </HeaderContainer>

      <ButtonWrapper>
        <ButtonContainer>
          <Button
            onClick={() => {
              onClick();
            }}
            flex={true}
            classification={"roundedFilled"}
            size={"small"}
          >
            {buttonCopy}
          </Button>
        </ButtonContainer>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  max-width: 500px;
  background-color: ${(props) => {
    return props.theme.colors.transparent;
  }};
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    max-width: 100%;
    height: 100%;
    justify-content: space-between;
  }
`;

const HeaderContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px 10px 0 0;
  gap: 8px;
  backdrop-filter: blur(2px);
  padding: 24px;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    border-radius: 0px;
    height: 100%;
  }
`;

const ButtonWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const ButtonContainer = styled(Flex)`
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 24px;
  border-radius: 0 0 10px 10px;
  width: 100%;
  background-color: black;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    border-radius: 0px;
  }
`;

const StyledInfoCircle = styled(BiInfoCircle)`
  color: ${({ theme }) => theme.colors.error};
`;
