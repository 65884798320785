import { RecoilState, atom } from "recoil";
import { TPinnedClip } from "../Components/ClipFeed/@types";
import { Clip } from "MVVM/Views/Profile/Clips";

type TPinnedClipWithError = {
  clipId: string;
  errorMessage: string;
};

export const PinnedClipWithError: RecoilState<TPinnedClipWithError> = atom({
  key: "PinnedClipWithError_V0.1",
  default: {
    clipId: "",
    errorMessage: "",
  },
});

export const PinnedClipsAtom: RecoilState<Clip[]> = atom({
  key: "PinnedClip_V0.1",
  default: [] as Clip[],
});
