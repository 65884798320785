import { CSSProperties } from "react";
import { Flex } from "rebass/styled-components";
import styled from "styled-components";

import theme from "theme/themeThree";
import { ReactComponent as HashtagSVG } from "../../../../assets/images/studio/tags/hashtag.svg";
import { ReactComponent as Cs2SVG } from "../../../Assets/GameLogos/cs2.svg";
import { ReactComponent as CsgoSVG } from "../../../Assets/GameLogos/csgo.svg";
import { ReactComponent as DotaSVG } from "../../../Assets/GameLogos/dota.svg";
import { ReactComponent as LeagueSVG } from "../../../Assets/GameLogos/league.svg";
import { ReactComponent as FortniteSVG } from "../../../Assets/GameLogos/fortnite.svg";
import {
  BiIntersect,
  BiLogInCircle,
  BiLogOutCircle,
  BiMessageError,
  BiSolidBolt,
  BiSolidImage,
  BiSolidMagicWand,
  BiSolidMicrophone,
} from "react-icons/bi";
import { PiMusicNotesFill } from "react-icons/pi";

interface ICreatorCardCategories {
  category?: string;
  style?: CSSProperties;
}

const StudioIcon = ({ category }: { category: string }) => {
  switch (category) {
    case "Audio":
      return <BiSolidMicrophone data-testid="audio" />;
    case "Overlays":
      return <BiIntersect />;
    case "Music":
      return <PiMusicNotesFill />;
    case "Memes":
      return <BiMessageError />;
    case "Outros":
      return <BiLogOutCircle />;
    case "Intros":
      return <BiLogInCircle />;
    case "Transitions":
      return <BiSolidBolt />;
    case "Thumbnails":
      return <BiSolidImage />;
    case "Special FX":
      return <BiSolidMagicWand />;
    case "Hashtag":
      return <HashtagSVG />;
    case "Cs2":
      return <Cs2SVG />;
    case "Csgo":
      return <CsgoSVG />;
    case "Dota":
      return <DotaSVG />;
    case "League":
      return <LeagueSVG />;
    case "Fortnite":
      return <FortniteSVG />;
    default:
      return <BiMessageError data-testid="default" />;
  }
};

const mapToLegacyCategory = (category: string): string => {
  switch (category) {
    case "AUDIO_FX":
      return "Audio";
    case "OVERLAY":
      return "Overlays";
    case "MUSIC":
      return "Music";
    case "SPECIAL_FX":
      return "Special FX";
    case "TRANSITION":
      return "Transitions";
    case "INTRO":
      return "Intros";
    case "THUMBNAIL":
      return "Thumbnails";
    case "OUTRO":
      return "Outros";
    case "MEME":
      return "Memes";
    default:
      return category;
  }
};

const CreatorCardCategoryIcon = ({
  category,
  style,
}: ICreatorCardCategories) => {
  if (!category) return <></>;

  const legacyCategory = mapToLegacyCategory(category);

  return (
    <IconContainer
      style={style}
      backgroundColor={
        theme.colors[
          legacyCategory
            .replace(" ", "")
            .toLowerCase() as keyof typeof theme.colors
        ] || "envy"
      }
      data-testid="icon-container"
    >
      <StudioIcon category={legacyCategory} />
    </IconContainer>
  );
};

const IconContainer = styled(Flex)`
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
  }

  svg path {
    fill: ${({ theme }) => theme.colors.ice};
  }
`;

export { CreatorCardCategoryIcon };
