import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import theme from "../../../../theme/themeThree";
import styled from "styled-components";
import { FaEdit } from "react-icons/fa";
import { useEffect, useState } from "react";

import { LoadingSpinner } from "../../Lottie";
import { Button } from "../../Button";

type Variant = keyof typeof theme.text;
const { REACT_APP_S3_CLIP_BUCKET } = process.env;
export default function EditableText({
  variant,
  defaultValue,
  onSave,
}: {
  variant: Variant;
  defaultValue: string;
  onSave: (value: string) => any;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const variantStyles = theme.text[variant];

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <TextWrapper>
      <StyledEditText
        name="editable-text"
        defaultValue={defaultValue}
        inputClassName="edit"
        className="view"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        editButtonContent={
          <>
            {!loading && !error && <EditButton />}
            {!loading && error && (
              <ErrorMessage
                color="error"
                variant={"text.labelSmall"}
                width="100px"
              >
                Invalid title!
              </ErrorMessage>
            )}
            {loading && <LoadingSpinner height={20} width={20} />}
          </>
        }
        onSave={async ({ value }) => {
          setLoading(true);
          const response = await onSave(value);

          if (!response) {
            setError(true);
            setValue(defaultValue);
          } else {
            window.rudderanalytics.track("Edit Clip", {
              collection: "clip-modal",
            });
          }
          setLoading(false);
        }}
        editButtonProps={{
          id: "react-edit-text-button",
          style: {
            marginLeft: "5px",
            cursor: "pointer",
            background: "unset",
            color: theme.colors.ice,
            position: "relative",
            pointerEvents: error ? "none" : "all",
            minWidth: 50,
          },
        }}
        style={{
          ...variantStyles,
        }}
        showEditButton
      />
    </TextWrapper>
  );
}

const EditButton = () => {
  return (
    <StyledButton
      width="100%"
      display="flex"
      classification="secondaryRounded"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      position="relative"
    >
      <StyledEdit size={15} color="chalk" />
      <StyledNew src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/ctas/new.svg`} />
    </StyledButton>
  );
};
const StyledNew = styled(Image)`
  position: absolute;
  right: -5px;
  top: 0px;
`;
const StyledButton = styled(Button)`
  &:hover {
    background: ${({ theme }) => theme.colors.envy};
  }
`;

const ErrorMessage = styled(Text)`
  position: absolute;
  top: 16px;
  left: 0px;
  width: 75px;
`;
const StyledEdit = styled(FaEdit)`
  color: ${({ theme }) => theme.colors.ice};
`;
const StyledEditText = styled(EditText)``;
const TextWrapper = styled(Flex)`
  position: relative;

  div {
    max-width: 100%;
  }

  .edit {
    color: ${({ theme }) => theme.colors.ice};
    background: ${({ theme }) => theme.colors.ned};
    background-color: ${({ theme }) => theme.colors.ned};
    border: 2px solid ${({ theme }) => theme.colors.envy};
    border-radius: 4px;
    outline: none;
  }
  .view {
    background: unset;
  }
`;
