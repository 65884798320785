import { useMemo } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import { FollowButton } from "../../../Components/Button";
import {
  AvatarWrapper,
  getAvatarBorderLevel,
  getAvatarImage,
} from "../../../Utilities/User";
import { Avatar } from "../../../Components/Avatar";
import { ISuggestedFollower } from "../@types";
import { useMutateFollowers } from "../../../Hooks/useMutateFollowers";
import { TMutateFollowers } from "../../../Hooks/@types";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { SuggestedFollowing } from "MVVM/State/suggestedFollowing";
import { randomAvatar } from "MVVM/Utilities/Images";
import { useUser } from "MVVM/Hooks/useUser";

interface ISuggestedItemProps {
  user: ISuggestedFollower;
}

const SuggestedItem = ({ user }: ISuggestedItemProps) => {
  const { allstarUser } = useUser();
  const avatarBorderLevel = getAvatarBorderLevel(user);
  const [suggestedFollowing, setSuggestedFollowing] =
    useRecoilState(SuggestedFollowing);
  const history = useHistory();
  const { mutateFollowers } = useMutateFollowers();
  const avatar = getAvatarImage(user) || useMemo(randomAvatar, []);

  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const followUnfollow = (data: TMutateFollowers) => {
    setSuggestedFollowing({
      ids: [...suggestedFollowing.ids, data?.variables?.userToFollow],
    });

    return mutateFollowers(data);
  };
  const setFollowModal = ({
    displayName,
    avatar,
  }: {
    displayName: string;
    avatar: string;
  }) => {
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.Signup,
      data: {
        target: displayName,
        avatar: avatar,
        action: "follow",
      },
    });
  };

  return (
    <SuggestedItemWrapper>
      <SuggestedItemContainer>
        <AvatarClickable
          onClick={() => {
            history.push(`/u/${user.username}`);
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <AvatarWrapper size="large" borderLevel={avatarBorderLevel}>
            <Avatar image={avatar} size="large" />
          </AvatarWrapper>
        </AvatarClickable>
        <Text
          fontWeight={700}
          fontSize="4"
          onClick={() => {
            if (!user?.username) return;
            history.push(`/u/${user.username}`);
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {user.username ?? "User"}
        </Text>
        <FollowButton
          size="tiny"
          flex={true}
          openModal={true}
          isModal={false}
          followUnfollow={followUnfollow}
          setFollowModal={setFollowModal}
          userId={user._id}
          allstarUser={allstarUser}
          displayName={user.username}
          avatar={user.avatar}
          trackingInfo={{
            avatarPresent: !!user.avatar,
            discordPresent: !!user?.discord?.id,
            followingCount: allstarUser?.user?.followingCount,
            reason: user.reason,
          }}
        />
      </SuggestedItemContainer>
    </SuggestedItemWrapper>
  );
};

const AvatarClickable = styled(Box)`
  cursor: pointer;
`;

const SuggestedItemWrapper = styled(Box)`
  max-width: 220px;
  width: 220px;
`;

const SuggestedItemContainer = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.navy};
  border-radius: 12px;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 12px 24px 24px;
  width: auto;
`;

export { SuggestedItem };
