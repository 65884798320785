import { useEffect } from "react";
import { useStatsigClient, useStatsigUser } from "@statsig/react-bindings";

import { usePortal } from "MVVM/Hooks/usePortal";
import { LoginModal } from "MVVM/Views/Profile/Components/LogInModal";

const isModalCookieSet = () =>
  document.cookie
    .split("; ")
    .some((cookie) => cookie.startsWith("login_modal_shown="));

export const useLoginModal = (allstarUser: any, delay = 5000) => {
  const { portalOpen, setPortalOpen, Portal } = usePortal(<LoginModal />);

  const { getExperiment } = useStatsigClient();
  const { user: statsigUser } = useStatsigUser();
  let renderLoginModal = false;

  if (!statsigUser?.userID) {
    const experiment = getExperiment("login_upsell_on_profile");
    const isInExperiment = !!experiment.get("login_upsell");
    renderLoginModal = isInExperiment;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        (Object.keys(allstarUser).length === 0 || !allstarUser.loggedIn) &&
        !isModalCookieSet()
      ) {
        document.cookie = `login_modal_shown=true; max-age=${
          12 * 60 * 60
        }; path=/`;
        setPortalOpen(true);
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [allstarUser, delay, setPortalOpen]);

  return { portalOpen, setPortalOpen, renderLoginModal, Portal };
};
