//Attempted action component that takes in an action and call to action type
//displays a column that consists of 2 rows.
//the first row is a title and a body in column format
//the second row is 3 columns, with a title, and 3 items in a vertical list below it
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Button } from "../../../../Components/Button";
import { Body, Title3 } from "../../../../Components/Text";
import { useRecoilState } from "recoil";
import { PartnerViewSession } from "../../../../State";
import { ThirdPartySettingsModal } from "../ThirdPartySettingsModal";

export const AttemptedAction = ({
  type,
  overlayConfig,
  translateHook,
  setAttemptedAction,
}: any) => {
  return type === "thirdPartySettings" ? 
    <ThirdPartySettingsModal 
      type={type} 
      overlayConfig={overlayConfig}
      translateHook={translateHook}
      setAttemptedAction={setAttemptedAction}
    /> : 
    <AttemptedActionModal 
      type={type} 
      overlayConfig={overlayConfig}
      translateHook={translateHook}
      setAttemptedAction={setAttemptedAction}
    />;
};

const AttemptedActionModal = ({
  type,
  overlayConfig,
  translateHook,
  setAttemptedAction,
}: any) => {
  const actionPhrase = !type
    ? "partnerOverlay.editTitle.actionPhrase"
    : overlayConfig?.attemptedAction[type]?.actionPhrase;
  const [partnerViewSession] = useRecoilState(PartnerViewSession);
  const getButtonText = () => {
    if (!type || type === "info")
      return translateHook("partnerOverlay.info.button");
    return translateHook(overlayConfig?.attemptedAction[type]?.button);
  };
  const getTitleText = () => {
    if (!type || type === "info")
      return translateHook("partnerOverlay.info.title", {
        action: translateHook(actionPhrase),
      });

    return translateHook("partnerOverlay.title", {
      action: translateHook(actionPhrase),
    });
  };

  return (
    <>
      <ModalPosition
        display={!!type}
        onClick={(e: any) => {
          setAttemptedAction("");
        }}
      >
        {" "}
        <AttemptedActionFlex
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {" "}
          <Flex
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            {" "}
            <AttemptedActionTitle>{getTitleText()} </AttemptedActionTitle>
            <AttemptedActionBody>
              {" "}
              {translateHook("partnerOverlay.body", {
                partner: overlayConfig?.partner?.name,
              })}
            </AttemptedActionBody>
          </Flex>
          <HideAt600>
            {" "}
            <Row
              style={{
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Column number={1}>
                <SubHeading>
                  {translateHook("floatingSalesPitch.CloudStorage")}
                </SubHeading>
                <Text dot>
                  {translateHook("floatingSalesPitch.QualityClips")}
                </Text>
                <Text dot>
                  {translateHook("floatingSalesPitch.FreeCloudStorage")}
                </Text>
                <Text dot>
                  {translateHook("floatingSalesPitch.DownloadClips")}
                </Text>
              </Column>
              <Column number={2}>
                <SubHeading>
                  {translateHook("floatingSalesPitch.EasySharing")}
                </SubHeading>
                <Text dot>
                  {translateHook("floatingSalesPitch.DiscordBot")}
                </Text>
                <Text dot>
                  {translateHook("floatingSalesPitch.TikTokIntegration")}
                </Text>
                <Text dot>
                  {translateHook("floatingSalesPitch.YouTubeIntegration")}
                </Text>
              </Column>
              <Column number={3}>
                <SubHeading>
                  {translateHook("floatingSalesPitch.AutomatedEdits")}
                </SubHeading>
                <Text dot>
                  {translateHook("floatingSalesPitch.CustomMusic")}
                </Text>
                <Text dot>
                  {translateHook("floatingSalesPitch.VerticalMobileClips")}
                </Text>
                <Text dot>
                  {translateHook("floatingSalesPitch.3DCameraFX")}
                </Text>
              </Column>
            </Row>
          </HideAt600>
          <Row
            style={{
              width: "100%",
            }}
          >
            <NotNowWrapper>
              <StyledBody
                style={{ alignSelf: "center" }}
                ml={2}
                onClick={() => {
                  setAttemptedAction("");
                }}
              >
                Not now
              </StyledBody>
            </NotNowWrapper>

            <StyledLinkButton
              classification="roundedFilled"
              size="tiny"
              flex={true}
              onClick={() => {
                try {
                  window.rudderanalytics.track(
                    "Overlay - Attempted Action Button Click",
                    {
                      knownUser: overlayConfig?.knownUser,
                      shareId: overlayConfig?.shareId,
                      partner: overlayConfig?.partner?.name,
                      useCase: overlayConfig?.partner?.useCase,
                      type: type,
                    },
                  );
                } catch (e) {}

                window.open(partnerViewSession?.dynamicCta?.link, "_blank");
              }}
            >
              {getButtonText()}
            </StyledLinkButton>
          </Row>
        </AttemptedActionFlex>
      </ModalPosition>
    </>
  );
};

const HideAt600 = styled(Box)`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const NotNowWrapper = styled(Flex)`
  justify-content: flex-start;
  margin-right: 25px;
`;

export const StyledLinkButton = styled(Button)`
  pointer-events: auto;
`;

const StyledBody = styled(Body)`
  cursor: pointer;
`;
const AttemptedActionTitle = styled(Title3)`
  font-weight: 700;
  text-align: center;
  padding-bottom: 5px;
  @media (max-width: 650px) {
    font-size: 1.1em;
  }
`;

const AttemptedActionBody = styled(Body)`
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.chalk};
  @media (max-width: 650px) {
    padding-top: 10px;
    font-size: 0.9em;
  }
`;

const AttemptedActionFlex = styled(Flex)`
  flex-direction: column;
  width: 50%;
  height: 50%;
  background-color: #000000;
  border-radius: 10px;

  box-sizing: border-box;
  color: #ffffff;
  position: absolute;
  pointer-events: auto;
  margin: auto;
  padding: 50px;
  justify-content: space-between;

  @media (min-width: 1900px) {
    width: 40%;
    height: 40%;
  }
  @media (max-width: 1900px) {
    width: 60%;
    height: 60%;
  }
  @media (max-width: 1400px) {
    width: 60%;
    height: 60%;
  }

  @media (max-width: 1200px) {
    width: 70%;
    height: 70%;
  }
  @media (max-width: 1000px) {
    width: 80%;
    height: 80%;
    padding-right: 30px;
    padding-left: 30px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media (max-width: 800px) {
    justify-content: space-evenly;
  }
`;

export const ModalPosition = styled<any>(Flex)`
  ${(props) => {
    if (props.display) {
      return `
      display: flex;
      `;
    } else {
      return `
      display: none;
      `;
    }
  }}
  pointer-events: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Row = styled(Flex)`
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  // padding: 20px;
`;

const Column = styled<any>(Flex)`
  flex-direction: column;
  align-items: center;
`;

const SubHeading = styled.h3`
  all: unset;
  ont-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0016em;
  color: ${(props) => props.theme.colors.ice};
  margin-top: 20px;
  margin-bottom: 7.65px;
  @media (max-width: 575px) {
    font-size: 10px;
  }
`;

const Text = styled.p<any>`
  all: unset;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0016em;
  color: ${(props) => props.theme.colors.chalk};
  @media (max-width: 575px) {
    font-size: 10px;
  }
  ${(props) =>
    props.dot
      ? ` &:before {
    content: "•  ";
  }`
      : ""}
`;
