import { lazy, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Activation from "../pages/activation/index";
import CreateOrLogin from "../pages/interstitial/createOrLogin";
import CreateOrLoginTikTok from "../pages/interstitial/createOrLoginTikTok";
import Home from "../pages/home/index";
import StudioMarketingPage from "../pages/studioMarketing/index";
import Verify from "../pages/verify/index";

import Edit from "../MVVM/Components/EditClipOrMontage";
import TikTokEmail from "../MVVM/Views/Authentication/Components/TikTokEmail";
import { getChunkable } from "./utils";
import { URLS } from "../MVVM/Utilities/Maps";
import { useGetUser } from "../userStore";
import Error404 from "../MVVM/Views/404";
import Homepage from "../MVVM/Views/Homepage";

import { redirects } from "./redirects";
import { externalLinks } from "./externalLinks";
import { authenticationPages } from "./authenticationPages";
import { brandPages } from "./brandPages";
import { mobileWebviews } from "./mobileWebviews";
import { staticPages } from "./staticPages";
import { useGateValue } from "@statsig/react-bindings";
import { getQueryParam } from "MVVM/Utilities/QueryParam";

const LazyClipFullPage = lazy(() => import("../MVVM/Views/ClipFull"));
const LazyClipPage = lazy(() => import("../MVVM/Views/Clip"));
const LazyCreateMontagePage = lazy(() => import("../MVVM/Views/CreateMontage"));
const LazyFeatureMontage = lazy(() => import("../MVVM/Views/FeatureMontage"));
const LazyMatchHistory = lazy(() => import("../MVVM/Views/MatchHistory"));
const LazyPricing = lazy(() => import("../MVVM/Views/Pricing"));
const LazyProfile = lazy(() => import("../MVVM/Views/Profile"));
const LazySetup = lazy(() => import("../MVVM/Views/Setup"));
const LazyStudioPage = lazy(() => import("../MVVM/Views/Studio"));
const LazyAddShareCodes = lazy(() => import("../MVVM/Views/AddShareCodes"));
const LazyAccount = lazy(() => import("../pages/profileSettings"));
const LazyPaymentValidation = lazy(() =>
  import("../MVVM/Views/PaymentValidation"),
);
const LazyCampaignCompetitions = lazy(() =>
  import("../MVVM/Views/Competitions/Views/[slug]"),
);
const LazyCampaignPlayCompetitions = lazy(() =>
  import("../MVVM/Views/Competitions/Views/Play"),
);
const LazyCampaignWinnersCompetitions = lazy(() =>
  import("../MVVM/Views/Competitions/Views/Winners"),
);
const LazyCompetitions = lazy(() => import("../MVVM/Views/Competitions"));
const LazyIframeLeaderboard = lazy(() =>
  import("../MVVM/Views/Competitions/Views/iframe/Leaderboard"),
);

const PrivateRoute = ({ bypassAuth, ...props }) => {
  const user = useGetUser();
  if (!user && !bypassAuth?.()) {
    return <Redirect to={`/login?next=${window.location.pathname}`} />;
  }
  return <Route {...props} />;
};

const AppRoutes = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const showModal = () => setModalOpen(!isModalOpen);
  const clipExperienceEnabled = useGateValue("new_clip_page");

  const clipId = getQueryParam("clip") || getQueryParam("montage");

  return (
    <Switch>
      {authenticationPages}
      {externalLinks}
      {mobileWebviews}
      {staticPages}
      {brandPages}
      <Route exact path="/" component={Homepage} />
      <Route
        path="/wrapped/2024/:user"
        render={() => <Homepage wrappedPeriod="2024" />}
      />
      <Route
        exact
        path="/howitworks"
        render={(props) => <Home {...props} showModal={showModal} />}
      />
      <Route exact path="/u/:user" render={getChunkable(LazyProfile)} />
      <Route exact path="/profile" render={getChunkable(LazyProfile)} />{" "}
      <Route
        path={"/match-history"}
        exact
        component={getChunkable(LazyMatchHistory)}
      />
      {/*
      <Route
        path={"/match-history/:page"}
        exact
        component={getChunkable(LazyMatchHistory)}
      /> */}
      <Route
        path={"/remix/:shareid"}
        render={getChunkable(LazyStudioPage, { route: "remix" })}
      />
      <Route
        path={"/studio/:cardId"}
        render={getChunkable(LazyStudioPage, { route: "studio" })}
      />
      <Route
        path={"/studio"}
        component={getChunkable(LazyStudioPage, {
          route: "studio",
        })}
      />
      <Route exact path="/activation/download-app" component={Activation} />
      <Route exact path="/activation/verify" component={Activation} />
      <Route exact path="/verify" component={Verify} />
      <Route
        exact
        path="/activation/connect-discord"
        render={(props) => <Activation {...props} step={2} />}
      />
      <Route
        exact
        path="/clip/:id"
        component={getChunkable(LazyClipFullPage)}
      />
      {clipExperienceEnabled ? (
        <Route key="clipRedirect" path="/clip">
          {({ location }) => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete("clip");

            const redirectPath = `clip/${clipId}${
              searchParams ? `?${searchParams.toString()}` : ""
            }`;

            return <Redirect to={redirectPath} />;
          }}
        </Route>
      ) : (
        <Route exact path="/clip" component={getChunkable(LazyClipPage)} />
      )}
      {clipExperienceEnabled ? (
        <Route key="montageRedirect" path="/montage">
          <Redirect to={`clip/${clipId}`} />
        </Route>
      ) : (
        <Route
          exact
          path="/montage"
          render={getChunkable(LazyClipPage, {
            route: "montage",
            isMontage: true,
          })}
        />
      )}
      <Route path="/edit/:shareId" component={Edit}></Route>
      <Route
        exact
        path="/montages/create"
        render={getChunkable(LazyCreateMontagePage)}
      />
      <Route exact path="/interstitial" component={CreateOrLogin} />
      <Route exact path="/interstitialtiktok" component={CreateOrLoginTikTok} />
      <Route exact path="/finishsignup" component={TikTokEmail} />
      <Route
        exact
        path="/feature/allstarstudio"
        component={StudioMarketingPage}
      />
      <Route
        exact
        path="/feature/montages"
        component={getChunkable(LazyFeatureMontage)}
      />
      <Route exact path={URLS.UPGRADE} component={getChunkable(LazyPricing)} />
      <Route path="/setup" render={getChunkable(LazySetup)} />
      <Route
        exact
        path="/competitions"
        render={getChunkable(LazyCompetitions)}
      />
      <Route
        exact
        path="/competitions/:campaign"
        render={getChunkable(LazyCampaignCompetitions)}
      />
      <Route
        exact
        path="/competitions/:campaign/play"
        render={getChunkable(LazyCampaignPlayCompetitions)}
      />
      <Route
        exact
        path="/competitions/:campaign/winners"
        render={getChunkable(LazyCampaignWinnersCompetitions)}
      />
      <Route
        exact
        path="/iframe/competitions/:campaign"
        render={getChunkable(LazyIframeLeaderboard)}
      />
      <PrivateRoute
        exact
        path="/notifications"
        component={getChunkable(LazyAccount, {
          showModal,
          view: "notifications",
        })}
      />
      <PrivateRoute
        exact
        path="/title-preferences"
        component={getChunkable(LazyAccount, {
          showModal,
          view: "titlePreferences",
        })}
      />
      <PrivateRoute
        exact
        path="/third-party-settings"
        component={getChunkable(LazyAccount, {
          showModal,
          view: "thirdPartySettings",
        })}
      />
      <PrivateRoute
        exact
        path="/dashboard"
        component={getChunkable(LazyAccount, {
          showModal,
          view: "dashboard",
        })}
      />
      <PrivateRoute
        exact
        path="/accountsettings"
        component={getChunkable(LazyAccount, {
          showModal,
          view: "accountSettings",
        })}
      />
      <PrivateRoute
        exact
        path="/connectedaccounts"
        component={getChunkable(LazyAccount, {
          showModal,
          view: "connectedAccounts",
        })}
      />
      <PrivateRoute
        exact
        path="/subscriptions"
        component={getChunkable(LazyAccount, {
          showModal,
          view: "subscriptions",
        })}
      />
      <PrivateRoute
        path={"/payment/validation"}
        exact
        component={getChunkable(LazyPaymentValidation)}
      />
      <PrivateRoute
        path={"/add-share-codes"}
        exact
        component={getChunkable(LazyAddShareCodes)}
      />
      {redirects}
      <Route exact path="/404" component={Error404} />
      <Route path="*" component={Error404} />
    </Switch>
  );
};

export { AppRoutes };
