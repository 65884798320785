import { Box, Flex, Image, Text } from "rebass/styled-components";
import styled from "styled-components";
import { Button } from "MVVM/Components/Button";
import AllstarIcon from "../../../../../assets/images/brand/Wordmark-Light-Tag.png";
import { IoMdCheckmark } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { URLS } from "MVVM/Utilities/Maps";

export const LoginModal = () => {
  const history = useHistory();

  return (
    <StyledBox>
      <Flex
        flexDirection="column"
        padding={7}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ gap: 20 }}
        maxWidth={400}
      >
        <StyledAllstarIcon src={AllstarIcon} />
        <Text variant="text.headingMedium" textAlign={"center"} mb={3}>
          Login and start clipping those nasty flicks, immediately.
        </Text>
        <Row>
          <StyledCheckmark color="envy" />
          <Text variant={"text.paragraphMedium"}>
            Post your sickest shots in minutes and brag to your friends.
          </Text>
        </Row>
        <Row>
          <StyledCheckmark color="envy" />
          <Text variant={"text.paragraphMedium"}>
            Create highlight reels from your best matches. Finally, your own personal fragmovie editor!
          </Text>
        </Row>
        <Row>
          <StyledCheckmark color="envy" />
          <Text variant={"text.paragraphMedium"}>
            Follow your friends and create montages of your squad's biggest W...or L. No one's judging.
          </Text>
        </Row>

        <Button
          mt={3}
          onClick={() => {
            const nextLink = window.location.search ? `${window.location.pathname}${window.location.search}` : window.location.pathname;
            history.push(`${URLS.LOGIN}?next=${nextLink}`);
          }}
        >
          Login
        </Button>

        <Flex style={{ gap: 10 }}>
          <Text variant={"text.labelMedium"}>Don't have an account?</Text>
          <LoginText
            variant={"text.labelMedium"}
            color="envy"
            mr={4}
            onClick={() => {
              window.rudderanalytics.track("Profile - Login Modal Sign Up");
              history.push(URLS.SIGNUP);
            }}
          >
            Create one
          </LoginText>
        </Flex>
      </Flex>
    </StyledBox>
  );
};

const LoginText = styled(Text)`
  cursor: pointer;
`;

const StyledCheckmark = styled(IoMdCheckmark)`
  color: ${({ theme }) => theme.colors.envy};
  fill: ${({ theme }) => theme.colors.envy};
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
const StyledBox = styled(Box)`
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  background-color: #10161a;
  max-width: 400px;
`;

const StyledAllstarIcon = styled(Image)`
  width: 110px;
`;

const Row = styled(Flex)`
  align-items: start;
  justify-content: start;
  gap: 15px;
`;
