import { useState } from "react";
import styled from "styled-components";
import { FiMoreHorizontal } from "react-icons/fi";
import { Tooltip } from "../../../../../Tooltip";
import { Flex } from "rebass/styled-components";
import { HiOutlineAnnotation } from "react-icons/hi";
import { RiShareForwardFill } from "react-icons/ri";
import { HiDownload } from "react-icons/hi";
import { RiEditLine } from "react-icons/ri";
import { BiMobile } from "react-icons/bi";
import { HiOutlineFlag } from "react-icons/hi";
import ClipDelete from "../../../../../../../components/atoms/form/button/clipDelete";
import { Button } from "../../../../../Button";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../../../../Hooks/useUser";
import { useIsDesktopApp } from "../../../../../../Hooks/useIsDesktopApp";
import { ReactComponent as Montage } from "../../../../../../Assets/CreateMontage/nav-montage.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AllstarModalState } from "../../../../../../State/modals";
import { ModalType } from "../../../../../../@types";
import { ApolloError } from "@apollo/client";
import { TbPinFilled, TbPinned } from "react-icons/tb";
import {
  PinnedClipsAtom,
  PinnedClipWithError,
} from "../../../../../../State/clip";
import { LoadingSpinner } from "../../../../../Lottie";
import { PinnedClipsViewModel } from "MVVM/Views/Profile/Clips/Components/PinnedClips/ViewModel";
import { analytics } from "MVVM/Analytics";
import { getQueryParam } from "MVVM/Utilities/QueryParam";

export function More({
  children,
  clip,
  isProfile,
  position,
  setShareModalOpen,
  setReportModalOpen,
  setEditModalOpen,
  setModalOpen,
  isMontage,
  onDownloadClick,
  isOwnClip,
}: any) {
  return (
    <>
      <DropdownContainer
        type="click"
        align="right"
        position={
          position || {
            bottom: "10px",
            right: "15px",
          }
        }
        tooltipContent={
          <MoreDropDown
            clip={clip}
            isProfile={isProfile}
            isOwnClip={isOwnClip}
            isMontage={isMontage}
            onDownloadClick={onDownloadClick}
            setShareModalOpen={setShareModalOpen}
            setReportModalOpen={setReportModalOpen}
            setEditModalOpen={setEditModalOpen}
            setModalOpen={setModalOpen}
          />
        }
      >
        <Button
          display="flex"
          maxWidth="38px"
          size="social"
          title="More Options"
          name="clip-more-button"
          classification="secondaryRounded"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <StyledMore></StyledMore>
          {children}
        </Button>
      </DropdownContainer>
    </>
  );
}

const DropdownContainer = styled(Tooltip)`
  display: flex;
  min-width: 38px;
  z-index: 1;
  &:hover {
    cursor: default;
  }
`;

const StyledMore = styled(FiMoreHorizontal)`
  position: relative;
  width: 24px;
  height: 24px;
`;

const ButtonText = styled.span`
  margin-left: 15px;
`;

const StyledFeedback = styled(HiOutlineAnnotation)`
  position: relative;
  top: 7px;
`;

const StyledShare = styled(RiShareForwardFill)`
  position: relative;
  top: 7px;
`;

const StyledDownload = styled(HiDownload)`
  position: relative;
  top: 7px;
`;

const StyledViewMobile = styled(BiMobile)`
  position: relative;
  top: 7px;
`;

const StyledMontage = styled(Montage)`
  fill: ${(props) => props.theme.colors.chalk};
  height: 16px;
  width: 18px;
  position: relative;
  top: 7px;
  &:hover {
    fill: ${(props) => props.theme.colors.envy};
  }
`;

const StyledEdit = styled(RiEditLine)`
  position: relative;
  top: 7px;
`;

const StyledWarning = styled(HiOutlineFlag)`
  position: relative;
  top: 7px;
`;

let DropdownContent = styled(Flex)`
  &:hover {
    cursor: default;
  }
`;

const MontageButton = styled(Button)`
  &:hover svg {
    fill: ${(props) => props.theme.colors.envy};
  }
`;

function MoreDropDown({
  mx,
  my,
  setShareModalOpen,
  setReportModalOpen,
  setEditModalOpen,
  setModalOpen,
  clip,
  classification,
  isProfile,
  isMontage,
  onDownloadClick,
  isOwnClip,
}: any) {
  classification = classification ? classification : "options";

  const history = useHistory();
  const pinnedClips = useRecoilValue(PinnedClipsAtom);
  const isPinned = pinnedClips?.some(
    (pinnedClip) => pinnedClip?.id === clip?.id,
  );
  const { pinClip, unpinClip } = PinnedClipsViewModel();
  const isDesktop = useIsDesktopApp();
  const showMontageButton = isMontage && !isDesktop.isDesktop;
  const clipLink = `${window.location.hostname}/clip?clip=${clip.shareId}`;
  const { allstarUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const setPinnedClipWithError = useSetRecoilState(PinnedClipWithError);
  const showDelete = clip?.partner?.useCase !== "POTG";
  const mobileView = getQueryParam("mobileView");
  let commonProps = {
    classification: classification,
    size: "social",
  };

  return (
    <>
      <DropdownContent mx={mx ? mx : 0} my={my ? my : 0}>
        <Flex flexDirection="column" alignItems="flex-start">
          {isOwnClip && !clip.userClipTitle && !isMontage ? (
            <Button
              display="flex"
              justifyContent="flex-start"
              alignContent="center"
              {...commonProps}
              onClick={(e: any) => {
                history.push({
                  pathname: `/clip`,
                  search: `?clip=${clip.shareId}&edit=true`,
                });
              }}
            >
              <StyledEdit size={18} />

              <ButtonText>Edit Title</ButtonText>
            </Button>
          ) : (
            <>
              {isOwnClip && !isMontage ? (
                <>
                  {" "}
                  <Button
                    display="flex"
                    justifyContent="flex-start"
                    alignContent="center"
                    disabled={true}
                    {...commonProps}
                  >
                    <StyledEdit size={18} />

                    <ButtonText>Title set</ButtonText>
                  </Button>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {showMontageButton && (
            <MontageButton
              mt={2}
              display="flex"
              justifyContent="flex-start"
              alignContent="center"
              {...commonProps}
              onClick={() => {
                history.push("/montages/create");
              }}
            >
              <StyledMontage />
              <ButtonText>Create Montage</ButtonText>
            </MontageButton>
          )}
          <Button
            mt={2}
            display="flex"
            justifyContent="flex-start"
            alignContent="center"
            {...commonProps}
            clip={clip}
            clipLink={clipLink}
            onClick={(e: any) => {
              if (allstarUser?.loggedIn) {
                onDownloadClick();
              } else {
                return setAllstarModalState({
                  data: {
                    action: "signup",
                  },
                  functions: {},
                  isOpen: ModalType.Signup,
                });
              }
            }}
          >
            <StyledDownload size={18} />

            <ButtonText>Download</ButtonText>
          </Button>
          <Button
            mt={2}
            display="flex"
            justifyContent="flex-start"
            alignContent="center"
            name="clip-more-share"
            {...commonProps}
            clip={clip}
            clipLink={clipLink}
            onClick={() => {
              setShareModalOpen(true);
            }}
          >
            <StyledShare size={18} />

            <ButtonText>Share</ButtonText>
          </Button>

          {clip?.hasPortrait && (
            <Button
              mt={2}
              display="flex"
              justifyContent="flex-start"
              alignContent="center"
              name="clip-view-mobile"
              {...commonProps}
              clip={clip}
              clipLink={clipLink}
              onClick={() => {
                if (mobileView) {
                  const queryParams = new URLSearchParams(location.search);
                  analytics.track(
                    "Clip Page More - Desktop View Requested",
                    {},
                  );
                  queryParams.delete("mobileView");
                  history.replace({
                    pathname: location.pathname,
                    search: queryParams.toString(),
                  });
                } else {
                  analytics.track("Clip Page More - Mobile View Requested", {});
                  history.replace({
                    pathname: location.pathname,
                    search: "mobileView=true",
                  });
                }
              }}
            >
              <StyledViewMobile size={18} />
              <ButtonText>{`View ${
                mobileView ? "Desktop" : "Mobile"
              }`}</ButtonText>
            </Button>
          )}

          {isOwnClip || isMontage ? null : (
            <Button
              {...commonProps}
              mt={2}
              display="flex"
              justifyContent="flex-start"
              alignContent="center"
              name="clip-report-button"
              onClick={() => {
                setReportModalOpen(true);
              }}
            >
              <StyledWarning size={18} />
              <ButtonText>Report</ButtonText>
            </Button>
          )}
          {isOwnClip && !isMontage && isProfile && (
            <Button
              {...commonProps}
              mt={2}
              display="flex"
              justifyContent="flex-start"
              alignContent="center"
              alignItems="center"
              name="clip-pin-button"
              onClick={() => {
                setLoading(true);
                setTimeout(async () => {
                  try {
                    if (isPinned) {
                      await unpinClip({ variables: { clipId: clip._id } });
                      window.rudderanalytics.track(
                        "Pinned Clip - Remove Clip",
                        {},
                      );
                    } else {
                      await pinClip({ variables: { clipId: clip._id } });
                      window.rudderanalytics.track(
                        "Pinned Clip - Add Clip",
                        {},
                      );
                    }
                    setLoading(false);
                  } catch (e) {
                    const error = e as ApolloError;
                    if (error.message.match(/more than 3/i)) {
                      window.rudderanalytics.track(
                        "Pinned Clip - Too Many Clips",
                        {},
                      );
                      setPinnedClipWithError({
                        clipId: clip._id,
                        errorMessage:
                          "You can only pin up to 3 clips at a time. Unpin a clip to free up space.",
                      });
                    }
                    setLoading(false);
                  }
                }, 1000);
              }}
            >
              {isPinned && (
                <>
                  {loading ? (
                    <LoadingSpinner width={18} height={18} />
                  ) : (
                    <StyledFilledPinnedIcon size={18} />
                  )}

                  <ButtonText>Pinned</ButtonText>
                </>
              )}
              {!isPinned && (
                <>
                  {loading ? (
                    <LoadingSpinner width={18} height={18} />
                  ) : (
                    <StyledPinnedIcon size={18} />
                  )}

                  <ButtonText>Pin</ButtonText>
                </>
              )}
            </Button>
          )}
          {isOwnClip ? (
            <>
              {" "}
              <Button
                mt={2}
                display="flex"
                justifyContent="flex-start"
                alignContent="center"
                name="clip-feedback-button"
                {...commonProps}
                onClick={() => {
                  //open our zendesk in new tab
                  window.open(
                    "https://help.allstar.gg/hc/en-us/requests/new",
                    "_blank",
                  );
                }}
              >
                <StyledFeedback size={18} />

                <ButtonText>Report Issue</ButtonText>
              </Button>
              {showDelete && (
                <>
                  {" "}
                  <ClipDelete
                    {...commonProps}
                    mt={2}
                    display="flex"
                    justifyContent="flex-start"
                    alignContent="center"
                    classification="alert"
                    onClick={() => {
                      setEditModalOpen(true);
                    }}
                  >
                    <ButtonText>
                      {isMontage ? <>Delete Montage</> : <>Delete Clip</>}
                    </ButtonText>
                  </ClipDelete>{" "}
                </>
              )}
            </>
          ) : null}
        </Flex>
      </DropdownContent>
    </>
  );
}
const StyledPinnedIcon = styled(TbPinned)`
  color: ${({ theme }) => theme.colors.chalk};
`;
const StyledFilledPinnedIcon = styled(TbPinFilled)`
  color: ${({ theme }) => theme.colors.error};
`;
