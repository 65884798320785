import { Box, Flex, Image, Text } from "rebass/styled-components";
import styled from "styled-components";
import { CompetitionViewModel } from "../../ViewModel";
import { TComp } from "../../@types";
import { MinimalCountDownRenderer } from "../../Views/Play/countdownTimer";
import Countdown from "react-countdown";
import { JoinCampaignButton } from "../JoinCampaignButton";
import { useEffect } from "react";

export const CampaignContentCallout = ({
  compact,
  text,
}: {
  compact?: boolean;
  text: string;
}) => {
  const { data } = CompetitionViewModel();

  const competitions = data?.competitions as TComp[];

  useEffect(() => {
    window.rudderanalytics.track("Competition Content Callout - Loaded", {});
  }, []);
  if (!competitions) return <></>;

  const present = competitions?.filter(
    (competition) => parseInt(competition?.campaignEnd) > Date.now(),
  );
  const currentCompetition = present[0];

  if (!currentCompetition) return <></>;

  if (compact)
    return (
      <Box mx={6} mt={6} mb={2}>
        <Container
          backgroundImage={currentCompetition.images.carouselBackground}
          style={{ padding: "16px" }}
        >
          <Flex flexDirection="column" style={{ gap: 8 }}>
            <Flex style={{ gap: "20px" }}>
              <Image
                flexShrink={0}
                flexGrow={0}
                src={currentCompetition.images.campaign}
                style={{ zIndex: 2, height: "48px", width: "128px" }}
              />
              <StyledText fontSize={1} fontWeight={500}>
                {text.split(".")[0]}.
              </StyledText>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mt={4}
              style={{ gap: "20px" }}
            >
              <Countdown
                date={new Date(parseInt(currentCompetition.campaignJoinEnd))}
                renderer={(props) => (
                  <MinimalCountDownRenderer
                    {...props}
                    variant={"text.headline"}
                    color={currentCompetition.color}
                  />
                )}
              />
              <JoinCampaignButton
                color={currentCompetition.color}
                secondaryColor={currentCompetition.secondaryColor}
                slug={currentCompetition.slug}
                style={{
                  padding: "2px",
                  height: "40px",
                  borderRadius: "20px",
                  lineHeight: "1em",
                  width: "140px",
                  zIndex: 0,
                }}
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
    );

  return (
    <Container backgroundImage={currentCompetition.images.carouselBackground}>
      <Row>
        <CampaignColumn>
          <CampaignImageAndTimer>
            <Image
              width="180"
              flexShrink={0}
              src={currentCompetition.images.campaign}
              style={{ zIndex: 2 }}
            />
            <Countdown
              date={new Date(parseInt(currentCompetition.campaignJoinEnd))}
              renderer={(props) => (
                <MinimalCountDownRenderer
                  {...props}
                  color={currentCompetition.color}
                />
              )}
            />
          </CampaignImageAndTimer>
        </CampaignColumn>
        <StyledText variant="text.labelMedium">{text}</StyledText>
      </Row>
      <JoinCampaignButton
        color={currentCompetition.color}
        secondaryColor={currentCompetition.secondaryColor}
        slug={currentCompetition.slug}
      />
    </Container>
  );
};

const StyledText = styled(Text)`
  z-index: 1;
  max-width: 600px;

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    display: none;
  }
`;

const Row = styled(Flex)`
  gap: 20px;
  align-items: center;
  justify-content: start;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    justify-content: start;
    width: unset;
  }
`;

const CampaignImageAndTimer = styled(Flex)`
  flex-direction: column;
  gap: 4px;

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    justify-content: start;
    width: 100%;
  }
`;

const Container = styled(Flex)<{ backgroundImage: string }>`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;

    border-radius: 9px;
  }
  width: 100%;
  padding: 20px;
  position: relative;
  justify-content: space-between;
  border-radius: 9px;
  align-items: center;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    &::before {
      background-position: top left; /* Shift the background image */
    }
  }
`;

const CampaignColumn = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;
