import { BiSolidGhost } from "react-icons/bi";
import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

const EmptyList = ({ text }: { text: string }) => {
  return (
    <Flex
      style={{ gap: "8px", height: "100%", opacity: 0.6 }}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      py={6}
    >
      <StyledGhost />
      <Text color="chalk" fontSize={[2, 3, 4]} fontWeight={500}>
        {text}
      </Text>
    </Flex>
  );
};

const StyledGhost = styled(BiSolidGhost)`
  color: ${({ theme }) => theme.colors.chalk};
  height: 30px;
  width: 30px;
`;

export { EmptyList };
