import { useCallback } from "react";
import { useQuery } from "@apollo/client";
import { useRecoilValue } from "recoil";
import { useStatsigClient } from "@statsig/react-bindings";

import { SUGGESTED_FOLLOWERS } from "MVVM/GraphQL/suggestedFollowers";
import { ISuggestedUser } from "../@types";
import { useUser } from "MVVM/Hooks/useUser";
import { SuggestedFollowing } from "MVVM/State/suggestedFollowing";

const SuggestedFeedViewModel = () => {
  const { allstarUser } = useUser();
  const loggedIn = allstarUser?.loggedIn;
  const { getExperiment } = useStatsigClient();
  const suggestedFollowing: { ids: string[] } =
    useRecoilValue(SuggestedFollowing);

  let useGraphQuery = false;
  if (loggedIn) {
    useGraphQuery = getExperiment("graphbasedsuggestedfollowers").get(
      "use_graph_query",
      false,
    );
  }

  const { data, error, loading } = useQuery(
    SUGGESTED_FOLLOWERS(useGraphQuery),
    {
      fetchPolicy: "network-only",
      variables: { count: 12 },
    },
  );

  const filteredSuggestedFollowers = useCallback(() => {
    const suggestedFollowers = data?.me?.user?.suggestedFollowers;
    if (!suggestedFollowers) return [];

    const filtered = suggestedFollowers.filter(
      ({ _id }: { _id: string }) => !suggestedFollowing.ids.includes(_id),
    );

    return filtered.length > 10 ? filtered : suggestedFollowers.slice(0, 12);
    // This is intentional. We do not want to remove users from this list in real time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.me?.user?.suggestedFollowers]);

  const suggestedFollowers = useGraphQuery
    ? filteredSuggestedFollowers()
    : data?.suggestedCreators.map((suggested: ISuggestedUser) => ({
        ...suggested.user,
        reason: suggested.reason,
      }));

  return {
    suggestedFollowers,
    error,
    loading,
  };
};

export { SuggestedFeedViewModel };
