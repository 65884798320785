import { Flex } from "rebass/styled-components";

import { LoadingSkeleton } from "../../../Components/Skeloton";

const Loading = () => (
  <Flex
    style={{ gap: 6, position: "relative" }}
    flexDirection="column"
    width="100%"
  >
    <LoadingSkeleton
      height="30px"
      width="10%"
      style={{ marginBottom: "0px" }}
    />
    <LoadingSkeleton height="160px" width="100%" />
  </Flex>
);

export { Loading };
