import { PageableContentFeed } from "../../../../Homepage/PageableContentFeed";
import { CompetitionClipsViewModel } from "./ViewModel";
import { SwiperOptions } from "swiper/types";

export const CompetitionClips = ({
  cardIds,
  swiperControlsColor,
  swiperBreakpoints,
  displayTitle = true,
  displayArrows = true,
  title,

  competitionColor,
}: {
  cardIds: string[];
  displayTitle?: boolean;
  displayArrows?: boolean;
  swiperControlsColor: string;
  title: string;
  competitionColor?: string;
  swiperBreakpoints: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
}) => {
  const { competitionClips, loading } = CompetitionClipsViewModel({
    cardIds: cardIds,
  });

  return (
    <PageableContentFeed
      title={title}
      data={competitionClips}
      loading={loading}
      controlsColor={swiperControlsColor}
      breakpoints={swiperBreakpoints}
      displayTitle={displayTitle}
      displayArrows={displayArrows}
      subtextColor={competitionColor}
      borderColor={competitionColor}
    />
  );
};
