import { useEffect, useState } from "react";
import { colors } from "../../../../../theme/colors";
import { MdReplay } from "react-icons/md";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Button } from "../../../../Components/Button";
import { Body, Subheader } from "../../../../Components/Text";
import { RiCheckboxCircleFill } from "react-icons/ri";
import theme from "../../../../../theme/themeThree";
import {
  OverlayEditTitle,
  OverlayRemix,
  OverlayShare,
  OverlayDownload,
  OverlayMobile,
  OverlayInfo,
  OverlaySettings,
} from "../../../../Components/Button/PlayerOverlay";
import { useRecoilState, useRecoilValue } from "recoil";
import { PartnerViewSession, IsUserLoggedIn } from "../../../../State";
import { useWindowSize } from "../../../../Hooks/useWindowSize";
import { SharePopout } from "../SharePopout";
import { AiOutlineStar } from "react-icons/ai";
import { AttemptedAction } from "../AttemptedAction";
import { isFaceItPartner } from "../../Utilities";

export function PartnerOverlay({
  onReplayClick,
  display,
  translateHook,
  platform,
  analyticsEventType,
}: any) {
  const [partnerViewSession] = useRecoilState(PartnerViewSession);
  const isLoggedIn = useRecoilValue(IsUserLoggedIn);
  const { overlayConfig, dynamicCta } = partnerViewSession;
  const size = useWindowSize();
  const [ctaSize, setCtaSize] = useState("default");
  const [shouldShowOverlay, setShouldShowOverlay] = useState(
    display && overlayConfig.display,
  );
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [displaySharePopout, setDisplaySharePopout] = useState(false);
  const [attemptedAction, setAttemptedAction] = useState("");
  const shouldShowCta = dynamicCta?.mainCopy;
  const isFaceItPartnerFlag = isFaceItPartner(platform);

  useEffect(() => {
    if (shouldShowOverlay) {
      window.rudderanalytics.track("Overlay - Display", {
        isLoggedIn: isLoggedIn,
        knownUser: overlayConfig?.knownUser,
        shareId: overlayConfig?.shareId,
        partner: overlayConfig?.partner?.name,
        useCase: partnerViewSession?.clip?.partner?.useCase,
        link: partnerViewSession?.dynamicCta?.link,
        type: partnerViewSession?.dynamicCta?.type,
        isClaimable: partnerViewSession.dynamicCta.isClaimable,
      });
      if (
        partnerViewSession?.promotion?.display &&
        partnerViewSession?.promotion?.name
      ) {
        window.rudderanalytics.track("Promotion - Impression", {
          promotionName: partnerViewSession?.promotion?.name,
          type: "videoPlayer",
        });
      }
      // need to add promo impression here
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowOverlay]);

  useEffect(() => {
    setShouldShowOverlay(display && overlayConfig.display);
  }, [overlayConfig, display]);

  useEffect(() => {
    if (size.width && size.width <= 700 && ctaSize !== "tiny") {
      setCtaSize("tiny");
    }
    if (size.width && size.width > 700 && ctaSize !== "default") {
      setCtaSize("default");
    }
  }, [size, ctaSize]);

  const closeOthers = () => {
    setSettingsOpen(false);
    setDisplaySharePopout(false);
  };

  const getSettings = () => {
    switch (overlayConfig.resconfig) {
      case "1":
        return (
          <>
            <SettingsRow isActive={true}>
              <StyledCheckIcon />
              <StyledSettingsText pl={2}>
                720P30 (Current Resolution)
              </StyledSettingsText>
            </SettingsRow>
            <SettingsRow
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Quality Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    type: "Desktop 1080P60 (16:9)",
                    overlayType: analyticsEventType,
                  });
                } catch (e) {}
                if (overlayConfig.knownUser)
                  window.open(overlayConfig.quality.remixLink, "_blank");
                else setAttemptedAction("adjustQuality");
              }}
            >
              <StyledStar />
              <StyledSettingsText pl={2}>
                Desktop 1080P60 (16:9)
              </StyledSettingsText>
            </SettingsRow>
            <SettingsRow
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Quality Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    type: "Mobile 1080P60 (9:16)",
                    overlayType: analyticsEventType,
                  });
                } catch (e) {}
                if (overlayConfig.knownUser)
                  window.open(overlayConfig.quality.mobileLink, "_blank");
                else setAttemptedAction("adjustQuality");
              }}
            >
              <StyledStar />
              <StyledSettingsText pl={2}>
                Mobile 1080P60 (9:16)
              </StyledSettingsText>
            </SettingsRow>
          </>
        );
      case "2":
        return (
          <>
            <SettingsRow isActive={true}>
              <StyledCheckIcon />
              <StyledSettingsText pl={2}>
                Desktop 1080P60 (16:9)
              </StyledSettingsText>
            </SettingsRow>
            <SettingsRow
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Quality Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    type: "Mobile 1080P60 (9:16)",
                    overlayType: analyticsEventType,
                  });
                } catch (e) {}
                if (overlayConfig.knownUser)
                  window.open(overlayConfig.mobile.link, "_blank");
                else setAttemptedAction("adjustQuality");
              }}
            >
              <StyledStar />
              <StyledSettingsText pl={2}>
                Mobile 1080P60 (9:16)
              </StyledSettingsText>
            </SettingsRow>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <AttemptedAction
        translateHook={translateHook}
        overlayConfig={overlayConfig}
        type={attemptedAction}
        setAttemptedAction={setAttemptedAction}
      />
      <Box
        className="hover-controls"
        style={{ display: shouldShowOverlay ? "none" : "block" }}
      >
        <InfoPosition>
          {" "}
          <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
            <StyledInfo
              classification={"overlayDefault"}
              size={"overlayOption"}
              onClick={() => {
                closeOthers();
                setAttemptedAction("info");
                try {
                  window.rudderanalytics.track("Overlay - Info Click", {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    shareId: overlayConfig.shareId,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    overlayType: "hover",
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
            ></StyledInfo>
          </DefaultBorder>
        </InfoPosition>
        <SettingsButtonPosition>
          <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
            <StyledSettings
              classification={"overlayDefault"}
              size={"overlayOption"}
              onClick={() => {
                try {
                  if (!overlayConfig.knownUser) {
                    closeOthers();
                    setAttemptedAction("adjustQuality");
                  } else {
                    setAttemptedAction("thirdPartySettings");
                  }
                  window.rudderanalytics.track("Overlay - Settings Click", {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    shareId: overlayConfig.shareId,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    overlayType: "hover",
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <Notification />
            </StyledSettings>
          </DefaultBorder>
        </SettingsButtonPosition>
        {settingsOpen && (
          <SettingsContainerPosition>
            {overlayConfig.resconfig && <> {getSettings()}</>}
          </SettingsContainerPosition>
        )}
      </Box>
      <ClipOverlay
        className="videoOverlay"
        display={shouldShowOverlay ? "block" : "none"}
        $analyticsEventType={analyticsEventType}
      >
        <InfoPosition>
          {" "}
          <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
            <StyledInfo
              classification={"overlayDefault"}
              size={"overlayOption"}
              onClick={() => {
                closeOthers();
                setAttemptedAction("info");
                try {
                  window.rudderanalytics.track("Overlay - Info Click", {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    shareId: overlayConfig.shareId,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    overlayType: analyticsEventType,
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
            ></StyledInfo>
          </DefaultBorder>
        </InfoPosition>
        {!isFaceItPartnerFlag && (
          <SettingsButtonPosition>
            <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
              <StyledSettings
                classification={"overlayDefault"}
                size={"overlayOption"}
                onClick={() => {
                  try {
                    if (!overlayConfig.knownUser) {
                      closeOthers();
                      setAttemptedAction("adjustQuality");
                    } else {
                      setAttemptedAction("thirdPartySettings");
                    }
                    window.rudderanalytics.track("Overlay - Settings Click", {
                      isLoggedIn: isLoggedIn,
                      knownUser: overlayConfig.knownUser,
                      shareId: overlayConfig.shareId,
                      partner: overlayConfig.partner.name,
                      useCase: partnerViewSession?.clip?.partner?.useCase,
                      overlayType: analyticsEventType,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                <Notification />
              </StyledSettings>
            </DefaultBorder>
          </SettingsButtonPosition>
        )}
        {settingsOpen && (
          <SettingsContainerPosition>
            {overlayConfig.resconfig && <> {getSettings()}</>}
          </SettingsContainerPosition>
        )}
        <StyledOptions>
          <Box style={{ position: "relative" }}>
            <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
              <StyledShare
                classification={"overlayDefault"}
                size={"overlayOption"}
                href={overlayConfig.share.link}
                onClick={() => {
                  setDisplaySharePopout(!displaySharePopout);
                  try {
                    window.rudderanalytics.track("Overlay - Share Click", {
                      isLoggedIn: isLoggedIn,
                      shareId: overlayConfig.shareId,
                      partner: overlayConfig.partner.name,
                      useCase: partnerViewSession?.clip?.partner?.useCase,
                      overlayType: analyticsEventType,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            </DefaultBorder>
            <SharePopout
              overlayConfig={overlayConfig}
              display={displaySharePopout}
              setDisplaySharePopout={setDisplaySharePopout}
              setAttemptedAction={setAttemptedAction}
            />
          </Box>
          <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
            <StyledDownload
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.edit.link}
              onClick={() => {
                closeOthers();

                try {
                  window.rudderanalytics.track("Overlay - Download Click", {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    shareId: overlayConfig.shareId,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    directDownload: isFaceItPartnerFlag,
                    overlayType: analyticsEventType,
                  });
                } catch (e) {
                  console.log(e);
                }

                if (isFaceItPartnerFlag) {
                  return window.open(
                    `${process.env.REACT_APP_S3_CLIP_BUCKET}/${partnerViewSession?.clip?.clipLink}`,
                  );
                }

                if (!overlayConfig.knownUser) {
                  setAttemptedAction("download");
                } else {
                  window.open(overlayConfig.download.link, "_blank");
                }
              }}
            ></StyledDownload>
          </DefaultBorder>
          <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
            <StyledMobile
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.edit.link}
              onClick={() => {
                closeOthers();
                if (!overlayConfig.knownUser) {
                  setAttemptedAction("mobile");
                } else {
                  window.open(overlayConfig.mobile.link, "_blank");
                }

                try {
                  window.rudderanalytics.track("Overlay - Mobile Click", {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    shareId: overlayConfig.shareId,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    overlayType: analyticsEventType,
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
            ></StyledMobile>
          </DefaultBorder>
          <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
            <StyledRemix
              classification={"overlayDefault"}
              size={"overlayRemixOption"}
              onClick={() => {
                try {
                  closeOthers();
                  if (!overlayConfig.knownUser) {
                    setAttemptedAction("addfx");
                  } else {
                    window.open(overlayConfig.remix.link, "_blank");
                  }
                  window.rudderanalytics.track("Overlay - Remix Click", {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    shareId: overlayConfig.shareId,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    overlayType: analyticsEventType,
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
            ></StyledRemix>
          </DefaultBorder>{" "}
          {!isFaceItPartnerFlag && (
            <DefaultBorder sx={{ ...theme.borders["overlayDefault"] }}>
              <StyledEditTitle
                classification={"overlayDefault"}
                size={"overlayOption"}
                onClick={() => {
                  closeOthers();

                  if (!overlayConfig.knownUser) {
                    setAttemptedAction("editTitle");
                  } else {
                    window.open(overlayConfig.edit.link, "_blank");
                  }
                  try {
                    window.rudderanalytics.track("Overlay - Edit Click", {
                      isLoggedIn: isLoggedIn,
                      knownUser: overlayConfig.knownUser,
                      shareId: overlayConfig.shareId,
                      partner: overlayConfig.partner.name,
                      useCase: partnerViewSession?.clip?.partner?.useCase,
                      overlayType: analyticsEventType,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
              ></StyledEditTitle>
            </DefaultBorder>
          )}
        </StyledOptions>
        {analyticsEventType !== "pre" && (
          <StyledReplayWrapper>
            <StyledButton flex={true} classification={"bigReplay"}>
              <MdReplay
                size={40}
                onClick={() => {
                  try {
                    window.rudderanalytics.track("Overlay - Replay Click", {
                      isLoggedIn: isLoggedIn,
                      knownUser: overlayConfig.knownUser,
                      shareId: overlayConfig.shareId,
                      partner: overlayConfig.partner.name,
                      useCase: partnerViewSession?.clip?.partner?.useCase,
                      overlayType: analyticsEventType,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                  onReplayClick();
                }}
              />
            </StyledButton>
          </StyledReplayWrapper>
        )}
        <StyledCta flexDirection={"column"} shouldShowCta={shouldShowCta}>
          {overlayConfig.centerText.show && (
            <IndependentTextWrapper>
              <StyledHeadline>{dynamicCta.mainCopy}</StyledHeadline>
            </IndependentTextWrapper>
          )}
          <CtaButton
            flex={true}
            size={ctaSize}
            px={4}
            onClick={() => {
              try {
                window.rudderanalytics.track(
                  "Overlay - Center Main Button Click",
                  {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    shareId: overlayConfig.shareId,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    link: partnerViewSession.dynamicCta.link,
                    type: partnerViewSession.dynamicCta.type,
                    isClaimable: partnerViewSession.dynamicCta.isClaimable,
                    overlayType: analyticsEventType,
                  },
                );

                if (partnerViewSession?.promotion?.display) {
                  window.rudderanalytics.track("Promotion - Button Click", {
                    isLoggedIn: isLoggedIn,
                    knownUser: overlayConfig.knownUser,
                    partner: overlayConfig.partner.name,
                    useCase: partnerViewSession?.clip?.partner?.useCase,
                    link: partnerViewSession.dynamicCta.link,
                    type: "videoPlayer",
                    overlayType: analyticsEventType,
                  });
                }

                try {
                  window.open(
                    `${partnerViewSession.dynamicCta.link}`,
                    "_blank",
                  );
                } catch (e) {
                  window.rudderanalytics.track(
                    "Overlay - Center Main Button Click Fail",
                    {
                      error: `${e}`,
                    },
                  );
                }
              } catch (e) {}
            }}
          >
            {partnerViewSession.dynamicCta.buttonText}
          </CtaButton>
        </StyledCta>
      </ClipOverlay>
    </>
  );
}

const Notification = styled.span`
  position: absolute;
  top: 0px;
  right: -5px;
  padding: 6px;
  border-radius: 50%;
  background: red;
  color: white;
`;

const IndependentTextWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 50px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 300px) {
    display: none;
  }
  @media (max-height: 330px) {
    display: none;
  }
`;

const StyledStar = styled<any>(AiOutlineStar)`
  transition: color 0.1s;
`;

const ClipOverlay = styled(Flex)<{ $analyticsEventType: string }>`
  position: absolute;
  pointer-events: none;
  background: ${(props) =>
    props.$analyticsEventType === "pre"
      ? "transparent"
      : colors.midnightAlpha80};
  top: 0;
  left: 0;
  display: ${(props) => {
    return props.display;
  }};

  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition:
    opacity 0.2s,
    visibility 0s 0.2s;
`;

const StyledCheckIcon = styled(RiCheckboxCircleFill)`
  color: ${(props) => props.theme.colors.envy};
  width: 16px;
  height: 16px;
`;

const CtaButton = styled(Button)`
  pointer-events: auto;
  border-radius: 999px;
  position: absolute;
  top: -45%;
  @media (max-width: 700px) {
    top: -18%;
  }
  @media (max-height: 330px) {
    top: -50%;
  }
`;

const StyledCta = styled<any>(Flex)`
  position: absolute;
  pointer-events: auto;
  bottom: 3%;
  left: 2%;
  right: 2%;
  border-radius: 10px;
  height: 90px;
  align-items: center;
  justify-content: center;
  background: ${colors.envyAlpha10};
  border: 2px solid ${colors.envyAlpha50};
  @media (max-width: 800px) {
    height: 90px;
  }
  @media (max-width: 700px) {
    height: 100px;
  }
  @media (max-width: 300px) {
    height: 40px;
    border: 0px solid ${colors.envy};
    bottom: 0%;
    left: 0%;
    right: 0%;
    border-radius: 0px;
  }
  @media (max-height: 330px) {
    height: 40px;
    border: 0px solid ${colors.envy};
    bottom: 0%;
    left: 0%;
    right: 0%;
    border-radius: 0px;
  }
  visibility: ${(props: any) => {
    if (props.shouldShowCta) {
      return "visible";
    } else {
      return "hidden";
    }
  }};
`;

const StyledShare = styled(OverlayShare)`
  height: 55px;
  width: 55px;
  padding-top: 0px;
  @media (max-width: 700px) {
    padding-top: 6px;
  }
`;
const StyledDownload = styled(OverlayDownload)`
  height: 55px;
  width: 55px;
  padding-top: 0px;
  @media (max-width: 700px) {
    padding-top: 6px;
  }
`;

const StyledMobile = styled(OverlayMobile)``;
const StyledSettingsText = styled(Subheader)`
  transition: color 0.1s;
  &:hover {
    color: ${colors.envy};
  }
`;
const StyledSettings = styled(OverlaySettings)`
  height: 55px;
  width: 55px;
  padding-top: 0px;
  line-height: 37px !important;
  z-index: 100;
`;
const StyledRemix = styled(OverlayRemix)``;
const DefaultBorder = styled(Box)``;
const StyledEditTitle = styled(OverlayEditTitle)`
  height: 55px;
  width: 55px;
  padding-top: 0px;
  line-height: 37px !important;
`;

const StyledInfo = styled(OverlayInfo)`
  height: 55px;
  width: 55px;
  padding-top: 0px;
  line-height: 37px !important;
  z-index: 100;
`;
const SettingsButtonPosition = styled(Box)`
  pointer-events: auto;
  z-index: 8;
  flex-direction: column;
  position: absolute;
  left: 20px;
  top: 80px;

  @media (max-width: 700px) {
    left: 10px;
    top: 65px;
  }
  @media (max-width: 650px) {
    left: 10px;
    top: 55px;
  }
  @media (max-height: 330px) {
    top: 55px;
  }
`;
const SettingsRow = styled<any>(Flex)`
  width: 100%;
  align-items: center;
  padding-left: 5px;
  border-left: 2px solid transparent;
  pointer-events: auto;

  ${(props) => {
    if (props.isActive) {
      return `
        border-left: 2px solid ${colors.envy};
        color: ${colors.envy};
      `;
    } else {
      return `
        &:hover {
          color: ${colors.envy};
          cursor:pointer;
        }
      `;
    }
  }}
`;

const InfoPosition = styled(Box)`
  pointer-events: auto;
  z-index: 10;
  flex-direction: column;
  position: absolute;
  left: 20px;
  top: 20px;

  @media (max-width: 700px) {
    left: 10px;
    top: 10px;
  }
`;
const SettingsContainerPosition = styled(Flex)`
  pointer-events: auto;
  background: ${colors.midnightAlpha70};
  flex-direction: column;
  justify-content: end;
  align-items: end;
  position: absolute;
  width: 230px;
  gap: 10px;
  left: 10px;
  top: 10px;
  z-index: 9;
  border-radius: 9px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;

  padding-top: 70px;
  @media (max-width: 700px) {
    left: 0px;
    top: 50px;
    padding-top: 15px;
    background: ${colors.midnightAlpha90};
  }
`;
const StyledOptions = styled(Flex)`
  pointer-events: auto;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 20px;
  gap: 15px;
  z-index: 100;
  @media (max-width: 700px) {
    right: 10px;
    top: 10px;
    gap: 8px;
  }
  @media (max-height: 330px) {
    right: 10px;
    top: 10px;
    gap: 8px;
    flex-direction: row;
  }
`;
const StyledButton = styled(Button)``;
const StyledHeadline = styled(Body)`
  max-width: 90%;
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
  @media (max-width: 900px) {
    max-width: 100%;
    font-size: 1em;
    font-weight: 500;
  }
  @media (max-width: 700px) {
    max-width: 90%;
    font-size: 0.8em;
    font-weight: 400;
  }
`;
const StyledReplayWrapper = styled(Flex)`
  pointer-events: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
