import { ApolloError } from "@apollo/client";
import { atom, RecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { EView } from "../Views/CreateMontage";
import { GAMES, TLoadout, TSequence } from "../Views/CreateMontage/@types";
import {
  DEFAULT_LOADOUT,
  DEFAULT_SEQUENCE,
} from "../Views/CreateMontage/Defaults";

const BASE_URL = process.env.REACT_APP_API_URL;
const isDev = BASE_URL?.includes("staging") ? "dev-" : "";

export const { persistAtom } = recoilPersist({
  key: `${isDev}allstar-global-state`,
});

export const AllstarNovu = atom({
  key: "AllstarNovu_v1",
  default: { valid: false, subscriberId: "", subscriberHash: "", appId: "" },
});

const AllstarFollowersData = atom({
  key: "AllstarFollowersData_v1",
  default: { followers: [], following: [], loading: true },
});

export const AllstarUser = atom({
  key: "AllstarUserData_v0.1",
  default: {
    escalated: false, // decoded.escalated
    loggedIn: false,
    isAdmin: false, // decoded.admin
    token: "",
    user: {},
    decoded: {},
    BASE_URL: "",
    myAvatar: "",
  },
  effects_UNSTABLE: [persistAtom],
});

const AllstarSiteSettings = atom({
  key: "AllstarSiteSettings_v0.1",
  default: {
    autoplay: {
      cotdPlayer: true,
      feedPlayer: true,
      muteState: true,
    },
    lastRun: Date.now(),
  },
  effects_UNSTABLE: [persistAtom],
});

export const AllstarSiteData = atom({
  key: "AllstarSiteData_v0.1",
  default: {
    cotd: false,
    motw: false,
    promotion: false,
    lastRun: Date.now(),
    creatorCardMuted: false,
  },
  effects_UNSTABLE: [persistAtom],
});

const AppRefresh = atom({
  key: "AllstarPageRefresh_v0.1",
  default: {
    cotd: true,
    profile: false,
    clips: false,
    dotaclips: false,
    followers: false,
    lastRun: Date.now(),
  },
  effects_UNSTABLE: [persistAtom],
});

export const Session: RecoilState<any> = atom({
  key: "Session_v0.1",
  default: {
    profiles: [],
    lastRun: Date.now(),
  },
});

const PartnerLinkSession = atom({
  key: "AllstarPartnerLinkSession_v0.1",
  default: {
    partner: null,
    steamId64: null,
    fromSignInLink: false,
    lastRun: Date.now(),
  },
  effects_UNSTABLE: [persistAtom],
});

export const MontageSession = atom({
  key: "AllstarMontageSession_v0.1",
  default: {
    userGroup: "",
    isFreeUser: false,
    qualify: false,
    checkedQualify: false,
    validatedSelection: false,
    isRapidFireDefined: false,
    clips: [] as any,
    kills: -1,
    sequence: DEFAULT_SEQUENCE as TSequence,
    loadout: DEFAULT_LOADOUT as TLoadout,
    music: {} as any,
    view: EView.Game,
    montageId: "",
    payload: {},
    finalTouchesSelected: [] as any,
    hasBalance: false,
    failedFromCredits: false,
    enableProFeatures: false,
    game: GAMES.CounterStrike2,
    montagePolling: {
      isPolling: false,
      startedPolling: 0,
    },
  },
});

export const IsUserLoggedIn = atom({
  key: "Allstar_IsUserLoggedIn",
  default: (() => {
    const tokenFomStorage = localStorage.getItem("token");
    if (tokenFomStorage) return true;

    const tokenFromCookie = document.cookie
      .split("; ")
      .find((cookie) => cookie.startsWith("token="))
      ?.split("=")[1];

    return !!tokenFromCookie;
  })(),
});

export const PartnerViewSession = atom({
  key: "AllstarPartnerViewSession_v0.1",
  default: {
    overlayConfig: {
      display: false,
      fullscreen: false,
      knownUser: false,
      shareId: "",
      resconfig: "",
      uid: "",
      partner: {
        name: "",
      },
      remix: {
        show: false,
        link: ``,
      },
      share: {
        show: false,
        link: "",
        share: "",
        tiktokShare: "",
        youtubeShare: "",
        discordShare: "",
      },
      edit: {
        show: false,
        link: ``,
      },
      download: {
        show: false,
        link: ``,
      },
      mobile: {
        show: false,
        link: ``,
      },
      quality: {
        show: false,
        mobileLink: ``,
        remixLink: ``,
      },
      replay: { show: false },
      centerText: {
        show: false,
        content: ``,
      },
      button: {
        text: ``,
        link: ``,
        show: true,
      },
      attemptedAction: {
        discord: {
          actionPhrase: "",
          button: "",
        },
        tiktok: {
          actionPhrase: "",
          button: "",
        },
        youtube: {
          actionPhrase: "",
          button: "",
        },
        download: {
          actionPhrase: "",
          button: "",
        },
        mobile: {
          actionPhrase: "",
          button: "",
        },
        addfx: {
          actionPhrase: "",
          button: "",
        },
        editTitle: {
          actionPhrase: "",
          button: "",
        },
      },
    },

    claimableCount: 0,
    promotion: {
      display: false,
      buttonCopy: "",
      link: "",
      subtitle: "",
      title: "",
      name: "",
    },
    isOwnClip: false,

    notificationData: {
      requestNotificationData: () => {},
      error: undefined as ApolloError | undefined,
      data: [],
      loading: false,
    },
    clip: {} as any,
    readyToFetchClip: false,
    dynamicCta: {
      buttonText: "" as any,
      link: "" as any,
      mainCopy: "" as any,
      type: "" as any,
      isClaimable: false,
    },
    fetchedSuggested: false,
    steamIdForClaim: "",
    puuidForClaim: "",
  },
});

//recoil atom that stores the open state for a modal that is displayed when youtube share is not available.
export const YoutubeShareModal = atom({
  key: "YoutubeShareModal_v0.1",
  default: {
    open: false,
  },
});

type TStringOrNot = {
  key: string | null | undefined;
};

type TPayStation = {
  type: "subscribe" | "token" | "manage";
};

export const xsollaModalOpen = atom({
  key: "xsollaModalOpen", // unique ID (with respect to other atoms/selectors)
  default: {
    type: "subscribe" as TPayStation["type"],
    productId: undefined as TStringOrNot["key"],
    MTX: undefined as TStringOrNot["key"],
    isModalOpen: false,
    pendingEmail: false,
    shouldRefreshMtx: false,
  }, // default value (aka initial value)
});

type TStripeModal = {
  isModalOpen: boolean;
  type: "subscribe" | "purchase" | "session";
  pendingEmail: boolean;
  shouldRefreshMtx: boolean;
  lookUp?: string;
  couponId?: string;
  returnUrl?: string;
};

export const StripePaystationState = atom<TStripeModal>({
  key: "stripePaystationState", // unique ID (with respect to other atoms/selectors)
  default: {
    isModalOpen: false,
    type: "subscribe",
    pendingEmail: false,
    shouldRefreshMtx: false,
  },
});
