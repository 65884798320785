import { useEffect } from "react";
import { BiWindowClose } from "react-icons/bi";
import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

const Missing = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="50vh"
    >
      <StyledBiWindowClose />
      <Text variant="text.title2" fontWeight={700}>
        Uh Oh!
      </Text>
      <Text variant="text.paragraphMedium" fontWeight={500} color="chalk">
        This clip is no longer available.
      </Text>
    </Flex>
  );
};

const StyledBiWindowClose = styled(BiWindowClose)`
  color: ${({ theme }) => theme.colors.chalkAlpha40};
  height: 72px;
  margin-bottom: 16px;
  width: 80px;
`;

export { Missing };
