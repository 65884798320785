import React, { useEffect } from "react";
import styled from "styled-components";
import { Flex } from "rebass";
import { AlreadyPublished } from "./AlreadyPublished";
import { LinkYourSocial } from "../LinkYourSocial";
import { UploadTikTok } from "./UploadTikTok";
import { MobileOrDesktopUpload } from "../MobileOrDesktopUpload";
import { AllstarModalState } from "../../../State/modals";
import { useRecoilState } from "recoil";
import { ModalType } from "../../../@types";
import { UploadingModal } from "../Uploading";

export function TikTokShareModals() {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  return (
    <>
      {allstarModalState.isOpen === ModalType.DuplicateUploadTikTok && (
        <AlreadyPublished />
      )}
      {allstarModalState.isOpen === ModalType.TikTokUpload && <UploadTikTok />}
      {allstarModalState.isOpen === ModalType.MobileOrDesktopUpload && (
        <MobileOrDesktopUpload />
      )}
      {allstarModalState.isOpen === ModalType.Uploading && <UploadingModal />}{" "}
      {allstarModalState.isOpen === ModalType.LinkYourSocial && (
        <LinkYourSocial />
      )}
    </>
  );
}
