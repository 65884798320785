import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Badge,
  PlayIcon,
  SlideButton,
  Tag,
  WatchButton,
} from "./styledComponents";
import CotdImage from "../../../assets/images/feed/COTD_New.svg";
import MotwImage from "../../../assets/images/feed/MOTW_New.svg";
import { analyticsClickEvent } from "./utils";
import { ModalType } from "../../@types";
import { TFeaturedItemType } from "../../Views/Homepage/Featured/@types";

interface IOverlayTagProps {
  absolute?: boolean;
  style?: CSSProperties | undefined;
  tag: string;
}

export const OverlayBadge = ({ type }: { type: TFeaturedItemType }) => {
  if (type === "cotd") return <Badge src={CotdImage} />;
  if (type === "motw") return <Badge src={MotwImage} />;

  return <></>;
};

export const OverlayTag = ({ absolute, style, tag }: IOverlayTagProps) => {
  if (!tag) return <></>;

  return (
    <Tag
      absolute={absolute}
      className={tag.replace(/\s+/, "-").toLowerCase()}
      style={style}
    >
      {tag}
    </Tag>
  );
};

export const OverlayButton = ({
  buttonAction,
  buttonCopy,
  external,
  link,
  name,
  type,
  style,
}: {
  buttonAction?: ModalType;
  buttonCopy: string | undefined;
  external?: boolean;
  link: string;
  name: string;
  type: TFeaturedItemType;
  style?: CSSProperties | undefined;
}) => {
  if (!buttonCopy) return <></>;
  const history = useHistory();
  const { t } = useTranslation("translation");

  if (buttonCopy === "Watch")
    return (
      <WatchButton
        size="tiny"
        to={link}
        onClick={() => analyticsClickEvent(type, name)}
      >
        <PlayIcon />
        <span>Watch</span>
      </WatchButton>
    );

  return (
    <SlideButton
      size="tiny"
      style={style}
      onClick={() => {
        analyticsClickEvent(type, name);

        if (!link.length && !buttonAction) return;

        if (!link.length) return;
        if (external) return window.open(link);
        if (["motw", "cotd"].includes(type))
          return history.push({
            pathname: link,
            state: { autoplay: true, referrer: window.location.pathname },
          });
        return history.push(link);
      }}
    >
      {t(buttonCopy)}
    </SlideButton>
  );
};
