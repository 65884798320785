import { gql } from "@apollo/client";

const USER_FIELDS = gql`
  fragment SuggestedFollowersUserFields on User {
    _id
    avatar
    username

    discord {
      id
    }
  }
`;

const SUGGESTED_FOLLOWERS = (useGraphQuery: boolean) => gql`
  ${USER_FIELDS}

  ${useGraphQuery
    ? `
    query getMe {
      me {
        user {
          suggestedFollowers(limit: 100) {
            ...SuggestedFollowersUserFields
          }
        }
      }
    }
  `
    : `
    query suggestedCreators($count: Int) {
      suggestedCreators(count: $count) {
        reason
        user {
          ...SuggestedFollowersUserFields
        }
      }
    }
  `}
`;

export { SUGGESTED_FOLLOWERS };
