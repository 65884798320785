import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import videojs from "video.js";
import { SignatureSelectorState } from "../../../../State/loadoutSelector";

import {
  FullWidthWrapper,
  SixteenByNineSpaceMaker,
  VideoPlayerContainer,
} from "./styledComponents";
import { VideoPlayer } from "../../../../VideoPlayer";

export const SignaturePreview = () => {
  const [signatureSelectorState] = useRecoilState(SignatureSelectorState);
  const videoPlayerRef = useRef<videojs.Player | null>(null);
  const hasSelected =
    signatureSelectorState.selectedSignature.metadataPreviewSrc;
  return (
    <>
      <FullWidthWrapper>
        {hasSelected &&
          signatureSelectorState.previewSources.map((src) => {
            return (
              <>
                <SixteenByNineSpaceMaker />
                <VideoPlayerContainer
                  displayOnTop={
                    src ===
                    signatureSelectorState.selectedSignature.metadataPreviewSrc
                  }
                >
                  <VideoPlayer
                    hlsSource={src}
                    refForVideoPlayer={videoPlayerRef}
                    loop={true}
                    options={{
                      controls: false,
                      muted: true,
                      playsinline: true,
                      autoplay: true,
                      userActions: {
                        click: false,
                      },
                    }}
                  />
                </VideoPlayerContainer>
              </>
            );
          })}
        {!hasSelected && (
          <>
            {" "}
            <SixteenByNineSpaceMaker />
            <VideoPlayerContainer displayOnTop={true}>
              <VideoPlayer
                hlsSource={
                  "https://customer-rkr4t84lau6kjumb.cloudflarestream.com/6df96fea96eed6da4e63de704b83a385/manifest/video.m3u8"
                }
                refForVideoPlayer={videoPlayerRef}
                loop={true}
                options={{
                  playsinline: true,
                  autoplay: true,
                  muted: true,
                  userActions: {
                    click: false,
                  },
                }}
              />
            </VideoPlayerContainer>
          </>
        )}
      </FullWidthWrapper>
    </>
  );
};
